import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { ChatCircle, Hash } from '@phosphor-icons/react';
import DebugContext from 'src/contexts/DebugContext';
import { VERSION_NUMBER } from 'src/constants';
import { useSession, useTasks } from 'src/hooks';

const SVG_SIZE = 20;

export const HeaderTitle = () => {
  const { debugMode } = useContext(DebugContext);
  const { isActionItem, appUser, selectedTaskId } = useSession();
  const { selectTaskById } = useTasks(appUser.user_id);

  const currentActionItem = useMemo(
    () => (isActionItem && selectedTaskId ? selectTaskById(selectedTaskId) : null),
    [isActionItem, selectedTaskId, selectTaskById],
  );

  if (!isActionItem && !debugMode) {
    return null;
  }

  if (!isActionItem && debugMode) {
    return (
      <div className="nj-thread-header--title">
        <ChatCircle size={SVG_SIZE} />
        <h2>
          {`Atlas: ${
            process.env.REACT_APP_ENVIRONMENT || 'development'
          }, version ${VERSION_NUMBER}`}
        </h2>
      </div>
    );
  }

  if (!currentActionItem) {
    return null;
  }

  const { task_subject, created_at, task_id } = currentActionItem;

  return (
    <div className="nj-thread-header--action-title">
      <p>
        {created_at && dayjs(created_at).format('MMMM D, YYYY, h:mmA')}
        {debugMode && parse(`&nbsp;&middot;&nbsp;${task_id}`)}
      </p>

      <h2>
        <Hash size={SVG_SIZE} color="currentColor" />
        <span>{task_subject}</span>
      </h2>
    </div>
  );
};
