/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'react-responsive-modal';
import { Microphone, PaperPlaneRight, PlusCircle, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { Button } from 'src/components/Button';
import { useSession, useSpeechRecognizer, useContacts, useTasks } from 'src/hooks';
import { logAction } from 'src/utils/analytics';
import { interruptMetahuman, stripHtmlTags, getCaretPosition, selectEditableSpan } from 'src/utils';
import DebugContext from 'src/contexts/DebugContext';
import {
  MetaHumanEvent,
  useMetaHumanEvent,
} from 'src/pages/ManageTasksChatPage/components/MetaHuman/hooks/useMetaHumanEvent';
import { Examples } from './Examples';
import { EditableInserts, KeyCodes } from 'src/types';
import { Helper } from './Helper';

const SVG_SIZE = 20;

type ChatFormProps = {
  userId: string,
  isAvatarMode?: boolean;
  isAudioMode?: boolean;
  classname: string;
  value: string;
  shouldShowBrowseExamples: boolean;
  onValueChange: (value: string) => void;
  onValueSubmit: (value: string, audioLocale?: string) => Promise<void>;
  onToggleAudio?: () => void;
  onClear: () => void;
};

/**
 * ChatForm component handles message typing, translations, audio capture,
 * and commands rendering for calendar scheduling, reservations, and other
 * skills usage.
 */
const ChatForm = ({
  // isAudioMode = false,
  userId,
  isAvatarMode = false,
  classname,
  value = `<span class="nj-placeholder">Send your message...</span>`,
  shouldShowBrowseExamples = false,
  onValueSubmit,
  onValueChange,
  onClear,
}: ChatFormProps) => {
  const { debugMode } = useContext(DebugContext);
  const [open, setOpen] = useState(false);

  const contentEditableRef = useRef<HTMLDivElement>(null);
  const [apiRequestInProgress, setApiRequestInProgress] = useState<boolean>(false);
  const [metaHumanTalking, setMetaHumanTalking] = useState<boolean>(false);
  const { chatAudioLocale } = useSession();

  const [caretPosition, setCaretPosition] = useState<number>(0);
  const [selectionMode, setSelectionMode] = useState<EditableInserts>(EditableInserts.NONE);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { getMatchingContacts } = useContacts();
  const { getMatchingTasks } = useTasks(userId);

  const isInLimbo = useMemo(
    () => metaHumanTalking && !apiRequestInProgress,
    [metaHumanTalking, apiRequestInProgress],
  );

  // show helper dropdown to choose @contact or #action-item
  const showDropdownHelper = () => {
    if (!contentEditableRef.current) return;

    const caret = getCaretPosition(contentEditableRef.current);
    const text = value.slice(0, caret);

    if (text.length === 0) return;

    const prevChar = text.at(-1);
    const beforePrevChar = text.at(-2);

    if (prevChar === KeyCodes.AT && beforePrevChar === KeyCodes.SPACE) {
      setSelectionMode(EditableInserts.CONTACT);
      setShowDropdown(true);
      currentCaretPosition();
      contentEditableRef.current.blur();
    } else if (prevChar === KeyCodes.HASH && beforePrevChar === KeyCodes.SPACE) {
      setSelectionMode(EditableInserts.ACTION_ITEM);
      setShowDropdown(true);
      currentCaretPosition();
      contentEditableRef.current.blur();
    } else if (prevChar === KeyCodes.SPACE) {
      setSelectionMode(EditableInserts.NONE);
      setShowDropdown(false);
    }
  };

  // tracks what was typed after recorded caret
  const getTypedCharsAfter = useMemo(() => {
    if (!showDropdown) return '';

    return value
      .substring(caretPosition).split(' ')[0]
      .replace(/<span class="nj-replace">/, '')
      .replace(/<\/span>/, '')
    ;
  }, [value]);

  useEffect(() => {
    return () => {
      stopRecording();
      if (isAvatarMode && userId) {
        interruptMetahuman(userId, debugMode);
      }
    };
  }, []);

  // on value change, either show helper or not
  useEffect(() => {
    showDropdownHelper();
  }, [value]);

  const handleSpeechRecognized = useCallback(async (newValue: string) => {
    /**
     * We don't want to send the message if "stop record" was clicked.
     */
    if (!recordInProgressRef?.current) {
      return;
    }

    const updatedNewValue = value ? `${value} ${newValue[0].toLowerCase() + newValue.slice(1)}` : newValue;
    onValueChange(updatedNewValue);

    logAction('send_message', { type: 'voice' });

    muteMicrophone();
    setApiRequestInProgress(true);
    await onValueSubmit(updatedNewValue);
    setApiRequestInProgress(false);
    if (isAvatarMode) {
      /**
       *  Event that is coming from the iFrame is taking some time and UI behavior in not smooth.
       *  So setting metaHumanTalknig to true as soon as we send the request.
       */
      setMetaHumanTalking(true);
    } else {
      unMuteMicrophone();
    }
  }, [value]);

  const handleSpeechRecognizing = useCallback((newValue: string) => {
    onValueChange(`${value} ${newValue}`);
  }, [value]);

  const {
    startSpeechRecognizing,
    stopSpeechRecognizing,
    recordInProgressRef,
    voiceDetected,
    recordInProgress,
    muteMicrophone,
    unMuteMicrophone,
  } = useSpeechRecognizer({
    onRecognizing: handleSpeechRecognizing,
    onRecognized: handleSpeechRecognized,
    sourceLanguage: chatAudioLocale,
  });

  const startRecording = () => {
    logAction('start_recording', { type: isAvatarMode ? 'meta_human' : 'chat_form' });
    startSpeechRecognizing();
  };

  const stopRecording = () => {
    if (isAvatarMode && isInLimbo && userId) {
      interruptMetahuman(userId, debugMode);
    }
    setMetaHumanTalking(false);
    stopSpeechRecognizing();
    setApiRequestInProgress(false);
  };

  const handleSubmit = async () => {
    const value = contentEditableRef?.current?.innerHTML || '';

    if (value) {
      if (recordInProgress) {
        stopRecording();
      }

      setApiRequestInProgress(true);
      await onValueSubmit(stripHtmlTags(value));
      setApiRequestInProgress(false);
      onClear();
      handleClearHeight();
    }
  };

  const handleToggleAction = () => {
    if (apiRequestInProgress) {
      stopRecording();
      return;
    }

    recordInProgress ? stopRecording() : startRecording();
  };

  const handleClearHeight = () => {
    if (contentEditableRef.current instanceof HTMLDivElement) {
      contentEditableRef.current.style.height = 'auto';
    }
  };

  const handleHeightChange = () => {
    if (contentEditableRef.current instanceof HTMLDivElement) {
      contentEditableRef.current.style.height = 'auto';
      contentEditableRef.current.style.height = `${contentEditableRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (contentEditableRef.current && recordInProgress) {
      contentEditableRef.current.scrollTop = contentEditableRef.current.scrollHeight;
    }
  }, [contentEditableRef.current?.scrollHeight, recordInProgress]);

  /**
   * Handles events from MetaHuman iFrame
   */
  useMetaHumanEvent(
    useCallback(
      (metaHumanEvent: MetaHumanEvent) => {
        if (metaHumanEvent.silence && isAvatarMode) {
          if (!recordInProgress) {
            return;
          }
          setMetaHumanTalking(false);

          // unmute mic as soon as MetaHuman is done talking.
          unMuteMicrophone();
        }
      },
      [recordInProgress],
    ),
  );

  const classes = classNames('input-area', classname, {
    'recording-on': recordInProgress,
    'listening-on': recordInProgress && !voiceDetected,
    'speaking-on': recordInProgress && voiceDetected,
    'sending-on': recordInProgress && apiRequestInProgress,
    'limbo-on': apiRequestInProgress,
  });

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // on change save value
  const handleChange = (e: ContentEditableEvent) => {
    onValueChange(e.target.value);
    handleHeightChange();
  };

  // handle keydown to show helper
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === KeyCodes.BACKSPACE || e.key === KeyCodes.DELETE) {
      setShowDropdown(false);
    } else if (e.key === KeyCodes.ENTER && !e.shiftKey) {
      e.preventDefault();
      if (showDropdown === false) {
        logAction('send_message', { type: 'text' });
        handleSubmit();
      }
    }
  };

  // click on editable inserts
  const handleClick = (e: React.MouseEvent<Node>) => {
    selectEditableSpan(e.target as Node);

    if (apiRequestInProgress) {
      stopRecording();
      return;
    }
    recordInProgress && stopRecording();
  };

  // replace text in place
  const replaceText = (newText: string) => {
    if (!contentEditableRef.current) return;

    const content =
      value.substring(0, caretPosition) +
      value.substring(caretPosition).replace(getTypedCharsAfter, newText);

    onValueChange(content);
  };

  // helper item click
  const handleHelperItemClick = (text: string) => {
    if (contentEditableRef?.current) {
      if (selectionMode === EditableInserts.CONTACT ||
        selectionMode === EditableInserts.ACTION_ITEM) {
          replaceText(text);
      }

      setShowDropdown(false);
      contentEditableRef.current?.focus();
    }
  };

  // setting caret position
  const currentCaretPosition = () => {
    if (contentEditableRef.current) {
      const pos = getCaretPosition(contentEditableRef.current)
      setCaretPosition(pos);
    }
  };

  // match helper items
  const matchedHelperItems = useMemo(() => {
    if (selectionMode === EditableInserts.CONTACT) {
      return getMatchingContacts(getTypedCharsAfter);
    } else if (selectionMode === EditableInserts.ACTION_ITEM) {
      return getMatchingTasks(getTypedCharsAfter);
    } else {
      return [];
    }
  }, [selectionMode, getTypedCharsAfter]);

  // prefix for helper component
  const prefix = useMemo(() => {
    return selectionMode === EditableInserts.ACTION_ITEM ? '#' : '';
  }, [selectionMode]);

  return (
    <>
    {showDropdown && <div className="nj-replace--menu">
      <Helper
        prefix={prefix}
        items={matchedHelperItems}
        className="bottom-attached"
        onItemClick={handleHelperItemClick}
        selection={getTypedCharsAfter}
        style={{ left: `${caretPosition * 8}px` }}
      />
    </div>}
    <div
      aria-label="Type a message"
      className={classes}
    >
      {shouldShowBrowseExamples ? <Button
        aria-label="Browse examples menu"
        type="button"
        className="btn-plus"
        onClick={onOpenModal}
      >
        <PlusCircle data-testid="plus-icon" size={SVG_SIZE} weight="fill" />
      </Button> : <span className="btn-plus--spacer"></span>}
      <ContentEditable
        data-testid="chatform-content-editable"
        innerRef={contentEditableRef}
        html={recordInProgress && !!value ? `${value}...` : value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        placeholder={recordInProgress ? 'Listening now...' : 'Sending message...'}
        tagName='div'
        className={classNames({ 'no-command': !shouldShowBrowseExamples})}
      />
      <div className="action">
        <Button
          aria-label="Click to the action button"
          type="button"
          className="btn-update"
          onClick={handleToggleAction}
        >
          <Microphone data-testid="microphone-icon" size={SVG_SIZE} weight={recordInProgress ? "fill" : "regular"} />
        </Button>
      </div>
      <div className="action action-update">
        <Button
          aria-label="Send a message"
          disabled={!value || recordInProgress}
          className="action btn-submit"
          onClick={handleSubmit}
        >
          <PaperPlaneRight size={SVG_SIZE} color="currentColor" weight="fill" />
        </Button>
      </div>
      {shouldShowBrowseExamples && <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{
          overlay: 'nj-modal-overlay',
          modal: 'nj-modal',
        }}
        closeIcon={<X size={20} />}
        center>
        <Examples
          onTileClick={onValueChange}
          onClose={onCloseModal}
        />
      </Modal>}
    </div>
    </>
  );
};

export default memo(ChatForm);
