import { useContext } from 'react';
import classNames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { Bug, DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { useSession } from 'src/hooks';
import DebugContext from 'src/contexts/DebugContext';
import { ThreadMenuItems } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/ThreadMenuItems';
import { ActionItemMenuItems } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/ActionItemMenuItems';

const SVG_SIZE = 24;
const SVG_MENU_SIZE = 20;

export const HeaderMenu = () => {
  const { isActionItem } = useSession();
  const { debugMode, toggleDebugMode } = useContext(DebugContext);

  useHotkeys('ctrl+d', () => {
    toggleDebugMode();
  });

  return (
    <Menu
      menuClassName="nj-multi-menu"
      menuButton={
        <MenuButton data-testid="snowman-menu">
          <DotsThreeOutlineVertical size={SVG_SIZE} alt="Header menu" />
        </MenuButton>
      }
      transition
    >
      {isActionItem ? <ActionItemMenuItems /> : <ThreadMenuItems />}

      <MenuItem
        className={classNames({ selected: debugMode })}
        onClick={toggleDebugMode}
        data-testid="debug-btn"
      >
        <Bug size={SVG_MENU_SIZE} /> Debug
      </MenuItem>
    </Menu>
  );
};
