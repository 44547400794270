import classNames from 'classnames';
import { useState } from 'react';
import { Button } from 'src/components/Button';

const tabs = [
  { title: 'General' },
  { title: 'Profile' },
  { title: 'Scheduler' },
  { title: 'Reservation' },
  { title: 'Privacy' },
];

export const SettingsTabs = () => {
  const [activeTab, setActiveTab] = useState<string>('General');
  return (
    <div data-testid="settings-tabs" className="nj-settings--tabs">
      {tabs.map(({ title }) => (
        <Button
          className={classNames('nj-settings--tabs-button', { active: title === activeTab })}
          key={title}
          onClick={() => setActiveTab(title)}
        >
          {title}
        </Button>
      ))}
    </div>
  );
};
