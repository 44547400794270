import { rest } from 'msw';
import {
  ApiMessage,
  TaskSkill,
  TaskStatus,
  TaskState,
  MessageChannel,
  TaskChannel,
} from 'src/types';
import { v2BaseUrl } from '../config';
import { DEFAULT_MOCKED_USER_ID, DEFAULT_MOCKED_TASK_BY_ID } from './mockedConstants';

const MOCKED_CHAT_MESSAGE: ApiMessage = {
  message_id: 'message_id_2',
  timestamp: '2023-06-01T16:00:00.000Z',
  from_user_id: DEFAULT_MOCKED_USER_ID,
  to_user_id: 'to_user_id',
  channel: MessageChannel.WEB_APP,
  content: 'Test message 2',
};

export const MOCKED_TASK_BY_ID = {
  task_id: 'task_id',
  task_subject: 'Test Task Subject',
  user_id: DEFAULT_MOCKED_USER_ID,
  skill: TaskSkill.SCHEDULING,
  state: TaskState.COMPLETED,
  channel: TaskChannel.WEB_APP,
  created_at: '2023-06-01T19:00:00.000Z',
  messages: [MOCKED_CHAT_MESSAGE],
  status: TaskStatus.WORKING,
  updated_at: '2023-06-01T19:00:00.000Z',
};

export const MOCKED_TASKS_BY_USER_ID = [MOCKED_TASK_BY_ID, DEFAULT_MOCKED_TASK_BY_ID];

export const tasksApiMock = [
  rest.get(`${v2BaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/tasks`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: MOCKED_TASKS_BY_USER_ID }));
  }),
];
