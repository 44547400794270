import { AppUser, ApiMessage, ApiTaskSelectable, MessageChannel, TaskSkill, ApiTask,
  TaskState, TaskChannel, TaskStatus, ConversationRole, MessageDebugData, Side,
  ModelFeedback, ConversationEngineResponseWithTraceId, ConversationEngineRequestWithTask,
  ConversationEngineRequest, isConversationEngineSchedulingData} from 'src/types';
import { getUserFirstName } from './general';
import { CONVERSATION_ENGINE_ENDPOINT } from 'src/constants';

/**
 * getMessageSide() finds out the side the message bubble should show up on.
 * @param user
 * @param role
 * @param fromUserId
 * @param prevFromUserId
 * @returns string
 */
export function getMessageSide(
  user: AppUser,
  role: ConversationRole,
  fromUserId: string,
): Side {
  if (role && role === ConversationRole.AGENT) {
    return Side.LEFT;
  }

  if (user.user_id === fromUserId) {
    return Side.RIGHT;
  }

  return Side.LEFT;
}

/**
 * getMessageRole() fixes roles based on the fact that message can be
 * system or robot or assistant in the database, and it is not
 * an aligned concept just yet.
 * @param message
 * @param agentId
 * @returns string
 */
export function getMessageRole(message: ApiMessage, agentId: string): ConversationRole {
  if ('role' in message) {
    return message.role as ConversationRole;
  }

  if (message.from_user_id === agentId) {
    return ConversationRole.AGENT;
  }

  return ConversationRole.USER;
}

/**
 * getMessageAuthor() finds out the user based on the message header info.
 * @param users AppUser[]
 * @param fromUserId string
 * @returns User
 */
export function getMessageAuthor(users: AppUser[], fromUserId: string): AppUser|undefined {
  return users.find(user => user.user_id === fromUserId);
}

/**
 * getDefaultMessage creates a default message for the chat.
 * @param currentUser User
 * @param agent User
 * @returns ChatMessage
 */
export function getDefaultMessage(currentUser: AppUser, agent: AppUser): ApiMessage {
  return {
    from_user_id: agent.user_id,
    to_user_id: currentUser.user_id,
    content: `Hi ${getUserFirstName(currentUser)}, <br>what can I help you with today?`,
    role: ConversationRole.AGENT,
    channel: MessageChannel.WEB_APP,
    timestamp: new Date().toISOString(),
    tag: 'new-conversation',
  };
}

/**
 * Creates an empty task before it is saved to the database.
 * @param user AppUser
 * @param agent AppUser
 * @param messages ApiMessage[]
 * @param withAgentId boolean
 * @param contentPlain string
 * @returns ApiTaskSelectable
 */
export function createNewTask(
  user: AppUser,
  agent: AppUser,
  messages: Array<ApiMessage> = [],
  withAgentId = false,
  contentPlain = '',
): ApiTaskSelectable {
  const withAgendIdData = withAgentId ? {
    agent_id: agent.user_id,
  } : {};

  return {
    ...withAgendIdData,
    user_id: user?.user_id || '',
    skill: TaskSkill.SCHEDULING,
    state: TaskState.NEW,
    channel: TaskChannel.WEB_APP,
    messages: (messages && messages.length > 0)
      ? [...messages] : [getDefaultMessage(user, agent)],
    requires_attention: false,
    status: TaskStatus.WORKING,
    updated_at: new Date().toISOString(),
    created_at: new Date().toISOString(),
  };
}

/**
 * Creates chat gpt body to send request to an endpoint.
 * @param user AppUser
 * @param messages ApiMessage[]
 * @param debug boolean
 * @returns ConversationEngineRequestWithTask
 */
export function createConversationEngineRequestBody(
  user: AppUser,
  agent: AppUser,
  messages: ApiMessage[],
  debug = false,
): ConversationEngineRequestWithTask {
  const tmpArray: { content: string; role: string }[] = [];
  const lastMessage = messages.at(-1)?.content || '';

  for (let i = messages.length - 1; i >= 0; i--) {
    const { role, tag, content } = messages[i];

    if (debug) {
      console.log('tag: ', tag, ', content: ', content, ', index', i);
    }

    if (content !== '') {
      if (tag === 'new-conversation') {
        tmpArray.unshift({ content, role: role || ConversationRole.USER });
        break;
      } else {
        tmpArray.unshift({ content, role: role || ConversationRole.USER });
      }
    }
  }

  const genericTaskData: ApiTask = {
    ...createNewTask(user, agent, [], true, lastMessage), // with agent id attached
    messages: getLatestTaskRelatedMessages(messages, debug),
  };

  const request: ConversationEngineRequest = {
    use_v2: true,
    user_name: user?.first_name + ' ' + user?.last_name,
    assistant_name: 'Atlas',
    location: 'Palo Alto, CA',
    time_zone: 'PST',
    current_user_input: tmpArray[tmpArray.length - 1].content || '',
    today: new Date().toISOString(),
    message_logs: [...tmpArray],
  };

  if (debug) {
    console.log('OPENAI Request:', request);
  }

  return {
    ...genericTaskData,
    ...request,
  };
}

/**
 * getLatestTaskRelatedMessages retrieves message from last
 * the tag = 'new-conversation'
 * @param messages ApiMessage[]
 * @param debug boolean
 * @returns ApiMessage[]
 */
export function getLatestTaskRelatedMessages(messages: ApiMessage[], debug = false): ApiMessage[] {
  const taskRelatedMessages = [];
  let len = messages.length;
  while (len > 0) {
    len--;
    taskRelatedMessages.unshift(messages[len]);
    if (messages[len].tag === 'new-conversation') {
      break;
    }
  }
  if (debug) {
    console.log('Conversation:', taskRelatedMessages);
  }
  return taskRelatedMessages;
}

/**
 * createNewMessage creates a new message
 * @param message ApiMessage
 * @param tag string
 * @param user AppUser
 * @param debugData MessageDebugData
 * @param created_task_id string|null|undefined
 * @returns ApiMessage
 */
export function createNewMessage(
  content: string,
  tag: string,
  user: AppUser,
  agent: AppUser,
  messageDebugData: MessageDebugData,
  created_task_id: string | null | undefined = null,
  feedbackData?: ModelFeedback,
): ApiMessage {
  const taskId = created_task_id ? { created_task_id: created_task_id } : {};
  const feedback = feedbackData
    ? {
        ...feedbackData,
        ui_environment: process.env.REACT_APP_ENVIRONMENT || '',
        timestamp: feedbackData.timestamp,
      }
    : undefined;

  return {
    from_user_id: agent.user_id,
    to_user_id: user.user_id,
    channel: MessageChannel.WEB_APP,
    content,
    timestamp: new Date().toISOString(),
    role: ConversationRole.AGENT,
    tag,
    debug: messageDebugData,
    feedback,
    ...taskId,
  };
}

/**
 * fetchAPI() sends data to the endpoint and receives response { data: Object }.
 * Error-handling is delegated to the UI.
 * @param url string
 * @param options Object
 * @returns Promise
 */
export async function fetchAPI(url: string, options: { body?: string; }) {
  const response = await fetch(url, {
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...options,
  });

  const result = await response.json();
  return result;
}

/**
 * Fetches with timeout
 * @param url string
 * @param timeout number
 * @returns Promise
 */
export async function fetchAPIWithTimeout(
  url: string,
  options: { body?: string },
  timeoutOptions: { timeout: number; },
) {
  const { timeout } = timeoutOptions;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(url, {
    signal: controller.signal,
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...options,
  });
  clearTimeout(id);
  return response;
}

/**
 * loadConversationEngineResponse tries to fetch.
 * @returns ConversationEngineResponseWithTraceId
 */
export async function loadConversationEngineResponse({
  endpoint = CONVERSATION_ENGINE_ENDPOINT,
  debug = false,
  options = {},
  timeoutOptions = { timeout: 0, error: `Bad request with Conversation Engine Endpoint.` },
}: {
  endpoint: string;
  debug: boolean;
  timeoutOptions?: { timeout: number, error?: string },
  options?: { body?: string }
}): Promise<ConversationEngineResponseWithTraceId> {
  try {
    const response = timeoutOptions
      ? await fetchAPIWithTimeout(endpoint, options, timeoutOptions)
      : await fetchAPI(endpoint, options);

    const xTraceId = response.headers.get('x-trace-id') || undefined;
    const json = await response.json();

    if (json.success) {
      if (debug) {
        console.log('Server request: ', options.body);
        console.log('X-Trace-ID', xTraceId);
        console.log('Server response: ', json);
        console.log('Debug data:', json.data.debug);
      }

      if (isConversationEngineSchedulingData(json.data)) {
        if (debug) {
          console.log('Returning SCHEDULING data.');
        }
        return {
          data: json.data,
          message: json.data.messages.at(-1).content || json.data.task_subject,
          created_task_id: json.data.messages.at(-1).created_task_id || json.data.task_id,
          tag: json.data.messages.at(-1).tag || 'conversation',
          xTraceId,
        };
      }

      if (debug) {
        console.log('Returning CHIT-CHAT data.');
      }
      return {
        data: json.data,
        message: json.data.text,
        tag: 'conversation',
        xTraceId,
      };
    } else {
      throw new Error(timeoutOptions?.error || `Error`);
    }
  } catch (error: unknown) {
    if (debug) {
      console.log(error);
    }
    return {
      message: timeoutOptions?.error,
      tag: 'error',
      xTraceId: undefined,
    };
  }
}

/**
 * Possible Fetch API Solution for Retries (if backend does not fix it)
 * Todo(ella): can have our own retries if needed.
 * @param URL string
 * @param options Object
 */
export function fetchApiWithRetries(URL: string, options: { body: string }) {
  const fetchPromise = fetch(URL, {
    method: 'post',
    mode: 'cors',
    credentials: 'same-origin',
    referrerPolicy: 'no-referrer',
    redirect: 'follow',
    cache: 'no-cache',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const warningPromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: 'I am working on it...',
          tag: 'error',
        }),
      3000,
    );
  });

  const sorryPromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: 'Sorry, I am working on it...',
          tag: 'error',
        }),
      8000,
    );
  });

  const mistakePromise = new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          message: `I'm sorry, it seems I've run into a problem. Could you please re-ask your question?`,
          tag: 'error',
        }),
      11000,
    );
  });

  Promise.race([fetchPromise, warningPromise, sorryPromise, mistakePromise])
    .then((result) => {
      // Handle success
      console.log(result);
    })
    .catch((error) => {
      // Handle error
    });
}
