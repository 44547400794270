import { CircleNotch } from '@phosphor-icons/react';

const SVG_SIZE = 24;

export const Spinner = () => {
  return (
    <div className="nj-spinner">
      <CircleNotch size={SVG_SIZE} />
      <span>Loading...</span>
    </div>
  );
}
