/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'src/components/Button';
import { useSession } from 'src/hooks/useSession';
import { ApiMessage, ConversationRole, AppUser, MessageChannel } from 'src/types';
// import { useMarkAllReadTaskMessagesMutation } from 'src/store/services';
import {
  formatMonthDay,
  getElapsedTime,
  getMessageRole,
  getMessageSide,
  translateText,
  translations,
} from 'src/utils';
import { usePrevious } from 'src/hooks';
import { Message } from '../Message';

interface ChatMessageListProps {
  value: string;
  isBasic?: boolean;
  taskId: string;
  user: AppUser;
  messages: ApiMessage[];
  onScrollUp: (delay: number, instant: boolean) => Promise<void>;
}

export const ChatMessagesList = ({
  isBasic = false,
  taskId,
  user,
  messages,
  onScrollUp,
}: ChatMessageListProps) => {
  const { updateSelectedTaskId, avatarCCLocale, agent } = useSession();
  const [messagesToRender, setMessagesToRender] = useState<ApiMessage[]>([]);
  const [unreadMessageId, setUnreadMessageId] = useState<string | undefined>();

  // const [markAllReadTaskMessages] = useMarkAllReadTaskMessagesMutation();

  let prevDateLine = '';
  let prevTime = `${new Date().getTime()}`;

  const previousTaskId = usePrevious(taskId);

  useEffect(() => {
    if (taskId === 'default') {
      return;
    }
    if (previousTaskId !== taskId) {
      const unreadMessage = messages.find((message) => !message.is_read);
      setUnreadMessageId(unreadMessage?.message_id);

      // if (unreadMessage) {
      //  markAllReadTaskMessages(taskId);
      // }
    }
  }, [previousTaskId, messages, taskId]);

  useEffect(() => {
    /**
     * For text view (!isBasic) we don't need to translate messages.
     *
     * However for all other cases we want to make sure to translate messages
     * to the selected avatarCCLocale language.
     */
    if (!isBasic) {
      setMessagesToRender(addWaitingMessage(messages));
    } else {
      handleMessageTranslations();
    }
  }, [messages, isBasic, avatarCCLocale]);

  const addWaitingMessage = (messages: ApiMessage[]): ApiMessage[] => {
    const lastMessage = messages?.at(-1);
    if (lastMessage) {
      return lastMessage?.role === ConversationRole.USER
        ? [
            ...messages,
            {
              tag: 'waiting',
              content: '',
              timestamp: new Date().toISOString(),
              role: ConversationRole.AGENT,
              user_id: user.user_id,
              from_user_id: agent.user_id,
              to_user_id: user.user_id,
              channel: MessageChannel.WEB_APP,
            },
          ]
        : messages;
    }
    return messages;
  };

  const handleMessageTranslations = useCallback(async () => {
    const avatarCCLanguage = avatarCCLocale.slice(0, 2);

    /**
     * Translate last 4 messages (last 4 visible messages in the chat window)
     * and store them in a map, so next time new messages are added we can
     * only translate the new messages.
     */
    await Promise.all(
      messages
        .slice(-4)
        .map(async (message) => await translateText(message.content, [avatarCCLanguage])),
    );

    /**
     * Replace content of messages with translated content.
     */
    const updatedMessages = messages.map((message) => {
      const translation = translations.get(message.content)?.get(avatarCCLanguage);
      if (translation) {
        return { ...message, content: translation };
      }
      return message;
    });

    setMessagesToRender(addWaitingMessage(updatedMessages));
  }, [isBasic, messages, avatarCCLocale]);

  const displayMessages = useMemo(
    () =>
      messagesToRender.map((message: ApiMessage, index: number) => {
        const dateLine = message.timestamp ? formatMonthDay(message.timestamp) : '';
        const role = getMessageRole(message, agent.user_id);
        const side = getMessageSide(user, role, message.from_user_id);

        const jsxMessageBlock = (
          <div key={`${taskId}-${index}`} className="nj-message-block">
            {!isBasic && prevDateLine !== dateLine && <div className="nj-date">{dateLine}</div>}
            {!isBasic && message.tag === 'new-conversation' && index !== 0 && (
              <p data-testid="new-conversation" className="nj-divider">
                New Conversation
              </p>
            )}
            {!isBasic &&
              taskId !== 'default' &&
              !!unreadMessageId &&
              unreadMessageId === message.message_id && (
                <p data-testid="new-updates" className="nj-divider active">
                  New updates
                </p>
              )}
            <Message
              timeDiff={getElapsedTime(message.timestamp, prevTime)}
              isBasic={isBasic}
              message={message}
              // doTyping={index === (messages.length - 1) && role === Role.Robot}
              onScrollUp={onScrollUp}
              side={side}
            />
            {!isBasic &&
              message.created_task_id &&
              message.created_task_id !== taskId &&
              message.tag === 'task-display' && (
                <span className="nj-task-created-display">
                  <Button
                    onClick={() => updateSelectedTaskId(message.created_task_id || 'default')}
                    className="nj-button-system"
                    aria-label="View Task"
                  >
                    {message.content || 'View Task'}
                  </Button>
                </span>
              )}
          </div>
        );

        prevDateLine = dateLine;
        prevTime = message.timestamp || '';
        return jsxMessageBlock;
      }),
    [messagesToRender],
  );

  return <div {...(isBasic ? {className: "nj-audio-conversation"} : {})}>{displayMessages}</div>;
};
