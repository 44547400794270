import { ChatCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { AppUser } from 'src/types';
import { EA } from 'src/constants';
import { Icon } from 'src/components/Icon';

interface EACardProps {
  users: AppUser[];
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  rest?: { [key: string]: React.ReactNode };
}

const ATLAS_ICON_SIZE = 24;
const SVG_SIZE = 20;
const ICON_PROPS = {
  size: SVG_SIZE,
  color: 'currentColor',
};

/**
 * EACard displays an EA Assistant information.
 */
export const EACard = ({ users, selected, onClick, ...rest }: EACardProps) => {
  const classes = classNames('nj-task-card ea-details', {
    selected: selected,
  });

  return (
    <div className="nj-ea-card">
      <div
        aria-label="Click to start chat with Atlas"
        className={classes}
        onClick={onClick}
        {...rest}
      >
        <span className="nj-task-card--icon">
          <ChatCircle {...ICON_PROPS} />
        </span>

        <h3 className="subtitle ellipsis">{`Chat with ${EA.name}`}</h3>

        <span className="nj-task-card--icon">
          <Icon type="avatarAtlas" size={ATLAS_ICON_SIZE} />
        </span>
      </div>
    </div>
  );
};
