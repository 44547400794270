import { useMemo } from 'react';
import { ApiMessage, ConversationRole, MessageChannel } from 'src/types';
import { formatHours } from 'src/utils';
import { Icon } from 'src/components/Icon';
import { ConversationThumbButtons } from 'src/pages/ManageTasksChatPage/components/ConversationThumbButtons';
import { ReplyMessageButton } from 'src/pages/ManageTasksChatPage/components/ReplyMessageButton';

interface MessageFooterProps {
  message: ApiMessage;
}

export const MessageFooter = ({ message }: MessageFooterProps) => {
  const { feedback, role, message_id, timestamp, channel } = message;

  const date = useMemo(
    () => (timestamp ? formatHours(new Date(timestamp).getTime()) : ''),
    [timestamp],
  );

  // TODO(olha): extend Channel Icons
  const messageChannelIcon = useMemo(() => {
    switch (channel) {
      case MessageChannel.EMAIL:
        return <Icon type="gmailLogo" size={16} />;

      default:
        return null;
    }
  }, [channel]);

  return (
    <div className="nj-bubble--footer">
      <div className="nj-bubble--footer-wrapper">
        <span className="nj-bubble--date">{date}</span>

        {message_id && feedback && role !== ConversationRole.USER && (
          <ConversationThumbButtons feedback={feedback} messageId={message_id} />
        )}
      </div>

      {role !== ConversationRole.USER && <ReplyMessageButton />}

      {role === ConversationRole.USER && messageChannelIcon && (
        <span className="nj-bubble--channel">
          <span className="nj-bubble--channel-title">Message sent from</span>
          {messageChannelIcon}
        </span>
      )}
    </div>
  );
};
