import classNames from 'classnames';
import { useState } from 'react';
import avatarAtlas from 'src/images/avatars/AiAvatarAtlas.png';
import avatarAndres from 'src/images/avatars/andres.png';
import avatarArash from 'src/images/avatars/arash.png';
import avatarBabak from 'src/images/avatars/babak.png';
import avatarCarlos from 'src/images/avatars/carlos.png';
import avatarDivya from 'src/images/avatars/divya.png';
import avatarElla from 'src/images/avatars/ella.png';
import avatarKurt from 'src/images/avatars/kurt.png';
import avatarMarwa from 'src/images/avatars/marwa.png';
import avatarMasoud from 'src/images/avatars/masoud.png';
import avatarPeter from 'src/images/avatars/peter.png';
import avatarSam from 'src/images/avatars/sam.png';
import avatarSoroush from 'src/images/avatars/soroush.png';
import avatarVince from 'src/images/avatars/vince.png';
import attention from 'src/images/icons/AttentionDot.svg';
import company from 'src/images/icons/Windows.png';
import defaultAvatar from 'src/images/icons/defaultAvatar.svg';
import waveform from 'src/images/icons/waveform.svg';
import ninjaIcon from 'src/images/logos/ninja-icon.svg';
import openaiIcon from 'src/images/logos/openai-icon.svg';
import gmailLogo from 'src/images/logos/gmail-logo.svg';
import { getCircleColor } from 'src/utils/index';

type IconMap = {
  [key: string]: string;
};

const iconMap: IconMap = {
  company,
  attention,
  ninjaIcon,
  openaiIcon,
  avatarAtlas,
  avatarBabak,
  avatarSam,
  avatarPeter,
  avatarMarwa,
  avatarDivya,
  avatarSoroush,
  avatarMasoud,
  avatarCarlos,
  avatarArash,
  avatarElla,
  avatarVince,
  avatarAndres,
  avatarKurt,
  defaultAvatar,
  waveform,
  gmailLogo,
};

type IconProps = {
  type?: string;
  alt?: string;
  size?: number;
  masterColor?: string;
  rest?: { [key: string]: React.ReactNode };
};

/**
 * Icon component displays icon with an image based
 * on the props provided, or a color circle instead.
 */
export const Icon = ({ type = '', size = 32, alt = '', masterColor = '', ...rest }: IconProps) => {
  const [color] = useState(masterColor === '' ? getCircleColor() : masterColor);
  const avatar = iconMap[type];
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
  };

  const circleClasses = classNames('circle', color);
  return (
    <span className="icon" style={styles}>
      {avatar ? (
        <img src={avatar} alt={alt} width={size} height={size} {...rest} />
      ) : (
        <span data-testid="circle-icon" className={circleClasses}>
          {alt}
        </span>
      )}
    </span>
  );
};
