import { isString, isBoolean } from './general.guard';
import { isApiMessage, isModelFeedback, isApiTask } from './api.guard';
import { ConversationEngineData, MessageLog, ConversationEngineSchedulingData,
  ConversationEngineRequest, ConversationEngineRequestWithTask } from './conversationengine';
import { isDebugData } from './data.guard';

/**
 * Guards data to be of ConversationEngineData type.
 * @param data unknown
 * @returns boolean
 */
export function isConversationEngineData(data: unknown): data is ConversationEngineData {
  const payload = data as ConversationEngineData;

  if (!('debug' in payload && isDebugData(payload.debug))) {
    return false;
  }

  if (!('feedback' in payload && isModelFeedback(payload.feedback))) {
    return false;
  }

  if (!('text' in payload && isString(payload.text))) {
    return false;
  }

  return true;
}

/**
 * Guards data to be of ConversationEngineSchedulingData type.
 * @param data unknown
 * @returns boolean
 */
export function isConversationEngineSchedulingData(data: unknown): data is ConversationEngineSchedulingData {
  if (!data) return false;

  const schedulingPayload = data as ConversationEngineSchedulingData;

  if (!('task_id' in schedulingPayload && isString(schedulingPayload.task_id))) {
    return false;
  }

  if (!('messages' in schedulingPayload && Array.isArray(schedulingPayload.messages))) {
    return false;
  }

  for (const message of schedulingPayload.messages) {
    if (!isApiMessage(message)) {
      return false;
    }
  }

  if (!('task_subject' in schedulingPayload && isString(schedulingPayload.task_subject))) {
    return false;
  }

  if (!('text' in schedulingPayload && isString(schedulingPayload.text))) {
    return false;
  }

  if ('feedback' in schedulingPayload && !isModelFeedback(schedulingPayload.feedback)) {
    console.log('isConversationEngineSchedulingData > feedback', schedulingPayload.feedback);
    return false;
  }

  return true;
}

/**
 * Guards data against MessageLog data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageLog(data: unknown): data is MessageLog {
  const log = data as MessageLog;

  if (!('content' in log && isString(log.content))) {
    return false;
  }

  if (!('role' in log && isString(log.role))) {
    return false;
  }

  return true;
}

/**
 * Guards data against ConversationEngineRequest data type.
 * @param data unknown
 * @returns boolean
 */
export function isConversationEngineRequest(data: unknown): data is ConversationEngineRequest {
  const request = data as ConversationEngineRequest;

  if (!('use_v2' in request && isBoolean(request.use_v2))) {
    return false;
  }

  if (!('user_name' in request && isString(request.user_name))) {
    return false;
  }

  if (!('assistant_name' in request && isString(request.assistant_name))) {
    return false;
  }

  if (!('location' in request && isString(request.location))) {
    return false;
  }

  if (!('time_zone' in request && isString(request.time_zone))) {
    return false;
  }

  if (!('current_user_input' in request &&  isString(request.current_user_input))) {
    return false;
  }

  if (!('today' in request && isString(request.today))) {
    return false;
  }

  if (!('message_logs' in request && Array.isArray(request.message_logs))) {
    return false;
  }

  for (const message_log of request.message_logs) {
    if (!isMessageLog(message_log)) {
      return false;
    }
  }

  return true;
}

/**
 * Guards data against conversation engine request with task data type.
 * @param data unknown
 * @returns boolean
 */
export function isConversationEngineRequestWithTask(data: unknown): data is ConversationEngineRequestWithTask {
  const requestWithTask = data as ConversationEngineRequestWithTask;

  if (!isConversationEngineRequest(requestWithTask)) {
    return false;
  }

  if (!isApiTask(requestWithTask)) {
    return false;
  }

  return false;
}
