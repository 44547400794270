import { Icon } from '../Icon';
import { getInitials } from 'src/utils';
import { AppUser } from 'src/types';

const SVG_SIZE = 24;

type ProfileCardProps = {
  user: AppUser;
}

export const Profile = ({ user }: ProfileCardProps) => {
  return (
    <div className="nj-account-bar--button">
      <span className="nj-account-bar--profile-icon">
        <Icon
          type={user.avatar}
          size={SVG_SIZE}
          alt={getInitials(user)}
          masterColor={user.color}
        />
      </span>
      <span className="nj-account-bar--profile-name">
        {user.first_name} {user.last_name}
      </span>
    </div>
  );
};
