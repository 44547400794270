import { useState } from 'react';
import { CaretDown, ChartBar } from '@phosphor-icons/react';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';

type TimeCardProps = {
  hours?: number;
};

enum TimeSavingFilter {
  Week = 'This week',
  Month = 'This month',
  Year = 'This year',
  All = 'All time',
}

const SVG_SIZE_SM = 16;
const SVG_SIZE = 20;

export const TimeCard = ({ hours = 0 }: TimeCardProps) => {
  const [filterBy, setFilterBy] = useState<TimeSavingFilter>(TimeSavingFilter.Week);
  return (
    <div className="nj-timecard">
      <div className="nj-timecard--time-saved">
        <span className="filter-by">Time saved:</span><br />
        <span className="text-lg">{hours} hrs</span>
      </div>
      <div className="nj-timecard--filters">
        <Menu
          menuClassName="nj-multi-menu"
          menuButton={<MenuButton><span data-testid="filter-by-time" className="filter-by">{filterBy}</span><CaretDown size={SVG_SIZE_SM} alt="Expand" /></MenuButton>}
          onItemClick={(e) => setFilterBy(e.value)}
          transition>
            {Object.values(TimeSavingFilter).map((filter, index) => <MenuItem key={index} value={filter}>{filter}</MenuItem>)}
            <MenuDivider />
            <MenuItem value="Savings insight"><ChartBar size={SVG_SIZE} />Savings insight</MenuItem>
        </Menu>
      </div>
    </div>
  );
};
