import { performanceMarkerEnd, performanceMarkerStart } from './performanceMarkers';

/* eslint-disable @typescript-eslint/no-explicit-any */
const resourceKey = process.env.REACT_APP_MSFT_TRANSLATION_KEY || '';
const resouceRegion = process.env.REACT_APP_MSFT_TRANSLATION_REGION || '';

/**
 * Map to cache translations.
 */
export const translations: Map<string, Map<string, string>> = new Map();

const cacheTranslation = (
  originalText: string,
  translatedText: string,
  originalLanguage: string,
  translatedLanguage: string,
) => {
  const originalTextMap = translations.get(originalText) || new Map<string, string>();

  originalTextMap.set(translatedLanguage, translatedText);

  translations.set(originalText, originalTextMap);

  // If we have original language, we can cache the reverse translation
  if (originalLanguage) {
    const translatedTextMap = translations.get(translatedText) || new Map<string, string>();

    translatedTextMap.set(originalLanguage, originalText);

    translations.set(translatedText, translatedTextMap);
  }
};

export const translateText = async (text: string, toLanguages: string[]) => {
  performanceMarkerStart('translate_text');

  const languagesToRequestTranslations: string[] = [];

  /**
   * For each passed in language check the cache to se if we already have the translation.
   * If we don't have translation for exact text to exact language, we will have to make a request
   * to get the translation.
   */
  toLanguages.forEach((toLanguage) => {
    const cachedTranslationText = translations.get(text)?.get(toLanguage);

    if (!cachedTranslationText) {
      languagesToRequestTranslations.push(toLanguage);
    } else {
      cacheTranslation(text, cachedTranslationText, '', toLanguage);
    }
  });

  if (languagesToRequestTranslations.length > 0) {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=${languagesToRequestTranslations.join(
        ',',
      )}`,
      {
        method: 'POST',
        headers: new Headers({
          'Ocp-Apim-Subscription-Key': resourceKey,
          'Ocp-Apim-Subscription-Region': resouceRegion,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify([
          {
            text,
          },
        ]),
      },
    );

    const parsedResponse = await response.json();

    parsedResponse?.[0]?.translations?.forEach((translation: any) => {
      cacheTranslation(
        text,
        translation.text,
        parsedResponse?.[0].detectedLanguage.language,
        translation.to,
      );
    });
  }

  performanceMarkerEnd('translate_text');

  return translations;
};
