import classNames from 'classnames';
import { useTabs, TabPanel } from "react-headless-tabs";
import parse from 'html-react-parser';

type ExampleProps = {
  title: string;
  tiles: string[];
}

type ExampleArrayProps = {
  tiles: ExampleProps[];
  onClick: (value: string) => void;
}

export const getTabID = (text: string): string => {
  return text.replace(' ','-').toLowerCase();
};

export const Example = ({ tiles, onClick }: ExampleArrayProps) => {
  const navItems = tiles.map(tile => tile.title);
  const [selectedTab, setSelectedTab] = useTabs(navItems);

  return (
    <div className="nj-chips-layout">
      <div className="nj-chips">
        {navItems.map((navItem, index) =>
          <div
            className={classNames("nj-chip", { selected: selectedTab === navItem })}
            key={`tab-${getTabID(navItem)}-${index}`}
            onClick={() => setSelectedTab(navItem)}
          >{navItem}</div>
        )}
      </div>
      <div className="nj-chips-panels">
        {tiles.map((tile, index) =>
          <TabPanel
            className="nj-chips-panel"
            hidden={selectedTab !== tile.title}
            key={`panel-${getTabID(tile.title)}-${index}`}>
            {tile.tiles.map((content, index) =>
              <div className="nj-tile" key={`tile-${getTabID(tile.title)}-${index}`}>
                <span>{parse(content)}</span>
                <span className="nj-tile--use" onClick={() => onClick(content)}>Use</span>
              </div>
            )}
          </TabPanel>
        )}
      </div>
    </div>
  );
};
