import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { HelpSection } from '../HelpSection';

/**
 * HelpList displays list of help text for different sections.
 */
export const HelpList = () => {
  const scheduleMeeting = useMemo(
    () => (
      <div>
        <p>Ask me to schedule a meeting and I'll get right on it.</p>
        <br />
        <p>
          <b>Schedule a meeting with someone in your Ninja contact list</b>
          <br />
          <em>“Hi Atlas, Set up a meeting with John”</em>
        </p>
        <br />
        <p>
          <b>Schedule a meeting at a specific time</b>
          <br />
          <em>“Hi Atlas, Set up a meeting with John on Monday at 4pm”</em>
        </p>
        <br />
        <p>
          <b>Schedule a meeting within a specific duration</b>
          <br />
          <em>“Hi Atlas, Set up a meeting with John next week”</em>
        </p>
        <br />
        <p>
          <b>Schedule a meeting with someone not in your Ninja contact list</b>
          <br />
          <em>“Hi Atlas, Set up a meeting with Adam. His email address is adam.smith@gmail.com”</em>
        </p>
        <br />
        <p>
          <b>Schedule a meeting with a group of guests</b>
          <br />
          <em>
            “Hi Atlas, Set up a 60 minute meeting with Adam, John, Mary, Jorge within the next two
            weeks?”
          </em>
        </p>
        <br />
        <p>
          <b>Make a request in your own words</b>
          <br />
          <em>“Hi Atlas, Can you find time with James for 30 minutes meeting”</em>
        </p>
        <br />
        <p>
          <em>“Hi Atlas, Set up a chat with Mary”</em>
        </p>
        <br />
        <p>
          <em>“Hi Atlas, Can you ping John for a meeting to discuss Atlas product launch”</em>
        </p>
      </div>
    ),
    [],
  );

  const makeReservation = useMemo(
    () => (
      <div>
        <p>Ask me to make a reservation at a restaurant</p>
        <br />
        <p>
          <b>Reserve a table at a restaurant in your Ninja contact list</b>
          <br />
          <em>“Hi Atlas, Can you reserve a table for 4 at Tabla for Friday between 7pm to 9pm?”</em>
        </p>
        <br />
        <p>
          <b>Reserve a table at a restaurant not in your Ninja contact list</b>
          <br />
          <em>
            “Hi Atlas, Can you reserve a table for 4 at Tabla for Friday between 7pm to 9pm?. Tabla
            restaurant’s phone number is +1111-111-1111”
          </em>
        </p>
      </div>
    ),
    [],
  );

  return (
    <div className="nj-help--list">
      <h1 className="title">Ways to use Ninja executive assistant</h1>
      <HelpSection
        title="Schedule a meeting"
        description={ReactDOMServer.renderToString(scheduleMeeting)}
      />
      <HelpSection
        title="Make a reservation"
        description={ReactDOMServer.renderToString(makeReservation)}
      />
    </div>
  );
};
