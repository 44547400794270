import { AuthEventData } from '@aws-amplify/ui';
import classNames from 'classnames';
import { useContext } from 'react';
import { Panel } from 'src/components/Panel';
import ThemeContext from 'src/contexts/ThemeContext';
import { useSession } from 'src/hooks';
import { useAppSelector, usersState } from 'src/store';
import { ChatMode } from 'src/types';
import { Conversation } from './components/Conversation';
import { EACard } from './components/EACard';
import { Account, AppNav } from 'src/components/AppNav';
import { TaskList } from './components/TaskList';
import { SettingsSection } from './components/SettingsSection';

interface ManageTasksProps {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
}

/**
 * Manage Tasks Page displays a list of tasks and task creation is enabled
 * by chat powered communication with EA via audio or text.
 */
export const ManageTasksChatPage = ({ signOut }: ManageTasksProps) => {
  const users = useAppSelector(usersState);

  const { isTasksListExpanded } = useContext(ThemeContext);
  const { selectedTaskId, updateSelectedTaskId, updateChatMode, appUser: user } = useSession();

  const handleSetCurrentTaskId = (id: string) => {
    if (id === 'default') {
      updateChatMode(ChatMode.CHAT);
    } else {
      updateChatMode(ChatMode.HASHTAG);
    }

    updateSelectedTaskId(id);
  };

  const firstPanelClasses = classNames('nj-manage-tasks', {
    hidden: !isTasksListExpanded,
  });

  return (
    <div className="nj-page manage-tasks">
      <Panel className={firstPanelClasses} data-testid="first-panel">
        <AppNav user={user} />
        <EACard
          users={users}
          selected={'default' === selectedTaskId}
          onClick={() => handleSetCurrentTaskId('default')}
        />
        <TaskList selectedTaskId={selectedTaskId} onSelectTask={handleSetCurrentTaskId} />
        <Account user={user} signOut={signOut} />
      </Panel>
      <Panel className="nj-converse-ai">
        <Conversation />
      </Panel>
      <SettingsSection user={user} />
    </div>
  );
};
