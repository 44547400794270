/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowsIn,
  ArrowsOut,
  ClosedCaptioning,
  UserFocus,
  Users,
  Check,
  ArrowsClockwise,
  Gear,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button } from 'src/components/Button';
import { IFrame } from 'src/components/IFrame';
import DebugContext from 'src/contexts/DebugContext';
import { useAvatarPreferences, useMessages, useSession, useTasks } from 'src/hooks';
import {
  feedbackState,
  setFeedbackData,
  tasksApi,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import {
  createConversationEngineRequestBody,
  createNewMessage,
  createNewTask,
  exitFullscreen,
  getAvatarURL,
  goFullScreen,
  isMoreThanOneDayOld,
  letMetaHumanTalk,
  loadConversationEngineResponse,
  pause,
  sendCameraSignalToMetahuman,
  haveConversedToday,
} from 'src/utils';
import { AppUser, ApiMessage, ConversationRole, MessageChannel, ChatMode, TaskState } from 'src/types';
import { performanceMarkerEnd, performanceMarkerStart } from 'src/utils/performanceMarkers';
import { ChatForm } from '../ChatForm';
import { ChatMessagesList } from '../ChatMessagesList';
import { ConversationFeedback } from '../ConversationFeedback';
import { PillarsCarousel } from '../PillarsCarousel/PillarsCarousel';
import { MetaHuman } from '../MetaHuman';
import { CONVERSATION_ENGINE_ENDPOINT } from 'src/constants';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
import { useSettings } from 'src/hooks/useSettings';

interface ConversationChatProps {
  taskId: string;
  user: AppUser;
}

export interface ConversationChatHandle {
  instantScrollIntoView: () => void;
  smoothScrollIntoView: () => void;
}

const DEFAULT_CHAT_OFFSET = 56;

const SVG_SIZE = 24;

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
};

enum ConversationEngineErrors {
  TIMEOUT = `Sorry, your request timed out. This may be due to a slow internet connection or high traffic on our servers. Please try again later.`,
  FAILURE = `Please rephrase your question and try again.`,
}

/**
 * Conversational pipeline to push messages into the chat.
 * ATTN(ella): This code is under ongoing refactoring.
 */
export const ConversationChat = ({ user, taskId }: ConversationChatProps) => {
  const { agent, chatMode } = useSession();
  const {
    userMessages: defaultMessages = [],
    addMessage,
    getUserMessages,
  } = useMessages(user, agent);
  const { selectTaskById, getTasks } = useTasks(user.user_id);

  const regularTask = selectTaskById(taskId) || createNewTask(user, agent);

  const chatMessages = taskId === 'default' ? defaultMessages : regularTask.messages || [];

  const [addMessageToTask] = tasksApi.useAddMessageToTaskMutation();

  const [value, setValue] = useState<string>('');

  const [requestToClipboard, setRequestToClipboard] = useState('');
  const [showTranscript, setShowTranscript] = useState<boolean>(true);
  const [expandedMeta, setExpandedMeta] = useState<boolean>(false);
  const [showAvatarSelector, setShowAvatarSelector] = useState<boolean>(false);
  const [submitTimer, setSubmitTimer] = useState<string>(new Date().toISOString());
  const [chatOffset] = useState(DEFAULT_CHAT_OFFSET);

  const { selectedTaskId, avatarAudioLocale, avatarCCLocale } = useSession();
  const { changeIsOpenSettingsSectionState, isOpenSettingsSection } = useSettings();
  const { debugMode } = useContext(DebugContext);
  const { feedbackData } = useAppSelector(feedbackState);
  const { incrementedAvatarCameraIndex, updateUser } = useAvatarPreferences(user.user_id);

  const refAnchor = useRef<HTMLDivElement>(null);
  const refIframe = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    scrollUp(50, true);
  }, []);

  useEffect(() => {
    if (submitTimer !== '') {
      setSubmitTimer('');
    }
  }, [submitTimer]);

  useEffect(() => {
    setValue('');
    scrollUp(50, true);
  }, [selectedTaskId]);

  useEffect(() => {
    if (!debugMode) {
      dispatch(setFeedbackData(null));
    }
  }, [debugMode, dispatch]);

  useHotkeys('ctrl+shift+a', () => {
    copy(requestToClipboard);
  });

  const scrollUp = async (delay: number, instant: boolean, ref = refAnchor) => {
    if (delay > 0) await pause(delay);
    if (ref.current) {
      if (instant && refIframe.current) {
        refIframe.current.scrollTop = refIframe.current.scrollHeight;
      } else {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const fetchAiData = async (updatedMessages: ApiMessage[], audioLocale?: string) => {
    const body = JSON.stringify(createConversationEngineRequestBody(user, agent, updatedMessages));

    performanceMarkerStart('ce-response-time');

    const res = await loadConversationEngineResponse({
      endpoint: CONVERSATION_ENGINE_ENDPOINT,
      options: { body },
      debug: debugMode,
      timeoutOptions: {
        timeout: 900000,
        error: ConversationEngineErrors.FAILURE,
      },
    });

    performanceMarkerEnd('ce-response-time', undefined, debugMode);

    const { data, message = '', created_task_id = null, tag = 'conversation', xTraceId = '' } = res;

    setRequestToClipboard(body);
    scrollUp(50, false);

    if (chatMode === ChatMode.AVATAR) {
      if (debugMode) {
        console.log('Meta Human Speaking: chat mode = ', chatMode);
      }
      letMetaHumanTalk(
        message,
        user.user_id,
        avatarCCLocale,
        audioLocale || avatarAudioLocale,
        debugMode,
      );
    }

    if (debugMode) {
      console.log('CE request:', body);
      console.log('CE response:', res);
    }

    // Create a new message or poll messages based on
    // scheduling or chit-chat intent.
    if (!created_task_id) {
      const newRecord = createNewMessage(
        message,
        tag,
        user,
        agent,
        { x_trace_id: xTraceId },
        created_task_id,
        data?.feedback,
      );

      // Chit-Chat
      if (taskId === 'default') {
        addMessage(newRecord);
      } else {
        addMessageToTask({ taskId, message: { ...newRecord, is_read: true } });
      }

      if (debugMode) {
        console.log('CE add new record:', newRecord);
      }
    } else {
      // Scheduling
      if (taskId === 'default') {
        getUserMessages();
      } else {
        getTasks();
      }

      if (debugMode) {
        console.log('CE new task ID:', created_task_id);
      }
    }
  };

  // Submit user request & show bubble:
  const handleSubmit = async (value: string, audioLocale?: string) => {
    if (value === '' || !value) return;

    setSubmitTimer(new Date().toISOString());

    const updatedMessages = (await getUserMessages()).data?.data || [];

    const tag =
      updatedMessages.at(-1)?.tag === 'error' ||
      updatedMessages.at(-1)?.tag === 'task-display' ||
      isMoreThanOneDayOld(updatedMessages.at(-1)?.timestamp)
        ? 'new-conversation'
        : 'conversation';

    const newMessage = {
      from_user_id: user.user_id,
      to_user_id: agent.user_id,
      content: value,
      timestamp: new Date().toISOString(),
      role: ConversationRole.USER,
      channel: MessageChannel.WEB_APP,
      tag,
    };

    if (debugMode) {
      console.log('handleSubmit >', value);
      console.log(newMessage);
    }

    setValue('');

    if (taskId === 'default') {
      addMessage(newMessage);
    } else {
      addMessageToTask({ taskId, message: { ...newMessage, is_read: true } });
    }

    return fetchAiData([...updatedMessages, newMessage], audioLocale);
  };

  const handleClearInput = () => {
    setValue('');
  };

  const handleAvatarFullscreen = () => {
    if (expandedMeta) {
      exitFullscreen();
    } else {
      if (refIframe.current) {
        goFullScreen(refIframe.current);
      }
    }
    setExpandedMeta(!expandedMeta);
  };

  const handleSendCameraSignalToMetahuman = () => {
    sendCameraSignalToMetahuman(user.user_id, incrementedAvatarCameraIndex, debugMode);
    updateUser({
      id: user.user_id,
      body: {
        metadata: { cameraIndex: incrementedAvatarCameraIndex },
      },
    });
  };

  const handleShowAvatarSelect = () => {
      console.log('showAvatarSelector', showAvatarSelector);
      setShowAvatarSelector(!showAvatarSelector);
  };

  const handleOpenSettingsSection = () => changeIsOpenSettingsSectionState(true, SettingsTabs.EXECUTIVE_ASSISTANT);

  const chatClasses = classNames({
    'nj-converse-ai-chat': chatMode === ChatMode.CHAT || chatMode === ChatMode.HASHTAG,
    // 'nj-converse-ai-teleninja': chatMode === ChatMode.TELENINJA,
    'nj-audio-converse': chatMode === ChatMode.AVATAR,
    expanded: !feedbackData,
  });

  const footerClasses = classNames('nj-converse-ai-footer', {
    expanded: !feedbackData,
    withoutBackground: chatMode === ChatMode.AVATAR
  });

  const statusIcon = useMemo(() => {
    if (regularTask.state === TaskState.COMPLETED) {
      return (
      <div className="nj-task-state completed">
        <span className="nj-task-card--icon">
          <Check data-testid="task-check-icon" {...ICON_PROPS} />
        </span> AI completed
      </div>);
    }
    return (
      <div className="nj-task-state">
        <span className="nj-task-card--icon progress">
          <ArrowsClockwise data-testid="task-arrows-clock-wise-icon" {...ICON_PROPS} />
        </span> AI in progress
      </div>);
  }, [regularTask]);

  return (
    <>
      <div
        className={chatClasses}
        style={{ height: chatMode === ChatMode.AVATAR ? `100%` : `calc(100% - ${chatOffset}px)` }}
        ref={refIframe}
      >
        {chatMode === ChatMode.AVATAR && (
          <MetaHuman
            userId={user.user_id}
            value={value}
            onValueChange={setValue}
            onValueSubmit={handleSubmit}
            showAvatarSelector={showAvatarSelector}
            onCloseAvatarSelector={() => setShowAvatarSelector(false)}
          >
            <IFrame
              url={`${getAvatarURL(
                user.user_id,
                debugMode,
              )}/?hide_ui=true&fullscreen=true&hideMic=true`}
              allowFullScreen={true}
              userId={user.user_id}
              debug={debugMode}
            ></IFrame>
          </MetaHuman>
        )}
        <div
          className={classNames({
            thread: chatMode !== ChatMode.AVATAR,
            'nj-messages-list': chatMode === ChatMode.AVATAR,
            'show-transcript': showTranscript,
          })}
        >
        <ChatMessagesList
          value={value}
          isBasic={chatMode === ChatMode.AVATAR}
          taskId={taskId}
          user={user}
          messages={chatMessages}
          onScrollUp={scrollUp}
        />
        {taskId === 'default' && chatMode === ChatMode.CHAT && !haveConversedToday(chatMessages) &&
          <PillarsCarousel
          user={user}
          onClick={setValue}
          shouldShowToday={true}
        />}
        {chatMode !== ChatMode.AVATAR && <div ref={refAnchor}></div>}
        </div>
        {chatMode === ChatMode.AVATAR && (
        <footer className={footerClasses}>
          <div className="nj-avatar-controls">
            <div className="nj-button--left-wrapper">
              <Button
                className={classNames('nj-button--meta', 'transcript', {
                  selected: showTranscript,
                })}
                onClick={() => setShowTranscript(!showTranscript)}
              >
                <ClosedCaptioning size={SVG_SIZE} color="currentColor" weight="fill" />
              </Button>
              <Button className={classNames('nj-button--meta', 'transcript', {
                selected: isOpenSettingsSection,
              })} onClick={handleOpenSettingsSection}>
                <Gear size={SVG_SIZE} color="currentColor" />
              </Button>
            </div>
            <div className="nj-button--right-wrapper">
              <Button
                className={classNames('nj-button--meta', 'selector', {
                  selected: showAvatarSelector,
                })}
                onClick={handleShowAvatarSelect}
              >
                <Users
                  size={SVG_SIZE}
                  color="currentColor"
                  weight={showAvatarSelector ? 'fill' : 'regular'}
                />
              </Button>
              <Button
                className={classNames('nj-button--meta')}
                onClick={handleSendCameraSignalToMetahuman}
              >
                <UserFocus size={SVG_SIZE} color="currentColor" />
              </Button>
              <Button
                className={classNames('nj-button--meta', 'scale', { selected: expandedMeta })}
                onClick={handleAvatarFullscreen}
              >
                {expandedMeta ? <ArrowsIn size={SVG_SIZE} /> : <ArrowsOut size={SVG_SIZE} />}
              </Button>
            </div>
          </div>
          <ChatForm
            userId={user.user_id}
            isAvatarMode
            isAudioMode={false}
            value={value}
            classname="chat-on"
            onValueChange={setValue}
            onValueSubmit={handleSubmit}
            onClear={handleClearInput}
            shouldShowBrowseExamples={taskId === 'default'}
          />
        </footer>
        )}
        {taskId !== 'default' && statusIcon}
      </div>
      {chatMode !== ChatMode.AVATAR && (
        <footer className={footerClasses}>
          <ChatForm
            userId={user.user_id}
            isAudioMode={false}
            value={value}
            classname="chat-on"
            onValueChange={setValue}
            onValueSubmit={handleSubmit}
            onClear={handleClearInput}
            shouldShowBrowseExamples={taskId === 'default'}
        />
        </footer>
      )}
      <ConversationFeedback />
    </>
  );
};
