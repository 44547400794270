/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import { useSession } from 'src/hooks';
import { useSpeechRecognizer } from 'src/hooks/useSpeechRecognizer';
import { interruptMetahuman } from 'src/utils'; // animateMetahuman
import { AvatarSelector } from '../AvatarSelector';
import { MetaHumanEvent, useMetaHumanEvent } from './hooks/useMetaHumanEvent';

type MetaHumanProps = {
  userId: string;
  value: string;
  children: React.ReactNode;
  onValueChange: (value: string) => void;
  onValueSubmit: (value: string, audioLocale?: string) => Promise<void>;
  showAvatarSelector: boolean;
  onCloseAvatarSelector: () => void;
};

/**
 * Shows Meta Human Assistant in IFrame.
 */
const MetaHuman = ({
  userId,
  children,
  onValueChange,
  onValueSubmit,
  showAvatarSelector,
  onCloseAvatarSelector,
}: MetaHumanProps) => {
  const { debugMode } = useContext(DebugContext);

  const [metaHumanTalking, setMetaHumanTalking] = useState<boolean>(false);
  const [apiRequestInProgress, setApiRequestInProgress] = useState<boolean>(false);
  const { avatarAudioLocale } = useSession();

  const isInLimbo = useMemo(
    () => metaHumanTalking && !apiRequestInProgress,
    [metaHumanTalking, apiRequestInProgress],
  );

  useEffect(() => {
    return () => {
      stopRecording();
      interruptMetahuman(userId, debugMode);
    };
  }, []);

  const handleSpeechRecognized = async (value: string, audioLocale?: string) => {
    /**
     * We don't want to send the message if "stop record" was clicked.
     */
    if (!recordInProgressRef?.current) {
      // Reset the value so that new bubble does not appear.
      onValueChange('');
      return;
    }

    onValueChange(value);

    // Mute mic when making a request. Unmute happens when metaHuman stops talking.
    muteMicrophone();

    await handleSubmit(value, audioLocale);

    /**
     *  Event that is coming from the iFrame is taking some time and UI behavior in not smooth.
     *  So setting metaHumanTalknig to true as soon as we send the request.
     */
    setMetaHumanTalking(true);
  };

  const handleSpeechRecognizing = (value: string) => {
    onValueChange(value);
  };

  const {
    stopSpeechRecognizing,
    recordInProgressRef,
    recordInProgress,
    muteMicrophone,
    unMuteMicrophone,
  } = useSpeechRecognizer({
    onRecognizing: handleSpeechRecognizing,
    onRecognized: handleSpeechRecognized,
    sourceLanguage: avatarAudioLocale,
  });

  const handleSubmit = async (value: string, audioLocale?: string) => {
    // animateMetahuman(userId, AvatarAnimations.THINKING, false);

    setApiRequestInProgress(true);
    await onValueSubmit(value, audioLocale);
    setApiRequestInProgress(false);
  };

  const stopRecording = async () => {
    if (apiRequestInProgress) {
      /**
       *  Mic button is disabled while request is in progress.
       *  So no action should happen on click.
       *  - If we go ahead and send the request to interrupt the metahuman it won't work.
       *  - If we stop the recording and fully reset the Mic, then we won't have ability to interrupt the metahuman.
       */
      return;
    }

    if (isInLimbo) {
      interruptMetahuman(userId, debugMode);
    }
    setMetaHumanTalking(false);

    stopSpeechRecognizing();
  };

  /**
   * Handles events from MetaHuman iFrame
   */
  useMetaHumanEvent(
    useCallback(
      (metaHumanEvent: MetaHumanEvent) => {
        if (metaHumanEvent.silence) {
          if (!recordInProgress) {
            return;
          }
          setMetaHumanTalking(false);
          // animateMetahuman(userId, AvatarAnimations.LISTENING, false);

          // unmute mic as soon as MetaHuman is done talking.
          unMuteMicrophone();
        }
      },
      [recordInProgress],
    ),
  );

  return (
    <div className="nj-meta-human">
      <AvatarSelector userId={userId} isOpen={showAvatarSelector} onClose={onCloseAvatarSelector} />
      {children}
    </div>
  );
};

export default memo(MetaHuman);
