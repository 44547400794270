type ContentPaneProps = {
  children?: React.ReactNode;
};

/**
 * ContentPane displays pages per chosen route.
 */
export const ContentPane = ({ children }: ContentPaneProps) => {
  return <div className="nj-content-pane">{children}</div>;
};
