/* eslint-disable react-hooks/exhaustive-deps */
import { Authenticator } from '@aws-amplify/ui-react';
import { datadogRum } from '@datadog/browser-rum';
import { Amplify, Auth } from 'aws-amplify';
import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ThemeContext from 'src/contexts/ThemeContext';
import { DashboardPage } from 'src/pages/DashboardPage';
import { HelpPage } from 'src/pages/HelpPage';
import { ManageSkillsPage } from 'src/pages/ManageSkillsPage';
import { ManageTasksChatPage } from 'src/pages/ManageTasksChatPage';
import { NotificationsPage } from 'src/pages/NotificationsPage';
import { ProfilePage } from 'src/pages/ProfilePage';
import { SettingsPage } from 'src/pages/SettingsPage';
import {
  fetchAgents,
  fetchDefaultAgent,
  fetchUserByEmail,
  messagesApi,
  tasksApi,
  useAppDispatch,
} from 'src/store';
import { ContentPane } from './ContentPane';
import { SSOLoginFooter } from './SSOLoginFooter';
import { isCognitoUser, isApiUser } from 'src/types';
import { useSession } from 'src/hooks';

/**
 * App Component loads sidebar and content area with two panels.
 * TODO(ella): Create a button to toggle theme between light mode and dark mode.
 * Pull toggleTheme from ThemeContext: onClick={toggleTheme}.
 */

Amplify.configure({
  Auth: {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_OATH_DOMAIN}`, // TODO make dynamic based on stage.
      scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_NINJA_UI_URL}`,
      redirectSignOut: `${process.env.REACT_APP_NINJA_UI_URL}`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

datadogRum.init({
  applicationId: `${process.env.REACT_APP_DATADOG_APP_ID}`,
  clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  site: `${process.env.REACT_APP_DATADOG_SITE}`,
  service: 'ninja_ea_frontend',
  env: `${process.env.REACT_APP_ENVIRONMENT}`,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  premiumSampleRate: 100,
  trackUserInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const App = () => {
  const { isDarkTheme } = useContext(ThemeContext);

  const { appUser } = useSession();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (!isCognitoUser(cognitoUser)) {
          throw new Error(`Not a cognito user ${cognitoUser}`);
        }

        const email = cognitoUser.attributes?.email;
        if (!email) {
          throw new Error(`Email is not retrievable from cognito login.`);
        }

        await dispatch(fetchUserByEmail(email));
      } catch (e: unknown) {
        // Todo(ella): add a message to application-wide error handling
        console.log(e);
      }
    };

    // TODO(ella): Add Redux Saga to synchronously load APIs.
    fetchUser();
    dispatch(fetchDefaultAgent());
    dispatch(fetchAgents());
  }, []);

  useEffect(() => {
    if (isApiUser(appUser)) {
      dispatch(messagesApi.endpoints.getMessages.initiate(appUser.user_id));
      dispatch(tasksApi.endpoints.getTasksByUserId.initiate(appUser.user_id));
    }
  }, [appUser, dispatch]);

  const components = {
    SignIn: {
      Footer() {
        return <SSOLoginFooter />;
      },
    },
  };

  const appClasses = classNames('nj-theme', {
    primary: isDarkTheme,
    login: appUser.user_id === '',
  });

  return (
    <Authenticator components={components} socialProviders={['google']}>
      {({ signOut }) => (
        <div className={appClasses}>
          <ContentPane>
          {appUser &&
            <Routes>
              <Route path="/" element={<ManageTasksChatPage signOut={signOut} />}></Route>
              <Route path="/dashboard" element={<DashboardPage user={appUser} />}></Route>
              <Route path="/manage-skills" element={<ManageSkillsPage />}></Route>
              <Route path="/settings" element={<SettingsPage />}></Route>
              <Route path="/notifications" element={<NotificationsPage />}></Route>
              <Route path="/help" element={<HelpPage />}></Route>
              <Route path="/profile" element={<ProfilePage />}></Route>
            </Routes>}
          </ContentPane>
        </div>
      )}
    </Authenticator>
  );
};

export default App;
