import { useMemo, useState, useEffect } from 'react';
import {
  Hash,
  ArrowsClockwise,
  WarningCircle,
  Check,
  PencilSimple,
  DotsThreeOutlineVertical,
} from '@phosphor-icons/react';
import classNames from 'classnames';
// import { useMarkAllReadTaskMessagesMutation } from 'src/store/services';
import { ApiTaskSelectable, TaskStatus, ApiTask } from 'src/types';
import { useTasks, useSession } from 'src/hooks';
import { Button } from 'src/components/Button';
import { Counter } from 'src/components/Counter';
import { TaskEditForm } from 'src/pages/ManageTasksChatPage/components/TaskEditForm';

type TaskProps = {
  task: ApiTaskSelectable;
  selected: boolean;
  onClick: (id: string) => void;
};

const SVG_SIZE = 20;
const ICON_PROPS = {
  size: SVG_SIZE,
  color: 'currentColor',
};

/**
 * Task component displays a row with task content which
 * holds task status: worked on, blocked or completed,
 * and short info on the task.
 */
export const Task = ({ task, onClick, selected }: TaskProps) => {
  const { task_subject, status, messages, task_id } = task;
  const { appUser } = useSession();

  const { updateTask } = useTasks(appUser.user_id);
  // const [markAllReadTaskMessages] = useMarkAllReadTaskMessagesMutation();

  const [editableStatus, toggleEditableStatus] = useState<boolean>(false);

  useEffect(() => {
    if (task_id && status === TaskStatus.COMPLETED) {
      // markAllReadTaskMessages(task_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id, status]);

  useEffect(() => {
    if (!selected) {
      toggleEditableStatus(false);
    }
  }, [selected]);

  const unReadCounter = useMemo(
    () => {
      if (status === TaskStatus.COMPLETED) return;

      return messages?.reduce((acc, item) => {
        if (item.from_user_id === appUser.user_id || item.is_read) {
          return acc;
        }
        return acc + 1;
      }, 0);
    },
    [status, messages, appUser.user_id],
  );

  const handleClick = () => {
    onClick(task.task_id || '');
  };

  const handleToggleEditableStatus = () => {
    toggleEditableStatus((state) => !state);
  };

  const handleUpdateTask = async (value: string) => {
    const updatedTask: ApiTask = {
      ...task,
      task_subject: value,
    };
    try {
      await updateTask({ userId: appUser.user_id, task: updatedTask }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const statusIcon = useMemo(() => {
    if (status === TaskStatus.BLOCKED) {
      return <WarningCircle data-testid="warning-circle-icon" {...ICON_PROPS} />;
    }
    if (status === TaskStatus.COMPLETED) {
      return <Check data-testid="check-icon" {...ICON_PROPS} />;
    }
    return <ArrowsClockwise data-testid="arrows-clock-wise-icon" {...ICON_PROPS} />;
  }, [status]);

  const classes = classNames('nj-task-card', {
    selected: selected,
  });

  const statusIconClasses = classNames('nj-task-card--icon', {
    progress: status === TaskStatus.NEW || status === TaskStatus.WORKING,
    warning: status === TaskStatus.BLOCKED,
  });

  return (
    <div data-testid="task" className={classes} onClick={handleClick}>
      <span className="nj-task-card--icon">
        <Hash {...ICON_PROPS} />
      </span>

      {selected && editableStatus && (
        <TaskEditForm
          defaultValue={task_subject || ''}
          onFormSubmit={handleUpdateTask}
          onToggleEditableStatus={handleToggleEditableStatus}
        />
      )}

      {selected && !editableStatus && (
        <>
          <h3 className="subtitle ellipsis">{task_subject}</h3>

          {task.status !== TaskStatus.COMPLETED &&
          <div className="nj-task-card--action-wrapper">
            <Button
              onClick={handleToggleEditableStatus}
              aria-label="Click to edit task"
              className="nj-task-card--button"
            >
              <PencilSimple {...ICON_PROPS} />
            </Button>

            <Button aria-label="Click to delete task" className="nj-task-card--button">
              <DotsThreeOutlineVertical {...ICON_PROPS} />
            </Button>
          </div>}
        </>
      )}

      {!selected && (
        <>
          <h3 className="subtitle ellipsis">{task_subject}</h3>

          <div className="nj-task-card--action-wrapper">
            {unReadCounter ? <Counter number={unReadCounter} /> : <span>&nbsp;</span>}

            <span data-testid="status-icon" className={statusIconClasses}>
              {statusIcon}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
