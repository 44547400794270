import ninjaCover from 'src/images/loader/cover.svg';
import ninjaGlowingEyes from 'src/images/loader/eyes-glow.svg';
import ninjaEyes from 'src/images/loader/eyes.svg';
import ninjaHeadBase from 'src/images/loader/head-base.svg';

/**
 * Loading/preloading screen.
 */
export const Loading = () => {
  return (
    <div className="nj-loading">
      <div className="nj-loading--ninja">
        <div className="nj-loading--ninja-eyes">
          <img src={ninjaEyes} alt="Glowing Ninja Eyes" />
        </div>
        <div className="nj-loading--ninja-cover">
          <img src={ninjaCover} alt="Ninja Cover" />
        </div>
        <div className="nj-loading--ninja-head">
          <img src={ninjaHeadBase} alt="Ninja Head Base" />
        </div>
        {
          <div className="nj-loading--ninja-glowing-eyes">
            <img src={ninjaGlowingEyes} alt="Glowing Ninja Eyes" />
          </div>
        }
      </div>
    </div>
  );
};
