import { configureStore, AnyAction, Reducer } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  persistCombineReducers,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import createWebStorage from 'reduxjs-toolkit-persist/lib/storage/createWebStorage';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import usersReducer, { usersState } from './slices/usersSlice';
import logoutReducer, { resetStore } from './slices/logoutSlice';
import audioModeReducer, { setAudioMode } from './slices/audioModeSlice';
import {
  fetchUsers,
  fetchUserById,
  fetchUserByEmail,
  fetchAgents,
  fetchDefaultAgent,
} from './thunks';
import sessionReducer, {
  resetSession,
  setSelectedTaskId,
  sessionState,
  setAvatarAudioLocale,
  setAvatarCCLocale,
  setAvatarBackgroundColor,
  setOpenAvatarByDefault,
  setChatAudioLocale,
  setChatMode,
} from './slices/sessionSlice';
import settingsReducer, {
  settingsState,
  resetSettings,
  setIsOpenSettingsSection,
  setActiveSettingsTab,
} from './slices/settingsSlice';
import feedbackReducer, {
  setFeedbackData,
  resetFeedbackData,
  feedbackState,
} from './slices/feedbackSlice';
import { tasksApi, messagesApi, feedbackApi, usersApi, contactsApi } from './services/index';

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: string) {
      return Promise.resolve(value);
    },
    removeItem(_key: string) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();
const persistConfig = {
  key: 'ninjaRoot',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    `${tasksApi.reducerPath}`,
    `${feedbackApi.reducerPath}`,
    `${messagesApi.reducerPath}`,
    `${usersApi.reducerPath}`,
    `${contactsApi.reducerPath}`,
  ],
};

const persistedReducer = persistCombineReducers(persistConfig, {
  users: usersReducer,
  logout: logoutReducer,
  audioMode: audioModeReducer,
  session: sessionReducer,
  settings: settingsReducer,
  feedback: feedbackReducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [messagesApi.reducerPath]: messagesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
});

// action.type === 'messages/clearThreadMessages'
const rootReducer: Reducer = (state: ReturnType<typeof persistedReducer>, action: AnyAction) => {
  if (action.type === 'logout/resetStore') {
    storage.removeItem('persist:ninjaRoot');
    return persistedReducer({} as unknown as RootState, action);
  }
  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      tasksApi.middleware,
      feedbackApi.middleware,
      messagesApi.middleware,
      usersApi.middleware,
      contactsApi.middleware,
    ),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        tasksApi.middleware,
        feedbackApi.middleware,
        messagesApi.middleware,
        usersApi.middleware,
        contactsApi.middleware,
      ),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { usersState };
export { fetchUserByEmail };
export { fetchAgents, fetchDefaultAgent, fetchUsers, fetchUserById };
export { resetStore, setAudioMode };
export {
  resetSession,
  setSelectedTaskId,
  sessionState,
  setAvatarCCLocale,
  setAvatarAudioLocale,
  setAvatarBackgroundColor,
  setOpenAvatarByDefault,
  setChatAudioLocale,
  setChatMode,
};
export {
  settingsState,
  resetSettings,
  setIsOpenSettingsSection,
  setActiveSettingsTab,
};
export { setFeedbackData, resetFeedbackData, feedbackState };
export { messagesApi, tasksApi, feedbackApi, usersApi, contactsApi };

export const persistor = persistStore(store);

export default store;
