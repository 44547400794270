import { performanceMarkerStart, performanceMarkerEnd } from './performanceMarkers';
import { translateText } from './translateText';
import { AvatarAnimations } from 'src/types';

export const AVATAR_DEV = process.env.REACT_APP_META_HUMAN_AUTOSCALE_IFRAME || '';

export const AVATAR_PROD = process.env.REACT_APP_META_HUMAN_IFRAME || '';

export const BABAK_ID = '9065526c-7371-4717-a71c-b13911cb3710';

export const ELLA_ID = 'b2cffd93-3ce2-4742-b58a-ad1efe397b54';

export const ANDRES_ID = '3064bd91-d05e-4c55-886b-05131f5c2303';

export const SAM_ID = '5c8bd410-3511-40c5-ad7d-4acb8a81bbda';

export const DIVYA_ID = '2d46c818-4ee8-4569-b30b-9d8db8c02cb2';

/**
 * interruptMetahuman sends a signal to Meta Human to stop talking.
 * @param userId string
 * @param debug boolean
 */
export async function interruptMetahuman(userId: string, debug = true) {
  const URL = getAvatarURL(userId, debug) + '/interrupt';
  if (debug) {
    console.log('Interrupt URL: ', URL);
  }
  await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
  });
}

/**
 * animateMetahuman() sends an animation signal to avatar to make it think, listen etc.
 * @param userId string
 * @param debug boolean
 * @param emotion AvatarAnimations
 */
export async function animateMetahuman(userId: string, emotion: AvatarAnimations, debug = true) {
  const URL = getAvatarURL(userId, debug) + '/animation?state=' + emotion;
  if (debug) {
    console.log('Avatar emotion URL: ', URL);
  }
  await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
  });
}

/**
 * Get Avatar URL as a base URL for the avatar api.
 * @param userId string
 * @param debug boolean
 * @returns string
 */
export function getAvatarURL(userId: string, debug = false): string {
  return isProdUser(userId, debug) ? AVATAR_PROD : AVATAR_DEV;
}

/**
 * getAvatarToTalkURL provides URL handle to send speech to.
 * @param userId string
 * @param debug boolean
 * @returns string
 */
export function getAvatarToTalkURL(userId: string, debug = false): string {
  return getAvatarURL(userId, debug) + '/input/text';
}

/**
 * Is prod user returns true if a user is Babak.
 * @param userId string
 * @param debug boolean
 * @returns boolean
 */
export function isProdUser(userId: string, debug = false): boolean {
  if (debug) {
    console.log('Is Prod User (Babak, Sam, Ella, Andres, Divya) = ', userId);
  }
  // Temporary disabling VIP access, giving access to all.
  // return BABAK_ID === userId ||  SAM_ID === userId || DIVYA_ID === userId;
  // return BABAK_ID === userId || ELLA_ID === userId || ANDRES_ID === userId || SAM_ID === userId || DIVYA_ID === userId;
  return (
    window.location.hostname === 'atlas.prod.myninja.ai'
    // || window.location.hostname === 'atlas.beta.myninja.ai'
  );
}

/**
 * GET Endpoint that sends data to iFrame to talk on behalf of Open AI.
 * @param speech string
 * @param userId string
 */
export async function letMetaHumanTalk(
  speech: string,
  userId: string,
  CCLocale: string,
  audioLocale: string,
  debug = false,
) {
  let text = speech.replace(/\s/gi, '%20');
  //let avatarCCLocale = CCLocale;

  /**
   * For non english languages we send the translation to the avatar, so that avatar
   * does not have to translate it.
   */
  if (audioLocale !== 'en-US') {
    const avatarAudioLanguage = audioLocale.slice(0, 2);

    const translationMap = await translateText(speech, [avatarAudioLanguage]);
    const translation = translationMap.get(speech)?.get(avatarAudioLanguage);
    if (translation) {
      text = translation.replace(/\s/gi, '%20');

      // Replace the CC locale with the audio locale
      //avatarCCLocale = audioLocale;
    }
  }

  // The avatar server expects the CCLang to the lang of the text being sent to
  // This is a separate setting than the CC (closed captioning)
  // shown in the FE
  // https://avatar.myninja.ai/input/text?AudioLang=en-US&CCLang=en-US&data=How%20are%20you%20today?
  const endpoint = `${getAvatarToTalkURL(
    userId,
    debug,
  )}?AudioLang=${audioLocale}&CCLang=${audioLocale}&data=${text}`;
  if (debug) {
    console.log('AVATAR DEBUG > ', endpoint);
  }

  performanceMarkerStart('avatar_response_time');

  const result = await fetch(endpoint, {
    method: 'GET',
    mode: 'no-cors',
    // for some reason the cookies aren't sent back to the myninja.ai
    // endpoint when 'same-origin' is set. It works with "include",
    // which sends the cookies to all of them
    // credentials: 'same-origin',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  });

  performanceMarkerEnd('avatar_response_time', {}, debug);

  if (debug) {
    console.log('CALLING FETCH & RESULT =', result);
  }
}

/**
 * isFullScreenEnabled tests user machine for fullscreen.
 * @returns boolean
 */
export function isFullScreenEnabled() {
  return (
    'fullscreenEnabled' in document ||
    'webkitIsFullScreen' in document ||
    'mozFullScreen' in document ||
    'msFullscreenElement' in document
  );
}

/**
 * goFullScreen expands iframe to be fullscreen.
 * @param element
 */
export function goFullScreen(element?: HTMLVideoElement | HTMLIFrameElement | HTMLDivElement) {
  if (!element) return;

  const requestMethod = element.requestFullscreen;

  if (requestMethod) {
    requestMethod.apply(element);
  }
}

/**
 * exitFullScreen contracts iframe to be fullscreen.
 * @param element
 */
export function exitFullscreen() {
  if (document.exitFullscreen !== null) {
    document.exitFullscreen();
  }
}

/**
 * change avatar from avatar selector.
 * @param userId string
 * @param avatarName string
 * @param debug boolean
 */
export async function changeMetahumanAvatar(userId: string, avatarName: string, debug = true) {
  const URL = getAvatarURL(userId, debug) + `/avatar?name=${avatarName}&index=1`;
  if (debug) {
    console.log('changeMetahumanAvatar URL: ', URL);
  }
  await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
  });
}

/**
 * sendCameraSignalToMetahuman sends a signal to Meta Human from camera.
 * @param userId string
 * @param debug boolean
 */
export async function sendCameraSignalToMetahuman(userId: string, index = 0, debug = true) {
  const URL = getAvatarURL(userId, debug) + `/camera?index=${index}`;
  if (debug) {
    console.log('sendCameraSignalToMetahuman URL: ', URL);
  }
  await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
  });
}

/**
 * change avatar backgroung color.
 * @param userId string
 * @param r number
 * @param g number
 * @param b number
 * @param debug boolean
 */
export async function changeMetahumanBackgrounColor(
  userId: string,
  r: number,
  g: number,
  b: number,
  debug = true,
) {
  const URL = getAvatarURL(userId, debug) + `/background?color=${r},${g},${b}`;
  if (debug) {
    console.log('changeMetahumanAvatar URL: ', URL);
  }
  await fetch(URL, {
    method: 'GET',
    mode: 'no-cors',
  });
}
