import { CaretLeft, Hash } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useContext } from 'react';
import ThemeContext from 'src/contexts/ThemeContext';
import { useTasks, useSession } from 'src/hooks';

const SVG_SIZE = 20;

interface TaskMenuButtonProps {
  reversed?: boolean;
}

/**
 * MainNavToggleButton for opening tasks list.
 */
export const MainNavToggleButton = ({ reversed = false }: TaskMenuButtonProps) => {
  const { appUser } = useSession();

  const { tasksQuantity } = useTasks(appUser.user_id);
  const { isTasksListExpanded, toggleTasksListExpanded } = useContext(ThemeContext);

  const classes = classNames('nj-thread-toggle', {
    hidden: isTasksListExpanded && !reversed,
    border: reversed,
  });

  return (
    <div className={classes}>
      <button aria-label="Click to expand the tasks list" onClick={toggleTasksListExpanded}>
        {!reversed && <Hash size={SVG_SIZE} color="currentColor" weight="regular" />}
        {reversed && <CaretLeft size={SVG_SIZE} color="currentColor" weight="regular" />}
        <span data-testid="tasks-number" className="reddot">
          {tasksQuantity}
        </span>
      </button>
    </div>
  );
};
