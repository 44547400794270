import classNames from 'classnames';
import { TabPanel } from 'react-headless-tabs';
import { Button } from 'src/components/Button';
import { useSettings } from 'src/hooks/useSettings';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
import { GeneralSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/GeneralSettings';
import { ProfileSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/ProfileSettings';
import {
  ExecutiveAssistanceSettings
} from 'src/pages/ManageTasksChatPage/components/SettingsSection/ExecutiveAssistanceSettings';
import { PreferencesSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/PreferencesSettings';
import { AccessAndDataSettings } from 'src/pages/ManageTasksChatPage/components/SettingsSection/AccessAndDataSettings';
import { AppUser } from 'src/types';

interface SettingsSectionProps {
  user: AppUser;
}
export const SettingsSection = ({user}: SettingsSectionProps) => {
  const { isOpenSettingsSection, changeIsOpenSettingsSectionState, changeSelectedSettingsTab, activeSettingsTab } = useSettings();
  const handleCloseSettingsSection = () => {
    changeIsOpenSettingsSectionState(false);
  };

  const settingsList = [
    {title: SettingsTabs.GENERAL, content: <GeneralSettings />},
    {title: SettingsTabs.PROFILE, content: <ProfileSettings />},
    {title: SettingsTabs.EXECUTIVE_ASSISTANT, content: <ExecutiveAssistanceSettings userId={user.user_id} />},
    {title: SettingsTabs.PREFERENCES, content: <PreferencesSettings />},
    {title: SettingsTabs.ACCESS_AND_DATA, content: <AccessAndDataSettings />},
  ]

  return <div
    data-testid='settings-section'
    className={classNames('nj-settings-section', { open: isOpenSettingsSection })}
  >
    <div className='nj-settings-section--header'>
      <h2 className='nj-settings-section-title'>Settings</h2>
      <div className='nj-settings-section--header-buttons'>
        <Button onClick={handleCloseSettingsSection} className='cancel'>Cancel</Button>
        <Button onClick={handleCloseSettingsSection} className='save'>Save</Button>
      </div>
    </div>
    <div className='nj-settings-section-tabs'>
      <nav className="nj-tabbed-layout--tabs">
        {(Object.keys(SettingsTabs) as Array<keyof typeof SettingsTabs>).map((setting, index) => {
          return (
            <div
              className={classNames("nj-tabbed-layout--tab", { selected: activeSettingsTab === SettingsTabs[setting] })}
              onClick={() => changeSelectedSettingsTab(SettingsTabs[setting])}
              key={`settingtab-${setting}-${index}`}
            >
              {SettingsTabs[setting]}
            </div>
          );
        })}
      </nav>
    </div>
    <div className="nj-tabbed-layout--panels">
      {settingsList.map((setting, index) =>
        <TabPanel
          hidden={activeSettingsTab !== setting.title}
          key={`examplepanel-${setting.title}-${index}`}>
          {setting.content}
        </TabPanel>
      )}
    </div>
  </div>;
}
