import { useMediaQuery } from 'react-responsive';

type Breakpoints = 'isDesktop' | 'isLaptop' | 'isTablet' | 'isMobile';

type UseBreakpoint = Record<Breakpoints, boolean>;

const BREAKPOINT_DESKTOP = 1200;
const BREAKPOINT_LAPTOP = 992;
const BREAKPOINT_TABLET = 768;

export const useBreakpoint = (): UseBreakpoint => ({
  isDesktop: useMediaQuery({ minWidth: BREAKPOINT_DESKTOP }),
  isLaptop: useMediaQuery({ minWidth: BREAKPOINT_LAPTOP }),
  isTablet: useMediaQuery({ minWidth: BREAKPOINT_TABLET }),
  isMobile: useMediaQuery({ maxWidth: BREAKPOINT_TABLET - 1 }),
});
