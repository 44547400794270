/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TestPayload = {
    payload_type: TestPayload.payload_type;
    content?: string;
};

export namespace TestPayload {

    export enum payload_type {
        TEST = 'test',
    }


}
