import {
  ApiMessage,
  ApiTaskSelectable,
  TaskSkill,
  TaskStatus,
  TaskState,
  MessageChannel,
  TaskChannel,
} from 'src/types';

export const DEFAULT_MOCKED_USER_ID = 'DEFAULT_MOCKED_USER_ID';
export const DEFAULT_MOCKED_TASK_ID = 'DEFAULT_MOCKED_TASK_ID';
export const DEFAULT_MOCKED_MESSAGE_ID = 'DEFAULT_MOCKED_MESSAGE_ID';

export const DEFAULT_MOCKED_CHAT_MESSAGE: ApiMessage = {
  message_id: 'default_message_id',
  timestamp: '2023-07-01T14:00:00',
  from_user_id: 'from_user_id',
  to_user_id: DEFAULT_MOCKED_USER_ID,
  channel: MessageChannel.WEB_APP,
  content: 'Test message 1',
};

export const DEFAULT_MOCKED_TASK_BY_ID: ApiTaskSelectable = {
  task_id: DEFAULT_MOCKED_TASK_ID,
  task_subject: 'Test Subject Ninja Tech AI',
  user_id: DEFAULT_MOCKED_USER_ID,
  skill: TaskSkill.SCHEDULING,
  state: TaskState.IN_PROGRESS,
  channel: TaskChannel.WEB_APP,
  created_at: '2023-07-12T09:00:00.000Z',
  messages: [DEFAULT_MOCKED_CHAT_MESSAGE],
  status: TaskStatus.WORKING,
  updated_at: '2023-07-12T09:00:00.000Z',
};
