import { CaretDown, CaretUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useState } from 'react';
import { EllipsisText } from 'src/components/EllipsisText';

const DESCRIPTION_MAX_LINE = 1;
const SVG_SIZE = 20;

interface HelpSectionProps {
  title: string;
  description: string;
}

/**
 * HelpSection displays information for each section.
 */
export const HelpSection = ({ title, description }: HelpSectionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleExpanding = () => {
    setIsExpanded(!isExpanded);
  };

  const classes = classNames('nj-help--section', {
    expanded: isExpanded,
  });

  return (
    <div className={classes}>
      <div className="content">
        <div role="button" className="collapse-header" onClick={toggleExpanding}>
          <span className="section-title">{title}</span>
          <span className="header-icon">
            {isExpanded ? (
              <CaretUp
                data-testid="caret-up"
                size={SVG_SIZE}
                color="currentColor"
                weight="regular"
              />
            ) : (
              <CaretDown
                data-testid="caret-down"
                size={SVG_SIZE}
                color="currentColor"
                weight="regular"
              />
            )}
          </span>
        </div>
        <div className="collapse-body">
          <EllipsisText
            isEllipsis={!isExpanded}
            maxLine={DESCRIPTION_MAX_LINE}
            text={description}
            className="description"
          />
        </div>
      </div>
    </div>
  );
};
