import { rest } from 'msw';
import { ApiMessage, MessageChannel } from 'src/types';
import { v2BaseUrl } from '../config';
import { AddMessageThreadRequest } from '../messagesApi';
import {
  DEFAULT_MOCKED_USER_ID,
  DEFAULT_MOCKED_CHAT_MESSAGE,
  DEFAULT_MOCKED_MESSAGE_ID,
} from './mockedConstants';

export const MOCKED_CHAT_MESSAGE: ApiMessage = {
  message_id: 'message_id_2',
  timestamp: '2023-07-11T20:00:00',
  from_user_id: DEFAULT_MOCKED_USER_ID,
  to_user_id: 'to_user_id',
  channel: MessageChannel.WEB_APP,
  content: 'Test message 2',
};

export const messagesApiMock = [
  rest.get(`${v2BaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/messages`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ data: [MOCKED_CHAT_MESSAGE, DEFAULT_MOCKED_CHAT_MESSAGE] }),
    );
  }),

  rest.delete<string>(
    `${v2BaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/messages`,
    async (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),

  rest.post<AddMessageThreadRequest>(
    `${v2BaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/messages`,
    async (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),

  rest.patch<AddMessageThreadRequest>(
    `${v2BaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/messages/${DEFAULT_MOCKED_MESSAGE_ID}`,
    async (req, res, ctx) => {
      return res(ctx.status(200));
    },
  ),
];
