import { Calendar, CalendarCheck, Chats, Lightbulb, CirclesThreePlus, Icon } from '@phosphor-icons/react';
import classNames from "classnames";
import { useTabs, TabPanel } from "react-headless-tabs";
import { Example, getTabID } from './Example';
import { EditableInserts } from 'src/types';

type TileType = {
  title: string;
  tiles: Array<string>;
}

export type ExampleType = {
  title: string;
  icon: Icon;
  tiles: Array<TileType>;
};

export const EXAMPLES_DATA = [
  {
    title: '/scheduling',
    icon: Calendar,
    tiles: [{
      title: 'Schedule a meeting',
      tiles: [
        `<strong>/calendar</strong> Schedule a meeting with @<span class="nj-replace">${EditableInserts.CONTACT}</span> and <span class="nj-replace">${EditableInserts.CONTACT_EMAIL}</span> for <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>`,
        `<strong>/calendar</strong> Schedule a meeting with @<span class="nj-replace">${EditableInserts.CONTACT}</span>, @<span class="nj-replace">${EditableInserts.CONTACT}</span>, @<span class="nj-replace">${EditableInserts.CONTACT}</span> and @<span class="nj-replace">${EditableInserts.CONTACT}</span> to discuss <span class="nj-replace">${EditableInserts.TOPIC}</span>`,
        `<strong>/calendar</strong> Schedule an urgent meeting by this Friday with @<span class="nj-replace">${EditableInserts.CONTACT}</span> to discuss <span class="nj-replace">${EditableInserts.TOPIC}</span>`,
        `<strong>/calendar</strong> Schedule a weekly recurring 1:1 with @<span class="nj-replace">${EditableInserts.CONTACT}</span> for Monday afternoons`,
        `<strong>/calendar</strong> Schedule a bi-weekly recurring 1:1 with @<span class="nj-replace">${EditableInserts.CONTACT}</span> based on their availability`,
      ],
    }, {
      title: 'Time Blocks',
      tiles: [
        `<strong>/calendar</strong> Put time on my calendar for daily walks @ 2-3`,
        `<strong>/calendar</strong> Add a 2 hr focus time for tomorrow afternoon`,
      ]
    }, {
      title: 'Update a Meeting',
      tiles: [
        `<strong>/calendar</strong> Move my Friday 1pm meeting to 3pm`,
        `<strong>/calendar</strong> I am not feeling well, reschedule all of today's meetings`,
        `<strong>/calendar</strong> Add @<span class="nj-replace">${EditableInserts.CONTACT}</span> for Friday's team meeting`,
        `<strong>/calendar</strong> Add @<span class="nj-replace">${EditableInserts.CONTACT}</span> as optional to #<span class="nj-replace">${EditableInserts.ACTION_ITEM}</span>`,
        `<strong>/calendar</strong> Remove @<span class="nj-replace">${EditableInserts.CONTACT}</span> from <span class="nj-replace">${EditableInserts.MEETING_DETAILS}</span>`,
        `<strong>/calendar</strong> Add the below Agenda to #<span class="nj-replace">${EditableInserts.ACTION_ITEM}</span>.`,
      ]
    }, {
      title: 'Query the Calendar',
      tiles: [
        `<strong>/calendar</strong> How can you help me make my calendar more efficient?`,
        `<strong>/calendar</strong> How does my day look like?`,
        `<strong>/calendar</strong> Are there any conflicts for today?`,
        `<strong>/calendar</strong> When was my last meeting with @<span class="nj-replace">${EditableInserts.CONTACT}</span>`,
      ],
    }]
  }, {
    title: '/outreach',
    icon: Chats,
    tiles: [{
      title: 'Inquiry',
      tiles: [
        `<strong>/outreach</strong> Check with <span class="nj-replace">${EditableInserts.EMAIL}</span> if they are <span class="nj-replace">${EditableInserts.DETAILS}</span>?`,
        `<strong>/outreach</strong> Contact <span class="nj-replace">${EditableInserts.EMAIL}</span> and ask when <span class="nj-replace">${EditableInserts.ACTION}</span> by <span class="nj-replace">${EditableInserts.TIME}</span>`,
        `<strong>/outreach</strong> Check with <span class="nj-replace">${EditableInserts.EMAIL}</span> if they have <span class="nj-replace">${EditableInserts.DETAILS}</span>`,
      ],
    }]
  }, {
    title: '/research',
    icon: Lightbulb,
    tiles: [{
      title: 'Topic',
      tiles: [
        `<strong>/research</strong> What are the current trends in <span class="nj-replace">${EditableInserts.TOPIC}</span>?`,
        '<strong>/research</strong> What are some healthy dinner options?',
      ],
    }, {
      title: 'People',
      tiles: [
        `<strong>/research</strong> Can you research about <span class="nj-replace">${EditableInserts.NAME}</span>?`,
        `<strong>/research</strong> How did <span class="nj-replace">${EditableInserts.NAME}</span>'s early life influence his career as an inventor?`,
        `<strong>/research</strong> What are the most important lessons that <span class="nj-replace">${EditableInserts.NAME}</span> taught us?`,
      ],
    }, {
      title: 'Code',
      tiles: [
        `<strong>/research</strong> How do I <span class="nj-replace">${EditableInserts.PROBLEM_STATEMENT}</span> in Python?`,
        `<strong>/research</strong> Write me a code for <span class="nj-replace">${EditableInserts.PROBLEM_STATEMENT}</span> in Python`,
        `<strong>/research</strong> What are the best practices for writing efficient code?`,
      ],
    }]
  }, {
    title: '/reservation',
    icon: CalendarCheck,
    tiles: [{
      title: 'Reservation',
      tiles: [
        `<strong>/reservation</strong> Can you make reservation at <span class="nj-replace">${EditableInserts.BUSINESS_NAME}</span> restaurant in Palo Alto for <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>?`,
        `<strong>/reservation</strong> Can you get me an appointment with my hair salon for Friday between <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>? The phone number to reach them is <span class="nj-replace">${EditableInserts.PHONE}</span>`,
      ]
    }]
  }, {
    title: '/status',
    icon: Lightbulb,
    tiles: [{
      title: 'Status',
      tiles: [
        `<strong>/status</strong> What's the status of #<span class="nj-replace">${EditableInserts.ACTION_ITEM}</span>"?`,
        `<strong>/status</strong> What's the status on my request to schedule time to discuss <span class="nj-replace">${EditableInserts.TOPIC}</span>?`,
      ],
    }, {
      title: 'Insights',
      tiles: [
        `<strong>/status</strong> How many hours have you saved for me this week?`,
      ]
    }]
  }, {
    title: '/fun',
    icon: CirclesThreePlus,
    tiles: [{
      title: 'Play',
      tiles: [
        `<strong>/fun</strong> Let's play "Would you rather..."`,
        `<strong>/fun</strong> Let's play twenty questions`,
      ],
    }, {
      title: 'Jokes',
      tiles: [
        `<strong>/fun</strong> Tell me a joke based on above chats`,
      ]
    }]
  },
];

type ExamplesProps = {
  topics?: ExampleType[];
  onTileClick: (value: string) => void;
  onClose: () => void;
};

export const Examples = ({ topics = EXAMPLES_DATA, onTileClick, onClose }: ExamplesProps) => {
  const tabs = topics.map((example) => example.title);
  const [selectedTab, setSelectedTab] = useTabs(tabs);

  const handleTileClick = (value: string) => {
    onTileClick(value);
    onClose();
  };

  return (
    <div className="nj-examples">
      <header>
        <h2>Use a skill example</h2>
        <p>Here are some message templates you can use to create action item to Atlas.</p>
      </header>
      <div className="nj-tabbed-layout">
        <nav className="nj-tabbed-layout--tabs">
          {topics.map((topic, index) => {
            const TabIcon: Icon = topic.icon;
            return (
              <div
                className={classNames("nj-tabbed-layout--tab", { selected: selectedTab === topic.title })}
                onClick={() => setSelectedTab(topic.title)}
                key={`exampletab-${getTabID(topic.title)}-${index}`}
              >
              <TabIcon size={20} />
              {topic.title}
            </div>
            );
          })}
        </nav>
        <div className="nj-tabbed-layout--panels">
          {topics.map((topic, index) =>
            <TabPanel
              hidden={selectedTab !== topic.title}
              key={`examplepanel-${getTabID(topic.title)}-${index}`}>
              <Example
                tiles={topic.tiles}
                onClick={handleTileClick}
              />
            </TabPanel>
          )}
        </div>
      </div>
    </div>
  );
};
