import { ChatMode } from 'src/types';
import classNames from 'classnames';
import { useSession } from 'src/hooks';
import { ConversationChat } from '../ConversationChat';
import { ConversationHeader } from '../ConversationHeader';

/**
 * Composes conversation panel with header, footer and AI scenario.
 */
export const Conversation = () => {
  const { appUser, chatMode, selectedTaskId } = useSession();

  const classes = classNames('nj-converse-ai', {
    'has-messages': chatMode === ChatMode.CHAT || chatMode === ChatMode.HASHTAG,
  });

  // todo(ella): fix toggling of the audio in the new header
  return (
    <div className={classes}>
      <ConversationHeader />

      <ConversationChat user={appUser} taskId={selectedTaskId || 'default'} />
    </div>
  );
};
