/**
 * getCaretPosition() determines position of the caret inside content editable.
 * @param contentEditable HTMLDivElement
 * @returns number
 */
export function getCaretPosition(contentEditable: HTMLDivElement): number {
  const selection = window.getSelection();
  if (selection?.rangeCount) {
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(contentEditable);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }
  return 0;
}

/**
 * selectEditableInsert() selects node contents on click inside it as visual aid.
 * @param node Node
 * @returns string
 */
export function selectEditableSpan(node: Node): string {
  if (node && (node as Element).classList.contains('nj-replace')) {
    const range = document.createRange();
    range.selectNodeContents(node);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    return node.firstChild?.textContent || '';
  }

  return '';
}
