import { messagesApi } from 'src/store';
import { ApiMessage, AppUser } from 'src/types';

export const useMessages = (user: AppUser, agent: AppUser) => {
  const [clearMessagesFromThread] = messagesApi.useClearMessagesFromThreadMutation();
  const [addMessageToThread] = messagesApi.useAddMessageToThreadMutation();
  const [updateThreadMessage] = messagesApi.useUpdateThreadMessageMutation();
  const [patchThreadMessage] = messagesApi.usePatchThreadMessageMutation();

  const {
    data,
    isFetching: isFetchingMessages,
    isLoading: isLoadingUserMessages,
    error: messagesError,
    refetch: getUserMessages,
  } = messagesApi.useGetMessagesQuery(user.user_id, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const sortData = (unsorted?: Array<ApiMessage>) => {
    if (!unsorted) {
      return [];
    }
    return [...unsorted].sort(
      (a: ApiMessage, b: ApiMessage) =>
        new Date(a.timestamp || new Date()).getTime() -
        new Date(b.timestamp || new Date()).getTime(),
    );
  };

  const clearMessages = async () => {
    await clearMessagesFromThread(user.user_id);
  };

  const addMessage = async (message: ApiMessage) => {
    await addMessageToThread({ userId: user.user_id, message: message });
  };

  const updateMessage = async (message: ApiMessage) => {
    await updateThreadMessage({ userId: user.user_id, message: message });
  };

  const patchMessage = async (message: Partial<ApiMessage>) => {
    await patchThreadMessage({ userId: user.user_id, message: message }).unwrap();
  };

  return {
    userMessages: sortData(data?.data),
    isFetchingMessages,
    isLoadingUserMessages,
    messagesError,
    getUserMessages,
    clearMessages,
    addMessage,
    updateMessage,
    patchMessage,
  };
};
