import { createSlice } from '@reduxjs/toolkit';
import { AppUser, ConversationRole } from 'src/types';
import { fetchUserByEmail, fetchDefaultAgent, fetchAgents } from '../thunks';
import { getCircleColor } from 'src/utils';
import { RootState } from '../index';

/**
 * Loads contacts of a user into the app store.
 * Attn: Remove colors after preferences are done.
 * TODO(ella): Clean up this slice. We have too many duplicate ideas.
 * 1. Move saved user agent into session slice as current agent.
 * 2. Move all agents into agents slice or agents service - do not mix with contacts.
 */
export const usersSlice = createSlice({
  name: 'users',
  initialState: [] as Array<AppUser>,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appUser = {
          ...action.payload,
          color: getCircleColor(),
          avatar: (avatar !== '' ? `avatar${avatar}` : ''),
          role: ConversationRole.USER,
        }
        // avoid dupes
        if (state.findIndex((e) => e.user_id === appUser.user_id) === -1) {
          state.push(appUser);
        }
      })
      .addCase(fetchDefaultAgent.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appAgent = {
          ...action.payload,
          color: getCircleColor(),
          avatar: (avatar !== '' ? `avatar${avatar}` : ''),
          role: ConversationRole.AGENT,
      }
        // avoid dupes
        if (state.findIndex((e) => e.user_id === appAgent.user_id) === -1) {
          state.push(appAgent);
        }
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        const fetchedUsers = action.payload.map((fetchedUser: AppUser) => {
          const firstName = fetchedUser.first_name;
          return {
            ...fetchedUser,
            color: getCircleColor(),
            avatar: `avatar${firstName}`,
            role: ConversationRole.AGENT,
          };
        });

        return [...fetchedUsers];
      });
  },
});

export const usersState = (state: RootState) => state.users as Array<AppUser>;

export default usersSlice.reducer;
