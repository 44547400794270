import { useMemo } from 'react';
import {
  setAvatarAudioLocale,
  setAvatarCCLocale,
  setChatAudioLocale,
  setSelectedTaskId,
  setChatMode,
  useAppDispatch,
  useAppSelector,
  sessionState,
} from 'src/store';
import { ChatMode } from 'src/types';

export const useSession = () => {
  const {
    selectedTaskId,
    avatarAudioLocale,
    avatarCCLocale,
    chatAudioLocale,
    agent,
    chatMode,
    appUser,
  } = useAppSelector(sessionState);

  const dispatch = useAppDispatch();

  const updateSelectedTaskId = (id: string) => {
    dispatch(setSelectedTaskId(id));
  };

  const updateAvatarAudioLocale = (locale: string) => {
    dispatch(setAvatarAudioLocale(locale));
  };

  const updateAvatarCCLocale = (locale: string) => {
    dispatch(setAvatarCCLocale(locale));
  };

  const updateChatAudioLocale = (locale: string) => {
    dispatch(setChatAudioLocale(locale));
  };

  const updateChatMode = (mode: ChatMode) => {
    dispatch(setChatMode(mode));
  };

  const isActionItem = useMemo(
    () => !!selectedTaskId && selectedTaskId !== 'default',
    [selectedTaskId],
  );

  return {
    selectedTaskId,
    updateSelectedTaskId,
    avatarAudioLocale,
    avatarCCLocale,
    updateAvatarAudioLocale,
    updateAvatarCCLocale,
    chatAudioLocale,
    updateChatAudioLocale,
    agent,
    chatMode,
    updateChatMode,
    appUser,
    isActionItem,
  };
};
