import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { isApiUserList, isApiUser } from 'src/types';
import { E_ASSISTANT } from "src/constants";

const API_V2_URL_USERS = `${process.env.REACT_APP_NINJA_API_V2_URL}/users`;

const API_URL_USERS = `${process.env.REACT_APP_NINJA_API_URL}/users`;

// Get a user by email
export const fetchUserByEmail = createAsyncThunk(
  'users/fetchByEmail',
  async (userEmail: string) => {
    const request = new Request(`${API_V2_URL_USERS}?email=${userEmail}`, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json_data = await response.json();
        if (json_data && json_data['data']) {
          const user = json_data['data'][0];
          return user;
        }
      } else {
        throw new Error(`GET users?email=${userEmail} failed to fetch response from the server.`);
      }
    } catch (e: unknown) {
      return isRejectedWithValue(`Cannot fetch user by email from API.`);
    }
  },
);

// Get the default agent for the environment
// TODO: this is basically the same code to fetchUserByEmail, should move it to a
// shared func
export const fetchDefaultAgent = createAsyncThunk('users/defaultAgent', async () => {
  const request = new Request(`${API_V2_URL_USERS}/${E_ASSISTANT}`, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
  });

  try {
    const response = await fetch(request);
    if (response.ok) {
      const json_data = await response.json();
      if (json_data && json_data['data']) {
        const user = json_data['data'][0];
        return user;
      }
    } else {
      throw new Error(`GET agent/default failed to fetch response from the server.`);
    }
  } catch (e: unknown) {
    return isRejectedWithValue(`Cannot fetch user by email from API.`);
  }
});

// GET /api/v1/users?user_type=agent
export const fetchAgents = createAsyncThunk('users/fetch', async () => {
  const request = new Request(`${API_V2_URL_USERS}?user_type=agent`, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
  });

  try {
    const response = await fetch(request);
    if (response.ok) {
      const json = await response.json();
      if (isApiUserList(json.data)) {
        return json.data;
      } else {
        throw new Error(`GET /api/v1/users returned data not of the right type.`);
      }
    } else {
      throw new Error(`GET /api/v1/users failed to fetch response from the server.`);
    }
  } catch (e: unknown) {
    // console.log(e.response.data.message);
    return isRejectedWithValue(`Failed to fetch a list of users from API.`);
  }
});

// GET /api/v1/users
export const fetchUsers = createAsyncThunk('users/fetch', async () => {
  const request = new Request(API_URL_USERS, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
  });

  try {
    const response = await fetch(request);

    if (response.ok) {
      const json = await response.json();
      if (isApiUserList(json.data)) {
        return json.data;
      } else {
        throw new Error(`GET /api/v1/users returned data not of the right type.`);
      }
    } else {
      throw new Error(`GET /api/v1/users failed to fetch response from the server.`);
    }
  } catch (e: unknown) {
    // console.log(e.response.data.message);
    return isRejectedWithValue(`Failed to fetch a list of users from API.`);
  }
});

// GET /api/v1/users/{id}
export const fetchUserById = createAsyncThunk('users/fetchById', async (userId: string) => {
  const request = new Request(`${API_URL_USERS}/${userId}`, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
  });

  try {
    const response = await fetch(request);

    if (response.ok) {
      const json = await response.json();
      if (isApiUser(json.data)) {
        return json.data;
      } else {
        throw new Error(
          `GET /api/v1/users/${userId} returned user data not of the right type.`
        );
      }
    } else {
      throw new Error(`GET /api/v1/users/${userId} failed to fetch response from the server.`);
    }
  } catch (e: unknown) {
    // console.log(e.response.data.message);
    return isRejectedWithValue(`Cannot fetch user by id from API.`);
  }
});
