import { CheckCircle } from '@phosphor-icons/react';

type SystemMessageProps = {
  tag?: string;
};

/**
 * Creates system message that allows for extra interactivity added to the UI.
 */
export const SystemMessage = ({ tag = '' }: SystemMessageProps) => {
  let content;
  switch (tag) {
    case 'add-task':
      content = (
        <>
          <span data-testid="add-task" className="nj-task-created">
            <CheckCircle size={16} color="currentColor" /> Task created
          </span>
        </>
      );
      break;
    default:
      content = <></>;
      break;
  }

  return (
    <div data-testid="system-message" className="nj-bubble-footer">
      {content}
    </div>
  );
};
