import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

/**
 * Creates a thread of agent-user conversation.
 */
export const audioModeSlice = createSlice({
  name: 'audioMode',
  initialState,
  reducers: {
    setAudioMode: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setAudioMode } = audioModeSlice.actions;

export default audioModeSlice.reducer;
