import { useMemo } from 'react';
import { ApiTaskSelectable } from 'src/types';
import { getTaskDisplayDate } from 'src/utils';
import { Task } from 'src/pages/ManageTasksChatPage/components/Task';

interface TasksProps {
  date: string;
  tasks: ApiTaskSelectable[];
  selectedTaskId?: string;
  onSelectTask: (id: string) => void;
}

/**
 * Tasks component displays a list of tasks with title, description and status update.
 */
export const Tasks = ({ date, tasks, selectedTaskId, onSelectTask }: TasksProps) => {
  const title = useMemo(() => getTaskDisplayDate(date), [date]);

  return (
    <div className="nj-tasks">
      <p className="nj-tasks--date">{title}</p>
      {tasks.map((item) => (
        <Task
          key={item.task_id}
          onClick={onSelectTask}
          selected={item.task_id === selectedTaskId}
          task={item}
        />
      ))}
    </div>
  );
};
