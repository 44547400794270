import { Calendar, Chats, ChatsCircle, CalendarCheck, Lightbulb, CirclesThreePlus } from '@phosphor-icons/react';
import { Pillar, PillarTopic } from './Pillar';
import { PillarsTitle } from './PillarsTitle';
import { AppUser, EditableInserts } from 'src/types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const DEFAULT_TOPICS: Array<PillarTopic> = [{
  icon: Calendar,
  title: '/calendar',
  description: 'Conversationally manage your calendar, ask questions, and manage meetings',
  tiles: [
    `/calendar Schedule a meeting with @john and mary@gmail.com for this Thursday afternoon`,
    `/calendar How can you help me make my calendar more efficient?`,
    `/calendar What does my day look like?`,
  ],
  templates: [
    `/calendar Schedule a meeting with @<span class="nj-replace">${EditableInserts.CONTACT}</span> and <span class="nj-replace">${EditableInserts.CONTACT_EMAIL}</span> for <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>`,
    `/calendar How can you help me make my calendar more efficient?`,
    `/calendar How does my day look like?`,
  ],
  classname: 'skills-examples-icons',
}, {
  icon: Chats,
  title: '/outreach',
  description: 'Get your agent to collect information and check actions on your behalf',
  tiles: [
    `/outreach Check with bobamango@gmail.com if they're open on Sunday and what hours?`,
    `/outreach Contact rinse@gmail.com and ask if my dry cleaning can be delivered in two days?`,
    `/outreach Check with crepesforsoul@gmail.com if they have gluten free crepes`,
  ],
  templates: [
    `/outreach Check with <span class="nj-replace">${EditableInserts.CONTACT_EMAIL}</span> if they are <span class="nj-replace">${EditableInserts.DETAILS}</span>?`,
    `/outreach Contact <span class="nj-replace">${EditableInserts.CONTACT_EMAIL}</span> and ask when <span class="nj-replace">${EditableInserts.PREFERRED_ACTION}</span> by <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>`,
    `/outreach Check with <span class="nj-replace">${EditableInserts.CONTACT_EMAIL}</span> if they have <span class="nj-replace">${EditableInserts.DETAILS}</span>`,
  ],
  classname: 'new-icon',
}, {
  icon: CalendarCheck,
  title: '/reservation',
  description: 'Seamless appointment booking and reservations on your behalf',
  tiles: [
    `/reservation Can you make reservation at Quatro restaurant for Saturday dinner?`,
    `/reservation Can you get me an appointment with my hair salon for Friday at 10am? The phone number to reach them is 123-456-7890`,
  ],
  templates: [
    `/reservation Can you make reservation at <span class="nj-replace">${EditableInserts.BUSINESS_NAME}</span> restaurant for <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>?`,
    `/reservation Can you get me an appointment with my hair salon for Friday at <span class="nj-replace">${EditableInserts.PREFERRED_TIME}</span>? The phone number to reach them is <span class="nj-replace">${EditableInserts.PHONE}</span>`,
  ],
}, {
  icon: Lightbulb,
  title: '/research',
  description: 'Let Atlas be your research sidekick; search across multiple sources',
  tiles: [
    `/research What are the current trends in AI research and development?`,
    `/research Write me a python code that calculates prime numbers upto six digits`,
    `/research How did Thomas Edison's early life influence his career as an inventor?`
  ],
  templates: [
    `/research What are the current trends in <span class="nj-replace">${EditableInserts.TOPIC}</span>?`,
    `/research Write me a python code to <span class="nj-replace">${EditableInserts.TOPIC}</span>`,
    `/research How did <span class="nj-replace">${EditableInserts.NAME}</span>'s early life influence his career as an inventor?`,
  ],
}, {
  icon: ChatsCircle,
  title: '/status',
  description: 'Status of the Action item',
  tiles: [
    `/status What's the status of #schedule-meeting-with-Jack-H?`,
    `/status How many hours have you saved for me this week?`,
    `/status How many hours have you saved for my team this week?`,
  ],
  templates: [
    `/status What's the status of #<span class="nj-replace">${EditableInserts.ACTION_ITEM}</span>?`,
    `/status How many hours have you saved for me this week?`,
    `/status How many hours have you saved my team this week?`,
  ],
}, {
  icon: CirclesThreePlus,
  title: '/fun',
  description: 'Dive into games & engage in fun conversations with Atlas',
  tiles: [
    `/fun Let's play "Would you rather..."`,
    `/fun Let's play twenty questions`,
    `/fun Tell me a joke based on above chats`,
  ],
  templates: [
    `/fun Let's play "Would you rather..."`,
    `/fun Let's play twenty questions`,
    `/fun Tell me a joke based on above chats`,
  ],
}
];

const RESPONSIVE = {
  superLargeDesktop: {
    breakpoint: { max: 7680, min: 3000 },
    items: 3,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2.5,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 320 },
    items: 1,
    slidesToSlide: 1,
  }
};

type PillarsCarouselProps = {
  shouldShowToday: boolean;
  user: AppUser;
  topics?: Array<PillarTopic>;
  onClick?: (param: string) => void;
};

export const PillarsCarousel = ({ shouldShowToday = false, user, topics = DEFAULT_TOPICS, onClick }: PillarsCarouselProps) => {
  return (
    <div className="nj-pillars">
      <PillarsTitle name={user.first_name} shouldShowToday={shouldShowToday} />
      <div className="nj-pillars--container">
        <Carousel
          responsive={RESPONSIVE}
          swipeable={true}
          draggable={true}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
          transitionDuration={300}
          showDots={true}
          renderArrowsWhenDisabled={true}
          containerClass="nj-pillars--carousel-container"
          dotListClass="nj-pillars--carousel-dots"
        >
          {topics.map((topic: PillarTopic, index: number) =>
            <Pillar
              key={`topic_${index}`}
              topic={topic}
              onClick={onClick}
            />
          )}
        </Carousel>
      </div>
    </div>
  );
};
