export const supportedLanguages = [
  {
    code: 'en-US',
    displayText: 'English (United States)',
  },
  {
    code: 'ar-SA',
    displayText: 'Arabic (Saudi Arabia)',
  },
  {
    code: 'az-AZ',
    displayText: 'Azerbaijani (Latin, Azerbaijan)',
  },
  {
    code: 'hy-AM',
    displayText: 'Armenian (Armenia)',
  },
  {
    code: 'ca-ES',
    displayText: 'Catalan (Spain)',
  },
  {
    code: 'yue-CN',
    displayText: 'Chinese (Cantonese, Simplified)',
  },
  {
    code: 'zh-CN',
    displayText: 'Chinese (Mandarin, Simplified)',
  },
  {
    code: 'hr-HR',
    displayText: 'Croatian (Croatia)',
  },
  {
    code: 'cs-CZ',
    displayText: 'Czech (Czechia)',
  },
  {
    code: 'da-DK',
    displayText: 'Danish (Denmark)',
  },
  {
    code: 'nl-NL',
    displayText: 'Dutch (Netherlands)',
  },
  {
    code: 'fi-FI',
    displayText: 'Finnish (Finland)',
  },
  {
    code: 'fil-PH',
    displayText: 'Filipino (Philippines)',
  },
  {
    code: 'fr-FR',
    displayText: 'French (France)',
  },
  {
    code: 'de-DE',
    displayText: 'German (Germany)',
  },
  {
    code: 'el-GR',
    displayText: 'Greek (Greece)',
  },
  {
    code: 'he-IL',
    displayText: 'Hebrew (Israel)',
  },
  {
    code: 'hi-IN',
    displayText: 'Hindi (India)',
  },
  {
    code: 'it-IT',
    displayText: 'Italian (Italy)',
  },
  {
    code: 'ja-JP',
    displayText: 'Japanese (Japan)',
  },
  {
    code: 'ko-KR',
    displayText: 'Korean (Korea)',
  },
  {
    code: 'fa-IR',
    displayText: 'Persian (Iran)',
  },
  {
    code: 'pl-PL',
    displayText: 'Polish (Poland)',
  },
  {
    code: 'pt-PT',
    displayText: 'Portuguese (Portugal)',
  },
  {
    code: 'ru-RU',
    displayText: 'Russian (Russia)',
  },
  {
    code: 'es-MX',
    displayText: 'Spanish (Mexico)',
  },
  {
    code: 'es-ES',
    displayText: 'Spanish (Spain)',
  },
  {
    code: 'sv-SE',
    displayText: 'Swedish (Sweden)',
  },
  {
    code: 'tr-TR',
    displayText: 'Turkish (Turkey)',
  },
  {
    code: 'uk-UA',
    displayText: 'Ukrainian (Ukraine)',
  },
  {
    code: 'uz-UZ',
    displayText: 'Uzbek (Latin, Uzbekistan)',
  },
  {
    code: 'vi-VN',
    displayText: 'Vietnamese (Vietnam)',
  },
];
