import { setupServer } from 'msw/node';
import { usersApiMock } from './usersApiMock';
import { tasksApiMock } from './tasksApiMock';
import { feedbackApiMock } from './feedbackApiMock';
import { messagesApiMock } from './messagesApiMock';
import { contactsApiMock } from './contactsApiMock';

const handlers = usersApiMock.concat(tasksApiMock, feedbackApiMock, messagesApiMock, contactsApiMock);

export const mockServer = setupServer(...handlers);
