import { useState, createContext } from 'react';

export type ThemeContextType = {
  isDarkTheme: boolean;
  toggleTheme?: () => void;
  isTasksListExpanded: boolean;
  setIsTasksListExpanded: (value: boolean) => void;
  toggleTasksListExpanded: () => void;
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

/**
 * Theme Context manages light, dark theme mode.
 */
const ThemeContext = createContext<ThemeContextType>({
  isDarkTheme: true,
  toggleTheme: () => undefined,
  isTasksListExpanded: true,
  setIsTasksListExpanded: () => undefined,
  toggleTasksListExpanded: () => undefined,
});

/**
 * Theme Provider helps to toggle light or dark theme modes.
 */
const ThemeContextProvider = ({ children }: ThemeProviderProps) => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [isTasksListExpanded, setIsTasksListExpanded] = useState<boolean>(true);

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const toggleTasksListExpanded = () => {
    setIsTasksListExpanded((state) => !state);
  };

  return (
    <ThemeContext.Provider
      value={{ isDarkTheme, toggleTheme, isTasksListExpanded, setIsTasksListExpanded, toggleTasksListExpanded }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContextProvider };
export default ThemeContext;
