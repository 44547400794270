import classNames  from 'classnames';
import parse from 'html-react-parser';

type HelperItemProps = {
  prefix?: string;
  selection?: string;
  text: string;
  selected?: boolean;
  onItemClick?: (value: string) => void;
  onKeyDown?: (value: string) => void;
};

export const HelperItem = ({
  prefix = '',
  selection = '',
  text,
  selected = false,
  onItemClick,
}: HelperItemProps) => {
  const handleHelperItemClick = (value: string) => {
    if (onItemClick) {
      onItemClick(value);
    }
  };

  const handleHelperItemEnter = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleHelperItemClick(value);
    }
  };

  const accentuate = (selection: string) => {
    const bolded = [];
    for (const [index, letter] of selection.split('').entries()) {
      if (letter === text[index]) {
        bolded.push(letter);
      }
    }
    return `<b>${bolded.join('')}</b>${text.slice(bolded.length)}`;
  };

  return (
    <div
      className={classNames('nj-helper--item', { selected })}
      onClick={(e) => handleHelperItemClick(text)}
      onKeyDown={(e) => handleHelperItemEnter(e, text)}
    >
      {prefix}{parse(accentuate(text))}
    </div>
  );
};
