import { Bell, Gear } from '@phosphor-icons/react';
import { MainNavToggleButton } from '../../pages/ManageTasksChatPage/components/ConversationHeader/components/MainNavToggleButton';
import { AppUser } from 'src/types';
import { useAvatarPreferences } from 'src/hooks';
import { Button } from '../Button';
import { useSettings } from 'src/hooks/useSettings';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
// import Atlas from 'src/images/icons/Atlas.png';

type NavProps = {
  user: AppUser;
};

const SVG_SIZE = 24;

export const Nav = ({ user }: NavProps) => {
  const { avatarImageURL } = useAvatarPreferences(user.user_id);
  const { changeIsOpenSettingsSectionState } = useSettings();

  const handleOpenSettingsSection = () => {
    changeIsOpenSettingsSectionState(true, SettingsTabs.GENERAL);
  };

  return (
    <div className="nj-app-nav--menu">
      <div className="nj-app-nav--menu-container">
        <Button className="nj-avatar--square">
          <img src={avatarImageURL} alt="Atlas" />
        </Button>
        <Button className="nj-app-nav--menu-button">
          <Bell size={SVG_SIZE} />
          <span className="reddot"></span>
        </Button>
        <Button className="nj-app-nav--menu-button" onClick={handleOpenSettingsSection}>
          <Gear size={SVG_SIZE} />
        </Button>
      </div>
      <MainNavToggleButton reversed />
    </div>
  );
};
