import { useEffect, useMemo, useState } from 'react';
import { DashboardEACard, DashboardReleaseList } from 'src/pages/DashboardPage/components';
import { sessionState, setOpenAvatarByDefault, useAppDispatch, useAppSelector } from 'src/store';
import { getAvatarURL } from 'src/utils';
import { AppUser } from 'src/types';

interface DashboardProps {
  user: AppUser;
}

export const DashboardPage = ({ user }: DashboardProps) => {
  const dispatch = useAppDispatch();
  const { shouldOpenAvatarByDefault } = useAppSelector(sessionState);

  const [shouldHidePage] = useState<boolean>(shouldOpenAvatarByDefault);

  const userName = useMemo(() => user.first_name, [user]);

  useEffect(() => {
    if (shouldOpenAvatarByDefault) {
      dispatch(setOpenAvatarByDefault(false));

      const url = getAvatarURL(user.user_id);

      window.location.href = url || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenAvatarByDefault, dispatch]);

  if (shouldHidePage) {
    return null;
  }

  return (
    <div className="nj-dashboard">
      <DashboardEACard userName={userName} />
      <DashboardReleaseList />
    </div>
  );
};
