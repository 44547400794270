import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const baseUrl = process.env.REACT_APP_NINJA_API_URL;
export const feedbackBaseUrl = process.env.REACT_APP_NINJA_FEEDBACK_API_URL;
export const v2BaseUrl = process.env.REACT_APP_NINJA_API_V2_URL;

export const baseQuery = fetchBaseQuery({
  baseUrl,
});

export const baseFeedbackQuery = fetchBaseQuery({
  baseUrl: feedbackBaseUrl,
});

export const baseV2Query = fetchBaseQuery({
  baseUrl: v2BaseUrl,
});

export const baseUsersV2Query = fetchBaseQuery({
  baseUrl: `${v2BaseUrl}/users`,
  credentials: 'same-origin',
});
