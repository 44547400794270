import { MagnifyingGlass } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';

const SVG_SIZE = 20;

export const SettingsHeader = () => {
  return (
    <div className="nj-settings--header">
      <p className="nj-settings--header-title">Settings</p>
      <form className="nj-settings--header-search">
        <Button aria-label="Search" className="nj-settings--header-search-button">
          <MagnifyingGlass size={SVG_SIZE} color="currentColor" />
        </Button>
        <input className="nj-settings--header-search-input" placeholder="Search" />
      </form>
    </div>
  );
};
