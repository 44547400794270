import { createApi } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'reduxjs-toolkit-persist';
import { ApiMessage } from '../../types';
import { baseUsersV2Query } from './config';

export interface AddMessageThreadRequest {
  userId: string;
  message: ApiMessage;
}

enum MessagesTags {
  Messages = 'Messages',
}

export const messagesApi = createApi({
  reducerPath: 'messagesApi',
  tagTypes: [MessagesTags.Messages],
  baseQuery: baseUsersV2Query,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getMessages: builder.query<{ data: ApiMessage[] }, string>({
      query: (userId) => ({
        url: `/${userId}/messages`,
      }),
      providesTags: [MessagesTags.Messages],
      keepUnusedDataFor: 24 * 60 * 1000,
    }),
    clearMessagesFromThread: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/${userId}/messages`,
        method: `DELETE`,
      }),
      invalidatesTags: [MessagesTags.Messages],
    }),
    addMessageToThread: builder.mutation<void, AddMessageThreadRequest>({
      query: ({ userId, message }) => ({
        url: `/${userId}/messages`,
        method: `POST`,
        body: message,
      }),
      invalidatesTags: [MessagesTags.Messages],
    }),
    updateThreadMessage: builder.mutation<void, AddMessageThreadRequest>({
      query: ({ userId, message }) => ({
        url: `/${userId}/messages/${message.message_id}`,
        body: message,
        method: `PUT`,
      }),
      invalidatesTags: [MessagesTags.Messages],
    }),
    patchThreadMessage: builder.mutation<void, { userId: string; message: Partial<ApiMessage> }>({
      query: ({ userId, message }) => ({
        url: `/${userId}/messages/${message.message_id}`,
        body: message,
        method: `PATCH`,
      }),
      invalidatesTags: [MessagesTags.Messages],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useAddMessageToThreadMutation,
  useClearMessagesFromThreadMutation,
  useUpdateThreadMessageMutation,
  usePatchThreadMessageMutation,
} = messagesApi;
