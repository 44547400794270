import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { useSession } from 'src/hooks/useSession';

interface DashboardEACardProps {
  userName: string;
}

/**
 * DashboardEACard displays an invitation block with Atlas on the Dashboard page.
 */
export const DashboardEACard = ({ userName }: DashboardEACardProps) => {
  // Todo(ella): add "agent" from session & replace `Atlas` with agent.first_name
  // at this time test is failing when the change occurs.
  const { updateSelectedTaskId } = useSession();

  const currentDate = useMemo(() => dayjs().format('dddd, MMM DD'), []);

  const { isDesktop } = useBreakpoint();

  return (
    <div className="nj-dashboard--ea-card">
      <Icon type="avatarAtlas" size={isDesktop ? 128 : 80} />
      <div className="content-wrapper">
        <p className="current-date">{currentDate}</p>
        <p className="title">Hi, {userName}!</p>
        <Link className="link" to="/manage-tasks" onClick={() => updateSelectedTaskId('default')}>
          Chat with Atlas
        </Link>
      </div>
    </div>
  );
};
