import AppleLogo from 'src/images/logos/apple-logo.png';
import MicrosoftLogo from 'src/images/logos/microsoft-logo.png';
import { sessionState, setOpenAvatarByDefault, useAppDispatch, useAppSelector } from 'src/store';

export const SSOLoginFooter = () => {
  const dispatch = useAppDispatch();
  const { shouldOpenAvatarByDefault } = useAppSelector(sessionState);

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatch(setOpenAvatarByDefault(target.checked));
  };

  return (
    <div>
      <button className="cognito--sign-in-button">
        <span>
          <img className="cognito--sign-in-button-logo" src={MicrosoftLogo} alt="microsoft" />
        </span>
        <span>Sign in with Microsoft</span>
      </button>
      <button className="cognito--sign-in-button">
        <span>
          <img className="cognito--sign-in-button-logo" src={AppleLogo} alt="apple" />
        </span>
        <span>Sign in with Apple</span>
      </button>
      <div className="cognito--sign-in-checkbox">
        <input
          id="chatWithAtlas"
          type="checkbox"
          checked={shouldOpenAvatarByDefault}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="chatWithAtlas">Video chat with Atlas</label>
      </div>
      <div className="cognito--sign-in-link">
        <span>Don’t have an account?</span>
        <a href="https://www.ninjatech.ai/">Waitlist sign up</a>
      </div>
    </div>
  );
};
