import classNames from 'classnames';
import { FieldValues, UseFormRegister, UseFormWatch, FieldErrors, Path } from 'react-hook-form';

const ratingOptions: {
  field: string;
  title: string;
  description: string;
}[] = [
  {
    field: 'understanding',
    title: 'Understanding',
    description:
      'How well did the model comprehend the input? Was it able to grasp the context and meaning of the questions or prompts?',
  },
  {
    field: 'helpfulness',
    title: 'Helpfulness',
    description:
      'How effectively/helpful were the model responses? Did it provide relevant and helpful information?',
  },
  {
    field: 'coherence',
    title: 'Coherence',
    description:
      "Did the model's responses flow logically and coherently? Were there any inconsistencies or abrupt transitions in the conversation?",
  },
  {
    field: 'completeness',
    title: 'Completeness',
    description:
      "Did the model provide thorough and comprehensive answers? Did it cover all aspects of the user's question or prompt?",
  },
  {
    field: 'accuracy',
    title: 'Accuracy',
    description:
      "How accurate were the model's responses? Did it provide correct and factual information? Were there any factual errors or misleading?",
  },
  {
    field: 'language_quality',
    title: 'Language Quality',
    description:
      'How well did the model use language? Did it produce grammatically correct and well-formed sentences? Were the responses fluent and natural-sounding?',
  },
  {
    field: 'relevance',
    title: 'Relevance',
    description:
      "Did the model's responses stay on topic and remain relevant to the conversation? Did it provide information or engage in discussions that were appropriate and related to the user's queries?",
  },
  {
    field: 'user_experience',
    title: 'User Experience',
    description:
      'How satisfying was the overall user experience during the interaction? Did the model engage in a pleasant and interactive manner? Was it easy to understand and communicate with the model?',
  },
  {
    field: 'style',
    title: 'Style',
    description: 'How was the response tone? Good, bad',
  },
  {
    field: 'error_handling',
    title: 'Error Handling',
    description:
      "How well did the model handle errors or misunderstandings? Did it respond appropriately when it didn't understand a question or when there was ambiguity in the input?",
  },
];

interface FeedbackRatingListProp<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
}

export const FeedbackRatingList = <T extends FieldValues>({
  register,
  errors,
  watch,
}: FeedbackRatingListProp<T>) => {
  const watchFields = watch();

  return (
    <div className="field">
      <p className={classNames('subtitle', { isRequired: errors['rating_list'] })}>
        Rate the response <span>(At least one rating)</span>
      </p>
      <p className="note">Lowest rating = 1</p>
      <p className="note">Highest ratting = 10</p>
      <div className="rating-list">
        {ratingOptions.map(({ field, title, description }) => (
          <div key={field}>
            <legend className={classNames('rating-subtitle', { isRequired: errors[field] })}>
              {title}
            </legend>
            <span className="rating-description">{description}</span>
            <div className="rating-score">
              {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
                <span key={value}>
                  <input
                    className="rating-radio"
                    type="radio"
                    id={`${field}-${value}`}
                    value={value}
                    {...register(field as Path<T>)}
                  />
                  <label
                    className={classNames('rating-radio-label', {
                      active: watchFields[field] === value.toString(),
                    })}
                    htmlFor={`${field}-${value}`}
                  >
                    {value}
                  </label>
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
