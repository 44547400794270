import packageJson from '../../package.json';
import { AppUser, ConversationRole, UserType } from 'src/types';

export const E_ASSISTANT = process.env.REACT_APP_E_ASSISTANT || 'EASSISTANT';

export const DEFAULT_PORTAL = 'manage-tasks';

export const DEFAULT_OPTION = 'default';

export const VERSION_NUMBER = packageJson.version;

export const DEFAULT_AGENT: AppUser = {
  user_id: E_ASSISTANT,
  first_name: 'Atlas',
  last_name: '',
  user_type: UserType.AGENT,
  role: ConversationRole.AGENT,
  email: 'atlas@ninjatech.ai',
  company: 'NinjaTech AI',
  avatar: 'avatarAtlas',
};

export const CONVERSATION_ENGINE_ENDPOINT = `${process.env.REACT_APP_CHATGPT_ENDPOINT}`;
