import { ToggleLeft, ToggleRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { Button } from 'src/components/Button';

const SVG_SIZE = 32;

interface SettingsSwitchProps {
  defaultActive?: boolean;
  onClick?: (isActive: boolean) => void;
  isActive?: boolean;
  name?: string;
}

export const SettingsSwitch = ({
  onClick,
  defaultActive = false,
  name = 'switch',
}: SettingsSwitchProps) => {
  const [active, toggleActive] = useState<boolean>(defaultActive);

  const handleClick = useCallback(() => {
    onClick?.(!active);
    toggleActive((state) => !state);
  }, [active, onClick]);

  return (
    <Button
      aria-label={name}
      className={classNames('nj-settings--form-switch', { active })}
      onClick={handleClick}
    >
      {active ? (
        <ToggleRight
          data-testid="toggle-right"
          role="img"
          size={SVG_SIZE}
          color="currentColor"
          weight="fill"
        />
      ) : (
        <ToggleLeft
          data-testid="toggle-left"
          role="img"
          size={SVG_SIZE}
          color="currentColor"
          weight="fill"
        />
      )}
    </Button>
  );
};
