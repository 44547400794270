import { tasksApi } from 'src/store';
import { ApiTaskSelectable, ApiMessage } from 'src/types';
import { getActionItemHash } from 'src/utils';

export const useTasks = (userId: string) => {
  const {
    data = [],
    isFetching: isFeatchingTasks,
    isLoading: isLoadingTasks,
    error: tasksError,
    refetch: getTasks,
  } = tasksApi.useGetTasksByUserIdQuery(userId, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    pollingInterval: 3000,
  });

  const [updateTask] = tasksApi.useUpdateTaskMutation();
  const [deleteTask] = tasksApi.useDeleteTaskByIdMutation();

  const selectTaskById = (taskId: string) => {
    return data.find((task: ApiTaskSelectable) => task.task_id === taskId);
  };

  const selectCollaborators = (messages: ApiMessage[]): string[] => {
    return [
      ...new Set(
        messages.reduce((total, message) => {
          return [...total, message.from_user_id, message.to_user_id];
        }, [] as string[]),
      ),
    ];
  };

  const selectTaskCollaboratorsById = (taskId: string) => {
    const task = selectTaskById(taskId);
    return selectCollaborators(task?.messages || []);
  };

  const sortTasks = () => {
    const tmp: ApiTaskSelectable[] = Array.isArray(data) ? [...data] : [];
    const sorted = tmp.sort(
      (a: ApiTaskSelectable, b: ApiTaskSelectable) =>
        new Date(b.created_at || new Date()).getTime() -
        new Date(a.created_at || new Date()).getTime(),
    );
    return sorted;
  };

  const getMatchingTasks = (search?: string) => {
    const hashedList: string[] = data.map((task: ApiTaskSelectable) => {
      return `${getActionItemHash(task.task_subject || '')}`;
    });

    if (!search) return hashedList.slice(0, 5);

    const tasksList = hashedList.filter((name: string) =>
      name.toLowerCase().includes(search.toLowerCase())
    ).slice(0, 5);

    return tasksList.length > 0 ? tasksList : hashedList.slice(0, 5);
  };

  return {
    tasks: sortTasks(),
    getTasks,
    tasksQuantity: data?.length || 0,
    isFeatchingTasks,
    isLoadingTasks,
    tasksError,
    selectTaskById,
    selectTaskCollaboratorsById,
    updateTask,
    deleteTask,
    getMatchingTasks,
  };
};
