import { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { ApiMessage, Side, ConversationRole } from 'src/types';
import DebugContext from 'src/contexts/DebugContext';
import { pause, getInitials } from 'src/utils';
import { useSession } from 'src/hooks';
import { Icon } from 'src/components/Icon';
import { SystemMessage } from 'src/pages/ManageTasksChatPage/components/SystemMessage';
import { MessageFooter } from 'src/pages/ManageTasksChatPage/components/MessageFooter';

const SVG_SIZE = 24;

type MessageProps = {
  timeDiff?: string;
  isBasic?: boolean;
  message: ApiMessage;
  side?: string;
  doTyping?: boolean;
  onIsRead?: (messageId: string) => Promise<void>;
  onScrollUp?: (delay: number, instant: boolean) => void;
};

/**
 * Generates a message on the fly with typing on or off
 * based on user side, left or right.
 * Message.is_read
 */
export const Message = ({
  timeDiff = '',
  isBasic = false,
  message,
  doTyping = false,
  side = Side.NONE,
  onScrollUp,
}: MessageProps) => {
  const { appUser } = useSession();

  const { debugMode } = useContext(DebugContext);
  const [text, setText] = useState('');
  const [showTyping, setShowTyping] = useState(doTyping);
  const [showSystemUpdates, setShowSystemUpdates] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const { content, tag, role } = message;

  useEffect(() => {
    const datedContent = `${content}`;

    if (showTyping && tag !== 'waiting') {
      const words = datedContent.split(' ');
      if (wordIndex < words.length) {
        pause(80).then(() => {
          setText(`${text} ${words[wordIndex]}`);
          setWordIndex(wordIndex + 1);
        });
      } else {
        setShowTyping(false);
        setShowSystemUpdates(true);
        if (onScrollUp) {
          onScrollUp(50, false);
        }
      }
    } else {
      setText(datedContent);
      setShowSystemUpdates(true);
      if (onScrollUp) {
        onScrollUp(50, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordIndex, showTyping, text, content, tag]);

  return (
    <div aria-label="message" className={classNames('nj-message', side, role)}>
      {side === Side.RIGHT ? <div data-testid="right-side"></div> : ''}

      <div className="container">
        {!isBasic && message.role !== ConversationRole.USER && (
          <span className="nj-bubble--avatar-icon">
            <Icon type="avatarAtlas" size={SVG_SIZE} />
          </span>
        )}

        <div className="content">
          <div data-testid="bubble-container" className={classNames('nj-bubble', side, role)}>
            {!isBasic && debugMode && (
              <p className="nj-debug bold">
                message-id: {message.message_id || 'undefined'}, tag: {tag}
                {message.role !== ConversationRole.USER && `, elapsed: ${timeDiff || `0ms`}`}
              </p>
            )}

            {message.tag === 'waiting' ? (
              <div data-testid="waiting-dots" className="nj-waiting--triple-dots"></div>
            ) : (
              parse(text.replace(/\n/gi, '<br>'))
            )}
          </div>

          {showSystemUpdates && <SystemMessage tag={tag} />}

          {tag !== 'waiting' && !isBasic && <MessageFooter message={message} />}
        </div>

        {!isBasic && message.role === ConversationRole.USER && (
          <span className="nj-bubble--avatar-icon">
            <Icon
              type={appUser.avatar}
              alt={getInitials(appUser)}
              size={SVG_SIZE}
              masterColor={appUser.color}
            />
          </span>
        )}
      </div>
      {side === Side.LEFT ? <div data-testid="left-side"></div> : ''}
    </div>
  );
};
