import classNames from 'classnames';

type PanelProps = {
  className?: string;
  children?: React.ReactNode;
};

/**
 * Panel component creates a panel to hold other components
 * within the layout of ContentPane.
 */
export const Panel = ({ className, children, ...rest }: PanelProps) => {
  const panelClasses = classNames('nj-panel', className);
  return (
    <div className={panelClasses} {...rest}>
      <div className="container">
        {children}
      </div>
    </div>
  );
};
