/* eslint-disable @typescript-eslint/ban-ts-comment */
import Select from 'react-select';
import { SelectOption } from 'src/pages/SettingsPage/components/SettingsSelect';
import { getCustomStyles } from './selectHelper';
import { DropdownOption } from 'src/components/Dropdown';
import { supportedLanguages } from 'src/pages/ManageTasksChatPage/components/AvatarSettingsSelector/constants';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'src/hooks';
import { AvatarColorPicker } from 'src/pages/ManageTasksChatPage/components/AvatarColorPicker';

interface ExecutiveAssistanceSettingsProps {
  userId: string;
}

const languageDropdownOptions: DropdownOption[] = supportedLanguages.map((language) => ({
  value: language.code,
  label: language.displayText,
  customConfig: {
    hasBorderBottom: language.code === 'en-US',
  },
}));

const audioLanguageDropdownOptions: DropdownOption[] = [
  {
    value: 'Auto-Detect',
    label: 'Auto-Detect',
    customConfig: {
      hasBorderBottom: true,
    },
  },
  ...languageDropdownOptions,
];

export const ExecutiveAssistanceSettings = ({userId}: ExecutiveAssistanceSettingsProps) => {
  const [selectedAvatarAudioLanguageOption, setSelectedAvatarAudioLanguageOption] =
    useState<DropdownOption>();
  const [selectedAvatarLanguageOption, setSelectedAudioLanguageOption] =
    useState<DropdownOption>();
  const { avatarCCLocale, avatarAudioLocale, updateAvatarCCLocale, updateAvatarAudioLocale } =
    useSession();

  useEffect(() => {
    const selectedAudioLanguage = audioLanguageDropdownOptions.find(
      (option) => option.value === avatarAudioLocale,
    );
    const selectedCCLanguage = languageDropdownOptions.find(
      (option) => option.value === avatarCCLocale,
    );
    setSelectedAvatarAudioLanguageOption(selectedAudioLanguage);
    setSelectedAudioLanguageOption(selectedCCLanguage);
  }, [avatarAudioLocale, avatarCCLocale]);

  const handleLanguageSelect = useCallback(
    (option: SelectOption) => {
      updateAvatarCCLocale(option?.value || '');
    },
    [updateAvatarCCLocale],
  );

  const handleAvatarLanguageSelect = useCallback(
    (option: SelectOption) => {
      updateAvatarAudioLocale(option?.value || '');
    },
    [updateAvatarAudioLocale],
  );

  return <div className="nj-executive-assistance-settings">
    <h4 className="nj-executive-assistance-settings-title">Language</h4>
    <div className="nj-executive-assistance-settings--form-field">
      <label className="nj-executive-assistance-settings--form-label">Your language</label>
      <Select
        aria-label="user-language"
        defaultValue={selectedAvatarLanguageOption}
        options={languageDropdownOptions}
        styles={getCustomStyles<SelectOption>()}
        isSearchable={false}
        value={selectedAvatarLanguageOption}
        // @ts-ignore
        onChange={handleLanguageSelect}
      />
    </div>
    <div className="nj-executive-assistance-settings--form-field">
      <label className="nj-executive-assistance-settings--form-label">Assistant Audio</label>
      <Select
        aria-label="user-language"
        defaultValue={selectedAvatarAudioLanguageOption}
        options={audioLanguageDropdownOptions}
        styles={getCustomStyles<SelectOption>()}
        isSearchable={false}
        value={selectedAvatarAudioLanguageOption}
        // @ts-ignore
        onChange={handleAvatarLanguageSelect}
      />
    </div>
    <div className="nj-executive-assistance-settings--form-field">
      <label className="nj-executive-assistance-settings--form-label">
        Close Caption language
        <p className="nj-executive-assistance-settings--form-sublabel">Video chat only</p>
      </label>
      <Select
        aria-label="user-language"
        defaultValue={languageDropdownOptions[0]}
        options={languageDropdownOptions}
        styles={getCustomStyles<SelectOption>()}
        isSearchable={false}
      />
    </div>
    <hr className="divider" />
    <div>
      <h4 className="nj-executive-assistance-settings-title">Background</h4>
      <p className="nj-executive-assistance-settings--form-sublabel">Video chat only</p>
    </div>
    <div className="nj-executive-assistance-settings--form-field">
      <label className="nj-executive-assistance-settings--form-label">
        Color selection
      </label>
     <AvatarColorPicker userId={userId} />
    </div>
    <div className="divider" />
  </div>
};
