export enum Side {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
  CENTER = 'center',
}

export enum ChatMode {
  CHAT = 'chat',
  // TELENINJA = 'teleninja', // commenting out for now
  AVATAR = 'avatar',
  HASHTAG = 'hashtag',
}

export interface CurrentUser {
  _id: string;
  name: string;
  email: string;
  metadata?: Metadata;
}

export interface Metadata {
  avatarName?: string;
  cameraIndex?: number;
}

export interface AvatarBackgroundColor {
  hex: string;
  r: number;
  g: number;
  b: number;
}

export enum EditableInserts {
  ACTION = '[action]',
  ACTION_ITEM = '[action item]',
  BUSINESS_NAME = '[business name]',
  CONTACT = '[contact]',
  CONTACT_EMAIL = '[contact email address]',
  DETAILS = '[details]',
  DOCUMENT = '[document]',
  EMAIL = '[email address]',
  MEETING_DETAILS = '[meeting details]',
  NAME = '[name]',
  NONE = '',
  RELEVANT_CONTENT = '[relevant content]',
  PHONE = '[phone number]',
  PREFERRED_ACTION = '[preferred action]',
  PREFERRED_TIME = '[preferred time frame]',
  PROBLEM_STATEMENT = '[problem statement]',
  TIME = '[time frame]',
  TOPIC = '[topic]',
}

export enum AvatarAnimations {
  LISTENING = 'listening',
  THINKING = 'thinking',
  IDLE = 'idleaccent',
}

export enum KeyCodes {
  SPACE = ' ',
  ENTER = 'Enter',
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  BACKSPACESHIFT = 'BackspaceShift',
  ESCAPE = 'Escape',
  AT = '@',
  HASH = '#',
}
