import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import App from 'src/components/App';
import { Loading } from 'src/components/Loading';
import { DebugContextProvider } from 'src/contexts/DebugContext';
import { ThemeContextProvider } from 'src/contexts/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

import store, { persistor } from 'src/store';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-responsive-modal/styles.css';
import 'src/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

root.render(
  <HashRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loading />}>
        <ThemeContextProvider>
          <DebugContextProvider>
            <GoogleOAuthProvider clientId={googleClientId}>
              <App />
            </GoogleOAuthProvider>
          </DebugContextProvider>
        </ThemeContextProvider>
      </PersistGate>
    </Provider>
  </HashRouter>,
);
