/**
 * Performance Markers can be used to measure the performance of a specific function or block of code.
 *
 * Usage:
 * - call performanceMarkerStart('name_of_marker') before the code you want to measure
 * - call performanceMarkerEnd('name_of_marker') after the code you want to measure
 * The performanceMarkerEnd function will send the time it took to execute the code to AWS CloudWatch.
 *
 *
 * PerformanceMarker can also be used to measure the time it takes to render a component.
 *
 * Usage:
 *  useEffect(() => {
 *      // Component did mount
 *      performanceMarkerStart('name_of_marker');
 *  }, []);
 *
 * useEffect(() => {
 *     // Component did update
 *  performanceMarkerEnd('name_of_marker');
 * });
 *
 * Metadata can be added to the performance marker to help with deeper analysis.
 * Metadata is an object with key value pairs.
 */
import { logAction } from './analytics';

type PerformanceMark = {
  name: string;
  startTime: number;
  metadata?: PerformanceMetaData;
};

type PerformanceMetaData = {
  [key: string]: string | number | boolean | undefined;
};

const performanceMarkers = new Map<string, PerformanceMark>(new Map());

export const performanceMarkerStart = (name: string, metadata?: PerformanceMetaData) => {
  performanceMarkers.set(name, {
    name,
    startTime: performance.now(),
    metadata,
  });
};

export const performanceMarkerEnd = (
  name: string,
  metadata?: PerformanceMetaData,
  debugMode?: boolean,
) => {
  const performanceMark = performanceMarkers.get(name);

  if (performanceMark && performanceMark.startTime) {
    const endTime = performance.now();
    const totalTime = endTime - performanceMark.startTime;

    // Combine metadata from start and end
    const metadataToReport = {
      ...(performanceMark.metadata ? performanceMark.metadata : {} ),
      ...(metadata ? metadata : {}),
    };

    logAction(performanceMark.name, { time: totalTime, ...metadataToReport });

    if (debugMode) {
      console.log('Performance: ', performanceMark.name, totalTime, metadataToReport);
    }
    performanceMarkers.delete(name);
  }
};
