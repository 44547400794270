import HtmlEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

type EllipsisTextProps = {
  maxLine: number;
  text: string;
  isEllipsis?: boolean;
  className?: string;
};

const ResponsiveHtmlEllipsis = responsiveHOC()(HtmlEllipsis);

/**
 * EllipsisText adds ellipsis to the text given a certain number of lines.
 */
export const EllipsisText = ({ className, maxLine, text, isEllipsis }: EllipsisTextProps) => {
  const isHTMLText = /(<([^>]+)>)/gi.test(text);

  if (!isEllipsis) {
    if (isHTMLText) {
      return (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      );
    }

    return <p className={className}>{text}</p>;
  }

  return (
    <ResponsiveHtmlEllipsis
      data-testid="ellipsis"
      ellipsisHTML="..."
      basedOn="letters"
      component="div"
      unsafeHTML={text}
      maxLine={maxLine}
      className={className}
    />
  );
};
