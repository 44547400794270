import { SettingsHeader } from './components/SettingsHeader';
import { SettingsTabs } from './components/SettingsTabs';
import { SettingsForm } from './components/SettingsForm';

export const SettingsPage = () => {
  return (
    <div className="nj-settings">
      <SettingsHeader />
      <div className="nj-settings--container">
        <div className="nj-settings--container-wrapper">
          <SettingsTabs />
          <SettingsForm />
        </div>
      </div>
    </div>
  );
};
