import { CaretDown, CaretUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { EllipsisText } from 'src/components/EllipsisText';
import { uppercaseFirstLetter } from 'src/utils';
import { Release } from '../DashboardReleaseList';

const DESCRIPTION_MAX_LINE = 2;
const SVG_SIZE = 20;

/**
 * DashboardReleaseNote displays information for one release.
 */
export const DashboardReleaseNote = ({
  typeOfRelease,
  date,
  title,
  description,
  features,
  bugFixes,
  knownIssues,
}: Release) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanding = () => {
    setIsExpanded(!isExpanded);
  };
  const classes = classNames('nj-dashboard--release-note', {
    expanded: isExpanded,
  });

  const typeClasses = classNames('header-type', {
    'color-green': typeOfRelease === 'MAJOR',
    'color-blue': typeOfRelease === 'MINOR',
  });

  const titleClasses = classNames('header-title', {
    ellipsis: !isExpanded,
  });

  return (
    <div className={classes} aria-label="Release Note">
      <div className="container">
        <div role="button" className="collapse-header" onClick={toggleExpanding}>
          <div className="header">
            <h3 className={titleClasses}>{title}</h3>
            <p className={typeClasses}>
              <span>{uppercaseFirstLetter(typeOfRelease)} Update</span>
              <span className="header-icon">
                {isExpanded ? (
                  <CaretUp size={SVG_SIZE} color="currentColor" weight="regular" />
                ) : (
                  <CaretDown size={SVG_SIZE} color="currentColor" weight="regular" />
                )}
              </span>
            </p>
          </div>
          <p className="header-date">{dayjs(date).format('MMM DD, YYYY')}</p>
        </div>
        <div className="collapse-body">
          <EllipsisText
            isEllipsis={!isExpanded}
            maxLine={DESCRIPTION_MAX_LINE}
            text={isExpanded ? description : `${description} ...`}
            className="description"
          />
          {features && features.length > 0 && (
            <div>
              <h6 className="content-title">Features</h6>
              <ul className="content-list" aria-label="Features">
                {features.map((item, index) => (
                  <li className="content-item" key={index}>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {bugFixes && bugFixes.length > 0 && (
            <div>
              <h6 className="content-title">Bug Fixes</h6>
              <ul className="content-list" aria-label="Bug Fixes">
                {bugFixes.map((item, index) => (
                  <li className="content-item" key={index}>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {knownIssues && knownIssues.length > 0 && (
            <div>
              <h6 className="content-title">Known Limitations</h6>
              <ul className="content-list" aria-label="Known Limitations">
                {knownIssues.map((item, index) => (
                  <li className="content-item" key={index}>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
