import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { ApiMessage, AppUser, ConversationRole } from 'src/types';
import { DEFAULT_DATE_FORMAT } from 'src/constants';

// Extends DayJS library:
dayjs.extend(isToday);

/**
 * Get full user name
 * @param user AppUser
 * @returns string
 */
export function getUserFirstName(user: AppUser): string {
  if (!user.first_name) {
    return '';
  }
  return user.first_name;
}

/**
 * Get initials from the user.
 * @param user AppUser
 * @returns string
 */
export function getInitials(user: AppUser): string {
  if (!user.first_name) {
    return '';
  }
  const names = [user.first_name, user.last_name];
  const initials = names.reduce((acc, name) => acc + name.charAt(0), '');
  return initials.toUpperCase();
}

/**
 * getFullName() gets full name for a user or name for a robot.
 * @param user AppUser
 * @returns string
 */
export function getFullName(user?: AppUser): string {
  if (!user) {
    return '';
  }
  return user.role === ConversationRole.USER
    ? `${user.first_name} ${user.last_name}`
    : `${user.first_name}`;
}

/**
 * Get random item from an array.
 * @param arr string[]
 * @returns string
 */
export function getRandomText(arr: string[]): string {
  return arr[Math.floor(Math.random() * arr.length)];
}

/**
 * Pauses execution for a given number of milliseconds.
 * @param delay number
 * @returns promise
 */
export function pause(delay: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

/**
 * Generates color & returns back circle color classname.
 * @returns string
 */
export function getCircleColor(): string {
  const colors = ['green-circle', 'blue-circle', 'purple-circle', 'pink-circle'];
  return getRandomText(colors);
}

/**
 * Returns today, tomorrow or a current date in the format `Feb 22`.
 * @param timestamp string
 * @param locale string
 * @returns string
 */
export function formatMonthDay(timestamp: number | string, locale = 'en-US'): string {
  const diffInDays = getDiffInDays(timestamp);

  if (diffInDays === 0) {
    return 'Today';
  }

  if (diffInDays === 1) {
    return 'Yesterday';
  }

  const date = new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });

  return date;
}

/**
 * Returns today in the format `10:00 AM`, tomorrow, yesterday or a current date in the format `Feb 22`.
 * @param timestamp number|string
 * @param locale string
 * @returns string
 */
export function formatMonthDayExtended(timestamp: number | string, locale = 'en-US'): string {
  const diffInDays = getDiffInDays(timestamp);
  if (diffInDays === 0) {
    return formatHours(timestamp, locale);
  }
  if (diffInDays === 1) {
    return 'Yesterday';
  }
  if (diffInDays === -1) {
    return 'Tomorrow';
  }

  return formatDay(timestamp, locale);
}

/**
 * Get todays date with hours and minutes per message headers format.
 * Format example: `Feb 22, 10:45 AM`
 * @param timestamp
 * @param locale
 * @returns string
 */
export function formatDate(timestamp: number | string, locale = 'en-US'): string {
  return new Date(timestamp).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

/**
 * Format hours and return the formatted date.
 * Sample format: `10:00 AM`
 * @param timestamp
 * @param locale
 * @returns string
 */
export function formatHours(timestamp: number | string, locale = 'en-US'): string {
  return new Date(timestamp).toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  });
}

/**
 * Formats date to day and month, example: `Mar 22`.
 * @param timestamp
 * @param locale
 * @returns string
 */
export function formatDay(timestamp: number | string, locale = 'en-US'): string {
  return new Date(timestamp).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Calculate the difference in days between two timestamps or between current date and timestamp.
 * @param firstTimestamp
 * @param secondTimestamp
 * @returns number
 */
export function getDiffInDays(
  firstTimestamp: number | string,
  secondTimestamp?: number | string,
): number {
  const firstDate = new Date(firstTimestamp);
  const secondDate = secondTimestamp ? new Date(secondTimestamp) : new Date();
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);
  const diffInMs = secondDate.getTime() - firstDate.getTime();
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return diffInDays;
}

/**
 * Sanitizes input from malicious html.
 * @param html string
 * @returns string
 */
export function sanitizeHTML(html: string): string {
  return DOMPurify.sanitize(html);
}

/**
 * Uppercase first letter of the word
 * @param str string
 * @returns string
 */
export function uppercaseFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  return [...str][0].toUpperCase() + str.slice(1).toLocaleLowerCase();
}

/**
 * Takes a string and returns alphanumeric version of it.
 * @param str string
 * @returns string
 */
export function toReadableString(str: string): string {
  return str.replace(/[\W_]+/g, ' ').trim();
}

/**
 * isMoreThanOneDayOld returns true if message is older in timestamp.
 * @param timestamp
 * @returns
 */
export function isMoreThanOneDayOld(timestamp: string | undefined) {
  if (!timestamp) return;

  const stamp = new Date(timestamp).getTime();
  const now = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;
  return now - stamp > oneDay;
}

/**
 * Find out elapsed time in human readable format.
 * @param dateOne string
 * @param dateTwo string
 * @returns string
 */
export function getElapsedTime(dateOne?: string, dateTwo?: string): string {
  if (!dateOne || !dateTwo) {
    return 'n/a';
  }

  const diff = Math.abs(new Date(dateOne).getTime() - new Date(dateTwo).getTime());

  const diffDate = new Date(diff);
  const minutes = diffDate.getMinutes();
  const seconds = diffDate.getSeconds();
  const milliseconds = diffDate.getMilliseconds();

  const template =
    `${minutes > 0 ? minutes + 'min ' : ''}` +
    `${seconds > 0 ? seconds + 's ' : ''}` +
    `${milliseconds > 0 ? milliseconds + 'ms' : ''}`;
  return template.trim();
}

/**
 * getTaskDisplayDate() returns
 * @param date string
 * @returns string
 */
export const getTaskDisplayDate = (date: string): string => {
  const currentDate = dayjs().format(DEFAULT_DATE_FORMAT);

  if (currentDate === date) {
    return 'Today';
  } else if (dayjs().diff(dayjs(date), 'day') === 1) {
    return 'Yesterday';
  } else {
    return dayjs(date).format('MMMM D, YYYY');
  }
};

/**
 * stripHtmlTags() removes html tags from strings.
 * @param text string
 * @returns string
 */
export function stripHtmlTags(text: string): string {
  if ((text === null) || (text === '')) return '';

  text = text.toString();
  return text.replace( /(<([^>]+)>)/ig, '');
}

/**
 * isMessageFromToday() checks whether last chat message was posted today.
 * @param lastMessage ApiMessage
 * @returns boolean
 */
export function isMessageFromToday(message?: ApiMessage): boolean {
  if (!message) return false;

  const timestamp = message?.timestamp;
  if (!timestamp) return false;

  return dayjs(timestamp).isToday();
}

/**
 * haveConversedToday() checks whether person spoke today with AI.
 * @param messages ApiMessage[]
 * @returns boolean
 */
export function haveConversedToday(messages: ApiMessage[] = []): boolean {
  const lastMessage = messages.length > 0 ? messages.at(-1) : undefined;

  return isMessageFromToday(lastMessage);
}

/**
 * displayGreeting() will say "Good morning" etc.
 * based on time of the day measured in user timezone.
 * Good Morning! 5:00 AM — 11:59 AM
 * Good Afternoon! 12:00 PM — 4:59 PM
 * Good Evening! 5:00 PM — 4:59 AM
 * @returns
 */
export const displayGreeting = (): string => {
  const today = new Date();
  const hrs = today.getHours();

  if (hrs >= 5 && hrs < 12) {
    return 'Good Morning';
  }

  if (hrs >= 12 && hrs < 17) {
    return 'Good Afternoon';
  }

  return 'Good Evening';
}

/**
 * getAbbrActionItem() provides appreviated action item name without hash.
 * Example: action-item-name-truncated...
 * @param name string
 * @returns string
 */
export function getActionItemHash(name: string): string {
  const charsMax = 40;
  const truncated = name
    .toLowerCase()
    .replace(/[^\w\s]|_/g, '')
    .replace(/\s{1,}/g, '-')
    .slice(0, charsMax)
  ;

  return name.length > charsMax
    ? truncated.slice(0, charsMax - 3) + '...' : truncated;
}
