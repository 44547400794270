import { ArrowCounterClockwise } from '@phosphor-icons/react';

import { MenuItem, MenuDivider } from '@szhsin/react-menu';
import { useMessages, useSession } from 'src/hooks';

const SVG_MENU_SIZE = 20;

export const ThreadMenuItems = () => {
  const { agent, appUser: user } = useSession();

  const { clearMessages } = useMessages(user, agent);

  const handleClearThread = async () => {
    clearMessages();
  };

  return (
    <>
      <MenuItem onClick={handleClearThread} data-testid="clear-btn">
        <ArrowCounterClockwise size={SVG_MENU_SIZE} /> Clear Chat
      </MenuItem>
      <MenuDivider />
    </>
  );
};
