import { useEffect } from 'react';

export type MetaHumanEvent = {
  type: string; // "avatar-event"
  silence: boolean;
  beginSpeech: boolean;
  sentTTSRequest: boolean;
  text: string;
  provider: string;
};
/**
 * This hook helps to watch out for the message events and handle those that are coming
 * from the avatar iFrame.
 */
export const useMetaHumanEvent = (callback: (metaHumanEvent: MetaHumanEvent) => void) => {
  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; silence: boolean }>) => {
      if (typeof ev.data !== 'object') {
        return;
      }
      if (ev.data.type !== 'avatar-event') {
        return;
      }

      callback(ev.data as MetaHumanEvent);
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [callback]);
};
