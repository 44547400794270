import {
  setActiveSettingsTab,
  setIsOpenSettingsSection,
  settingsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { SettingsTabs } from 'src/store/slices/settingsSlice';

export const useSettings = () => {
  const { isOpenSettingsSection, activeSettingsTab } =
    useAppSelector(settingsState);

  const dispatch = useAppDispatch();

  const changeSelectedSettingsTab = (tab: SettingsTabs) => {
    dispatch(setActiveSettingsTab(tab));
  };

  const changeIsOpenSettingsSectionState = (isOpen: boolean, activeTab?: SettingsTabs) => {
    dispatch(setIsOpenSettingsSection({isOpen, activeTab}));
  }

  return {
    isOpenSettingsSection,
    activeSettingsTab,
    changeSelectedSettingsTab,
    changeIsOpenSettingsSectionState
  };
}
