import { createApi } from '@reduxjs/toolkit/query/react';
import { CurrentUser } from 'src/types';
import { baseQuery } from './config';

enum ContactsTags {
  Contacts = 'Contacts',
}

// TODO(ella): Change to a new API endpoint to filter contacts.
// TODO(ella): Add "getContactsByQuery" with a search term.
// TODO(ella): Change CurrentUser to ApiUser.
export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  tagTypes: [ContactsTags.Contacts],
  baseQuery,
  endpoints: (builder) => ({
    getContacts: builder.query<CurrentUser[], void>({
      query: () => '/users',
      providesTags: [ContactsTags.Contacts],
      transformResponse: (response: { data: CurrentUser[] }) => response.data,
    }),
  }),
});

export const { useGetContactsQuery } = contactsApi;
