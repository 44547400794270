import release from 'src/data/release.json';
import { DashboardReleaseNote } from '../DashboardReleaseNote';

export interface Release {
  typeOfRelease: 'MAJOR' | 'MINOR';
  date: string;
  title: string;
  description: string;
  features?: string[];
  bugFixes?: string[];
  knownIssues?: string[];
}

/**
 * DashboardReleaseList displays list of relese notes.
 */
export const DashboardReleaseList = () => {
  return (
    <div className="nj-dashboard--release-list">
      <h1 className="title">Release Notes</h1>
      {release.map((item, index) => (
        <DashboardReleaseNote key={index} {...(item as Release)} />
      ))}
    </div>
  );
};
