import { useContext } from 'react';
import classNames from 'classnames';
import { Hash, ChatCircleText, Phone, VideoCamera } from '@phosphor-icons/react';
import { ChatMode } from 'src/types';
import { useSession } from 'src/hooks';
import { setAudioMode } from 'src/store';
import ThemeContext from 'src/contexts/ThemeContext';
import { Button } from 'src/components/Button';

const SVG_SIZE = 24;

const PhosphorIcons = {
  chat: ChatCircleText,
  teleninja: Phone,
  avatar: VideoCamera,
  hashtag: Hash,
};

export const ModeControl = () => {
  const { isActionItem, chatMode, updateChatMode, selectedTaskId, updateSelectedTaskId } =
    useSession();

  const { setIsTasksListExpanded } = useContext(ThemeContext);

  const handleClick = (mode: ChatMode) => {
    // disabling teleninja mode for now
    if (mode === 'hashtag') {
      return;
    }

    if (selectedTaskId !== 'default') {
      updateSelectedTaskId('default');
    }

    updateChatMode(mode);

    if (mode === 'avatar') {
      setIsTasksListExpanded(false);
      setAudioMode(true);
    } else {
      setIsTasksListExpanded(true);
      setAudioMode(false);
    }
  };

  return (
    <div className="nj-thread-header--button-chat-mode">
      {Object.values(ChatMode).map((mode: ChatMode, index: number) => {
        const ChatModeIcon = PhosphorIcons[mode];

        if (mode === 'hashtag' && !isActionItem) {
          return null;
        }

        return (
          <Button
            key={`${mode}-${index}`}
            className={classNames('nj-thread-header--menu-button', {
              selected: mode === chatMode,
              // 'nj-atlas-pro': mode === 'teleninja', // temporary hide this mode
            })}
            onClick={() => handleClick(mode)}
          >
            <ChatModeIcon
              size={SVG_SIZE}
              weight={mode !== chatMode || mode === ChatMode.HASHTAG ? 'regular' : 'fill'}
              alt={`${mode} mode icon`}
            />
          </Button>
        );
      })}
    </div>
  );
};
