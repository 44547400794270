/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TaskState {
    NEW = 'new',
    IN_PROGRESS = 'in_progress',
    COMPLETED = 'completed',
    SYSTEM_ERROR = 'system_error',
    USER_ERROR = 'user_error',
    TIMEOUT = 'timeout',
    BLOCKED = 'blocked',
}
