import { rest } from 'msw';
import { CurrentUser } from 'src/types';
import { baseUrl } from '../config';

export const MOCKED_CONTACT: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb4',
  name: 'Ninja Test1',
  email: 'test1@ninjatech.ai',
};

export const MOCKED_CONTACT_2: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb5',
  name: 'Ninja Test2',
  email: 'test2@ninjatech.ai'
};

export const MOCKET_CONTACT_3: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb6',
  name: 'Ninja Test3',
  email: 'test3@ninjatech.ai',
};

export const contactsApiMock = [
  rest.get(`${baseUrl}/users`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ data: [MOCKED_CONTACT, MOCKED_CONTACT_2, MOCKET_CONTACT_3]})
    );
  }),
];
