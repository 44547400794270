import { HelpCard } from './components/HelpCard';
import { HelpList } from './components/HelpList';

export const HelpPage = () => {
  return (
    <div className="nj-help">
      <HelpCard />
      <HelpList />
    </div>
  );
};
