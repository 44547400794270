import { Icon } from '@phosphor-icons/react';
import { PillarTile } from './PillarTile';
import classNames from 'classnames';
import { Button } from 'src/components/Button';

export type PillarTopic = {
  icon?: Icon,
  title: string;
  description?: string;
  tiles?: string[];
  templates?: string[];
  classname?: string;
};

type PillarProps = {
  topic: PillarTopic;
  onClick?: (param: string) => void;
}

const SVG_SIZE = 24;

export const Pillar = ({ topic, onClick }: PillarProps) => {
  const { title, icon: PillarIcon, description, tiles, templates, classname } = topic;

  return (
    <div className={classNames("nj-pillars--pillar", classname)}>
      <div className="nj-pillars--pillar-title">
        {PillarIcon &&
          <Button className="nj-pillars--pillar-icon" aria-label={description}>
          <PillarIcon
            size={SVG_SIZE}
            weight="regular"
            color="currentColor"
          />
          </Button>}
        <h4>{title}</h4>
      </div>
      <div className="nj-pillars--pillar-tiles">
        {tiles && tiles.map((text: string, index: number) =>
          <PillarTile key={index} template={templates ? templates[index] : ''} onTileClick={onClick}>{text}</PillarTile>
        )}
      </div>
    </div>
  );
}
