import classNames from 'classnames';
import { useContext } from 'react';
import { Button } from 'src/components/Button';
import DebugContext from 'src/contexts/DebugContext';
import { sessionState, setAvatarBackgroundColor, useAppDispatch, useAppSelector } from 'src/store';
import { AvatarBackgroundColor } from 'src/types';
import { changeMetahumanBackgrounColor } from 'src/utils';
import { avatarBackgroundColorOptions } from 'src/constants';

interface AvatarColorPickerProps {
  userId: string;
}

export const AvatarColorPicker = ({ userId }: AvatarColorPickerProps) => {
  const dispatch = useAppDispatch();
  const { avatarBackgroundColor } = useAppSelector(sessionState);
  const { debugMode } = useContext(DebugContext);

  const handleSetColor = (color: AvatarBackgroundColor) => {
    const { r, g, b } = color;
    dispatch(setAvatarBackgroundColor(color));
    changeMetahumanBackgrounColor(userId, r, g, b, debugMode);
  };

  return (
    <div className="nj-avatar-settings-selector--picker" data-testid="avatar-color-picker">
      {avatarBackgroundColorOptions.map((item) => (
        <Button
          className={classNames('color-button', {
            active: item.hex === avatarBackgroundColor.hex,
          })}
          key={item.hex}
          style={{ backgroundColor: item.hex }}
          onClick={() => handleSetColor(item)}
          data-testid={item.hex}
        />
      ))}
    </div>
  );
};
