import { PencilSimple, Check, XCircle } from '@phosphor-icons/react';
import { MenuItem, MenuDivider } from '@szhsin/react-menu';

const SVG_MENU_SIZE = 20;

export const ActionItemMenuItems = () => {
  return (
    <>
      <MenuItem data-testid="rename-btn">
        <PencilSimple size={SVG_MENU_SIZE} /> Rename
      </MenuItem>

      <MenuDivider />
      <MenuItem data-testid="done-btn">
        <Check size={SVG_MENU_SIZE} /> Mark as done
      </MenuItem>

      <MenuDivider />
      <MenuItem data-testid="cancel-btn">
        <XCircle size={SVG_MENU_SIZE} /> Cancel
      </MenuItem>

      <MenuDivider />
    </>
  );
};
