import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import {
  feedbackState,
  setFeedbackData,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { useSendFeedbackMutation } from 'src/store/services';
import { useMessages, useSession } from 'src/hooks';
import { FeedbackRatingList } from '../FeedbackRatingList';

const isIntentCorrectOptions = ['Yes', 'No'];

const correctIntentOptions = [
  'Schedule',
  'Teleninja',
  'Chit Chat',
  'Search',
  'Create',
  'Search and create',
  'Math',
];

export type FormData = {
  is_intent_correct: 'Yes' | 'No';
  correct_intent?: string;
  ideal_response: string;
  additional_feedback?: string;
  understanding?: number;
  helpfulness?: number;
  coherence?: number;
  completeness?: number;
  accuracy?: number;
  language_quality?: number;
  relevance?: number;
  user_experience?: number;
  style?: number;
  error_handling?: number;
};

const schema = yup
  .object()
  .shape({
    is_intent_correct: yup.string().required(),
    correct_intent: yup.string().when('is_intent_correct', {
      is: 'No',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    ideal_response: yup.string().required(),
    additional_feedback: yup.string(),
    understanding: yup.string().nullable(),
    helpfulness: yup.string().nullable(),
    coherence: yup.string().nullable(),
    completeness: yup.string().nullable(),
    accuracy: yup.string().nullable(),
    language_quality: yup.string().nullable(),
    relevance: yup.string().nullable(),
    user_experience: yup.string().nullable(),
    style: yup.string().nullable(),
    error_handling: yup.string().nullable(),
    rating_list: yup.string().nullable(),
  })
  .test('at-least-one-required', 'At least one field is required', function (values) {
    const {
      understanding,
      helpfulness,
      coherence,
      completeness,
      accuracy,
      language_quality,
      relevance,
      user_experience,
      style,
      error_handling,
    } = values;

    if (
      !understanding &&
      !helpfulness &&
      !coherence &&
      !completeness &&
      !accuracy &&
      !language_quality &&
      !relevance &&
      !user_experience &&
      !style &&
      !error_handling
    ) {
      return this.createError({
        message: 'At least one field is required',
        path: 'rating_list',
      });
    }
    return true;
  });

export const ConversationFeedback = () => {
  const { appUser, agent } = useSession();

  const { patchMessage } = useMessages(appUser, agent);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
    watch,
  } = useForm<FormData>({
    // TODO(olha): TS issue https://github.com/react-hook-form/react-hook-form/issues/6679
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const isIntentCorrect = watch('is_intent_correct');

  const dispatch = useAppDispatch();

  const { feedbackData } = useAppSelector(feedbackState);

  const [sendFeedback] = useSendFeedbackMutation();

  useEffect(() => {
    return () => {
      reset();
      dispatch(setFeedbackData(null));
    };
  }, [dispatch, reset]);

  useEffect(() => {
    if (isIntentCorrect === 'Yes') {
      resetField('correct_intent');
    }
  }, [isIntentCorrect, resetField]);

  const resetForm = () => {
    dispatch(setFeedbackData(null));
    reset();
  };

  const onSubmit = async (formData: FormData) => {
    if (!feedbackData) {
      return;
    }

    const {
      is_intent_correct,
      correct_intent,
      ideal_response,
      additional_feedback,
      understanding,
      helpfulness,
      coherence,
      completeness,
      accuracy,
      language_quality,
      relevance,
      user_experience,
      style,
      error_handling,
    } = formData;

    const newFeedback = {
      ...feedbackData,
      is_intent_correct: is_intent_correct === 'Yes',
      correct_intent,
      ideal_response,
      additional_feedback,
      understanding: Number(understanding),
      helpfulness: Number(helpfulness),
      coherence: Number(coherence),
      completeness: Number(completeness),
      accuracy: Number(accuracy),
      language_quality: Number(language_quality),
      relevance: Number(relevance),
      user_experience: Number(user_experience),
      style: Number(style),
      error_handling: Number(error_handling),
      thumbs: false,
    };

    try {
      await sendFeedback(newFeedback).unwrap();
      await patchMessage({ feedback: newFeedback, message_id: feedbackData.messageId });
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const classes = classNames('nj-converse-ai--feedback', {
    expanded: !!feedbackData,
  });

  if (!feedbackData) {
    return null;
  }

  const {
    user_input,
    model_input,
    model_intent,
    model_response,
    user_name,
    timestamp,
    request_id,
  } = feedbackData;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes}>
      <h3 className="title">Ninja ACE-IT (Answer Correctness Evaluation - Internal Testing)</h3>

      <div className="field">
        <h5 className="subtitle">User Input</h5>
        <p className="description">{user_input}</p>
      </div>

      <div className="field">
        <h5 className="subtitle">Model Input</h5>
        <p className="description">{model_input}</p>
      </div>

      <div className="field">
        <h5 className="subtitle">Model Intent</h5>
        <p className="description">{model_intent}</p>
      </div>

      <div className="field">
        <legend className={classNames('subtitle', { isRequired: errors.is_intent_correct })}>
          Is Intent correct?
        </legend>
        {isIntentCorrectOptions.map((item) => (
          <div key={item}>
            <input type="radio" id={item} value={item} {...register('is_intent_correct')} />
            <label className="label" htmlFor={item}>
              {item}
            </label>
          </div>
        ))}
      </div>

      <div className="field">
        {isIntentCorrect === 'No' && (
          <>
            <legend className={classNames('subtitle', { isRequired: errors.correct_intent })}>
              What is the correct Intent?
            </legend>
            <select className="select" defaultValue="" {...register('correct_intent')}>
              <option value="" disabled>
                Select...
              </option>
              {correctIntentOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </>
        )}
      </div>

      <div className="field">
        <h5 className="subtitle">Model Response</h5>
        <p className="description">{model_response}</p>
      </div>

      <FeedbackRatingList<FormData> register={register} errors={errors} watch={watch} />

      <div className="field">
        <legend className={classNames('subtitle', { isRequired: errors.ideal_response })}>
          Ideal Response
        </legend>
        <TextareaAutosize
          className="textarea"
          placeholder="Enter ideal output"
          {...register('ideal_response')}
        />
      </div>

      <div className="field">
        <legend className={classNames('subtitle', { isRequired: errors.additional_feedback })}>
          Additional Feedback
        </legend>
        <TextareaAutosize
          className="textarea"
          placeholder="Enter comment"
          {...register('additional_feedback')}
        />
      </div>

      <div className="actions">
        <button type="reset" onClick={resetForm}>
          Cancel
        </button>
        <button type="submit">Submit</button>
      </div>

      <p className="description">User: {user_name}</p>
      <p className="description">Timestamp: {timestamp}</p>
      <p className="description">RequestID: {request_id}</p>
    </form>
  );
};
