/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * This flag is used to identify who created a message within a conversation
 */
export enum ConversationRole {
    SYSTEM = 'system',
    USER = 'user',
    AGENT = 'agent',
}
