import { useCallback, useState } from 'react';
import { useSession } from 'src/hooks';
import BingLogo from 'src/images/logos/bing-logo.png';
import GoogleLogo from 'src/images/logos/google-logo.png';
import { supportedLanguages } from 'src/pages/ManageTasksChatPage/components/AvatarSettingsSelector/constants';
import { SelectOption, SettingsSelect } from '../SettingsSelect';
import { SettingsSwitch } from '../SettingsSwitch';
import { Button } from 'src/components/Button';
import { useGoogleLogin } from '@react-oauth/google';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';

const searchEngineOptions = [
  { value: 'Google', label: 'Google' },
  { value: 'Bing', label: 'Bing' },
];

const languageDropdownOptions = supportedLanguages.map((language) => ({
  value: language.code,
  label: language.displayText,
}));

const audioLanguageDropdownOptions = [
  {
    value: 'Auto-Detect',
    label: 'Auto-Detect',
  },
  ...languageDropdownOptions,
];

const countryAndRegionOptions = [{ value: 'United States', label: 'United States' }];

const stateOptions = [{ value: 'California', label: 'California' }];

const largeLanguageModelOptions = [
  { value: 'NinjaTech AI', label: 'NinjaTech AI' },
  { value: 'Open AI', label: 'Open AI' },
  { value: 'Anthropic', label: 'Anthropic' },
];

export const SettingsForm = () => {
  const [searchEngine, setSearchEngine] = useState<SelectOption>(searchEngineOptions[0]);
  const { updateChatAudioLocale, chatAudioLocale } = useSession();

  const appUser = useSelector((state: RootState) => state.appUser);

  const handleChatAudioLanguageSelect = useCallback(
    (option: SelectOption) => {
      updateChatAudioLocale(option.value);
    },
    [updateChatAudioLocale],
  );

  const login = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: process.env.REACT_APP_GOOGLE_AUTHORIZATION_REDIRECT_URI,
    // TODO: gather from user
    scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts",
    hint: appUser?.email,
    state: window.location.href
  });

  return (
    <div>
      {/* TODO: FE team - temporary ui to showcase authorization flow. */}

      <div className="nj-settings--form-field-wrapper" style={{marginBottom: "24px"}}>
        <div>
          <p className="nj-settings--form-title">Atlas permissions</p>
          <p className="nj-settings--form-description">Allow Atlas to manage your calendar and contacts</p>
        </div>

        <div className="nj-settings--form-field">
          <Button className='nj-button-system' onClick={() => login()}>Allow access</Button>
        </div>
      </div>

      <form data-testid="settings-form" className="nj-settings--form">


        <div className="nj-settings--form-field-wrapper">
          <div>
            <p className="nj-settings--form-title">Location</p>
            <p className="nj-settings--form-description">Set up your work location</p>
          </div>

          <div className="nj-settings--form-field">
            <label className="nj-settings--form-label">Country & Region</label>
            <SettingsSelect options={countryAndRegionOptions} name="country-select" />
          </div>

          <div className="nj-settings--form-field">
            <label className="nj-settings--form-label">State</label>
            <SettingsSelect options={stateOptions} name="state-select" />
          </div>

          <div className="nj-settings--form-field">
            <label className="nj-settings--form-label">City</label>
            <input aria-label="Input city name" className="nj-settings--form-input" />
          </div>
        </div>

        <div className="nj-settings--form-field-wrapper">
          <div>
            <p className="nj-settings--form-title">Conversation</p>
            <p className="nj-settings--form-description">
              Choose the default search engine and LLM for conversations with your EA
            </p>
          </div>

          <div className="nj-settings--form-field-search-engine">
            <label className="nj-settings--form-label">Search Engine</label>
            <div className="nj-settings--form-select-search-engine">
              <div className="nj-settings--form-select-wrapper">
                <SettingsSelect
                  value={searchEngine}
                  options={searchEngineOptions}
                  onChange={setSearchEngine}
                  name="search-engine-select"
                />
              </div>

              <img
                className="nj-settings--form-logo"
                src={searchEngine.value === 'Google' ? GoogleLogo : BingLogo}
                alt={searchEngine.value}
              />
            </div>
          </div>

          <div className="nj-settings--form-field">
            <label className="nj-settings--form-label">Large Language Model</label>
            <SettingsSelect options={largeLanguageModelOptions} name="language-model-select" />
          </div>
        </div>

        <div className="nj-settings--form-field-wrapper">
          <div>
            <p className="nj-settings--form-title">Notification</p>
            <p className="nj-settings--form-description">
              Set up preference for receiving notification in addition to your EA Portal
            </p>
          </div>

          <div className="nj-settings--form-horizontal-field">
            <label className="nj-settings--form-horizontal-label">Email</label>
            <SettingsSwitch name="email-switch" />
          </div>

          <div className="nj-settings--form-horizontal-field">
            <label className="nj-settings--form-horizontal-label">Slack</label>
            <SettingsSwitch defaultActive name="slack-switch" />
          </div>
        </div>

        <div className="nj-settings--form-field-wrapper">
          <div>
            <p className="nj-settings--form-title">Language Auto-Detect</p>
            <p className="nj-settings--form-description">
              Set up whether Auto-Detect is enabled for voice input
            </p>
          </div>

          <div className="nj-settings--form-horizontal-field">
            <label className="nj-settings--form-horizontal-label">Auto-Detect in Chat</label>
            <SettingsSelect
              defaultValue={audioLanguageDropdownOptions.find(
                (option) => option.value === chatAudioLocale,
              )}
              options={audioLanguageDropdownOptions}
              onChange={handleChatAudioLanguageSelect}
              name="language-auto-detect-select"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
