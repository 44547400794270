type PillarCardProps = {
  children?: React.ReactNode;
  template?: string;
  onTileClick?: (param: string) => void;
};

export const PillarTile = ({ template = '', onTileClick, children }: PillarCardProps) => {
  const handleTileClick = (text: string) => {
    if (text === '') return;
    if (onTileClick) {
      onTileClick(text);
    }
  };

  return (
    <div className="nj-pillars--tile" onClick={() => handleTileClick(template)}>{children}</div>
  );
}
