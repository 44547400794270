import { isString, isBoolean, isNumber } from './general.guard';
import { ApiMessage, MessageChannel, ConversationRole,
  MessageDebugData, ModelFeedback, ModelFeedbackDialogue,
  ApiTask, TaskChannel, TaskSkill, TaskState, SchedulingPayload,
  SchedulingRequest, Guest, Meeting, Negotiation, TaskStatus,
  ReservationDetails, TestPayload, ApiUser, AppUser } from './api';

/**
 * isTaskStatus() tests data against TaskStatus data type.
 * @param data unknown
 * @returns boolean
 */
export function isTaskStatus(data: unknown): data is TaskStatus {
  const status = data as TaskStatus;

  if (!Object.values(TaskStatus).includes(status)) {
    console.log('not a task status');
    return false;
  }

  return true;
}

/**
 * isApiUser() test data against ApiUser data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiUser(data: unknown): data is ApiUser {
  const apiUser = data as ApiUser;

  if (typeof apiUser !== 'object') {
    return false;
  }

  if (!('user_id' in apiUser && isString(apiUser.user_id))) {
    return false;
  }

  if (!('email' in apiUser && isString(apiUser.email))) {
    return false;
  }

  if (!('company' in apiUser && isString(apiUser.company))) {
    return false;
  }

  if (!('first_name' in apiUser && isString(apiUser.first_name))) {
    return false;
  }

  if (!('last_name' in apiUser && isString(apiUser.last_name))) {
    return false;
  }

  if (!('user_type' in apiUser && isString(apiUser.user_type))) {
    return false;
  }

  if ('user_owner_id' in apiUser && !isString(apiUser.user_owner_id)) {
    return false;
  }

  if ('metadata' in apiUser && !isString(apiUser.metadata)) {
    return false;
  }

  return true;
}

/**
 * isApiUserTypes() guards data to be an array of users.
 * @param data unknown
 * @returns boolean
 */
export function isApiUserList(data: unknown): data is Array<ApiUser> {
  const users = data as Array<ApiUser>;

  if (!Array.isArray(users)) {
    return false;
  }

  for (const user of users) {
    if (!isApiUser(user)) {
      return false;
    }
  }

  return true;
}

/**
 * isAppUser() test data type agaist AppUser data type.
 * @param data unknown
 * @returns boolean
 */
export function isAppUser(data: unknown): data is AppUser {
  const appUser = data as AppUser;

  if (!isApiUser(appUser)) {
    return false;
  }

  if ('avatar' in appUser && !isString(appUser.avatar)) {
    return false;
  }

  if ('role' in appUser && !isString(appUser.role)) {
    return false;
  }

  if ('color' in appUser && !isString(appUser.color)) {
    return false;
  }

  return true;
}

/**
 * isMessageDebugData() guards against debug data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageDebugData(data: unknown): data is MessageDebugData {
  const debug = data as MessageDebugData;

  if (typeof debug !== 'object') {
    return false;
  }

  if ('x_trace_id' in debug && !(isString(debug.x_trace_id) || debug.x_trace_id === undefined)) {
    return false;
  }

  return true;
}

/**
 * isModalFeedbackDialogue() guards against debug data type.
 * @param data unknown
 * @returns boolean
 */
export function isModelFeedbackDialogue(data: unknown): data is ModelFeedbackDialogue {
  const dialogue = data as ModelFeedbackDialogue;

  if (typeof dialogue !== 'object') {
    return false;
  }

  if (!('content' in dialogue && isString(dialogue.content))) {
    return false;
  }

  if (!('role' in dialogue && isString(dialogue.role))) {
    return false;
  }

  return true;
}

/**
 * isModelFeedback() guards data to be of Feedback type.
 * @param data unknown
 * @returns boolean
 */
export function isModelFeedback(data: unknown): data is ModelFeedback {
  const feedback = data as ModelFeedback;

  if (feedback === null) {
    console.log('returning true');
    return true;
  }

  if (typeof feedback !== 'object') {
    return false;
  }

  if (!('user_input' in feedback && isString(feedback.user_input))) {
    console.log('user_input');
    return false;
  }

  if (!('model_input' in feedback && isString(feedback.model_input))) {
    console.log('model_input');
    return false;
  }

  if (!('whole_dialogue' in feedback && Array.isArray(feedback.whole_dialogue))) {
    console.log('whole_dialogue');
    return false;
  }

  for (const dialogue of feedback.whole_dialogue) {
    if (!isModelFeedbackDialogue(dialogue)) {
      console.log('dialogue');
      return false;
    }
  }

  if (!('model_name' in feedback && isString(feedback.model_name))) {
    console.log('model_name');
    return false;
  }

  if (!('model_intent' in feedback && isString(feedback.model_intent))) {
    console.log('model_intent');
    return false;
  }

  if (!('model_response' in feedback && isString(feedback.model_response))) {
    console.log('model_response');
    return false;
  }

  if (!('user_name' in feedback && isString(feedback.user_name))) {
    console.log('user_name');
    return false;
  }

  if (!('request_id' in feedback && isString(feedback.request_id))) {
    console.log('request_id');
    return false;
  }

  if (!('model_id' in feedback && isString(feedback.model_id))) {
    console.log('model_id');
    return false;
  }

  if (!('model_output' in feedback && isString(feedback.model_output))) {
    console.log('model_output');
    return false;
  }

  if (!('thought' in feedback && isString(feedback.thought))) {
    console.log('thought');
    return false;
  }

  if (!('label' in feedback && isString(feedback.label))) {
    console.log('label');
    return false;
  }

  if (!('input' in feedback && isString(feedback.input))) {
    console.log('input');
    return false;
  }

  if (!('reply' in feedback && isString(feedback.reply))) {
    console.log('reply');
    return false;
  }

  // Att: this portion is not working, DB records do not return this field.
  // if (!('ui_environment' in feedback && isString(feedback.ui_environment))) {
  //  console.log('ui_environment');
  //  return false;
  // }

  if ('is_intent_correct' in feedback && !isBoolean(feedback.is_intent_correct)) {
    console.log('is_intent_correct');
    return false;
  }

  if ('correct_intent' in feedback && !isString(feedback.correct_intent)) {
    console.log('correct_intent');
    return false;
  }

  if ('ideal_response' in feedback && !isString(feedback.ideal_response)) {
    console.log('ideal_response');
    return false;
  }

  if ('additional_feedback' in feedback && !isString(feedback.additional_feedback)) {
    console.log('additional_feedback');
    return false;
  }

  if (!('timestamp' in feedback && isString(feedback.timestamp))) {
    console.log('timestamp');
    return false;
  }

  if ('thumbs' in feedback && !isBoolean(feedback.thumbs)) {
    console.log('thumbs');
    return false;
  }

  if ('understanding' in feedback && !isNumber(feedback.understanding)) {
    console.log('understanding');
    return false;
  }

  if ('helfulness' in feedback && !isNumber(feedback.helfulness)) {
    console.log('helfulness');
    return false;
  }

  if ('coherence' in feedback && !isNumber(feedback.coherence)) {
    console.log('coherence');
    return false;
  }

  if ('completeness' in feedback && !isNumber(feedback.completeness)) {
    console.log('completeness');
    return false;
  }

  if ('accuracy' in feedback && !isNumber(feedback.accuracy)) {
    console.log('accuracy');
    return false;
  }

  if ('language_quality' in feedback && !isNumber(feedback.language_quality)) {
    console.log('language_quality');
    return false;
  }

  if ('relevance' in feedback && !isNumber(feedback.relevance)) {
    console.log('relevance');
    return false;
  }

  if ('user_experience' in feedback && !isNumber(feedback.user_experience)) {
    console.log('user_experience');
    return false;
  }

  if ('style' in feedback && !isNumber(feedback.style)) {
    console.log('style');
    return false;
  }

  if ('error_handling' in feedback && !isNumber(feedback.error_handling)) {
    console.log('error_handling');
    return false;
  }

  return true;
}

/**
 * isApiMessage() guards for chat api message data types.
 * @param data unknown
 * @returns boolean
 */
export function isApiMessage(data: unknown): data is ApiMessage {
  const message = data as ApiMessage;

  if (typeof message !== 'object') {
    return false;
  }

  if ('message_id' in message && !isString(message.message_id)) {
    return false;
  }

  if ('timestamp' in message && !isString(message.timestamp)) {
    return false;
  }

  if ('session_id' in message && !isString(message.session_id)) {
    return false;
  }

  if ('user_id' in message && !isString(message.user_id)) {
    return false;
  }

  if ('task_id' in message && !isString(message.task_id)) {
    return false;
  }

  if ('created_task_id' in message && !isString(message.created_task_id)) {
    return false;
  }

  if (!('from_user_id' in message && isString(message.from_user_id))) {
    return false;
  }

  if (!('to_user_id' in message && isString(message.to_user_id))) {
    return false;
  }

  if ('channel' in message && !(isString(message.channel) && Object.values(MessageChannel).includes(message.channel))) {
    return false;
  }

  if ('role' in message && !(isString(message.role) && Object.values(ConversationRole).includes(message.role))) {
    return false;
  }

  if (!('content' in message && isString(message.content))) {
    return false;
  }

  if ('metadata' in message && !isString(message.metadata)) {
    return false;
  }

  if ('is_read'  in message && !isBoolean(message.is_read)) {
    return false;
  }

  if ('tag' in message && !isString(message.tag)) {
    return false;
  }

  if ('feedback' in message && !isModelFeedback(message.feedback)) {
    return false;
  }

  if ('debug' in message && !isMessageDebugData(message.debug)) {
    return false;
  }

  return true;
}

/**
 * isApiMessagesList guards data for chat messages list type.
 * @param data
 * @returns boolean
 */
export function isApiMessagesList(data: unknown): data is Array<ApiMessage> {
  const messages = data as Array<ApiMessage>;

  if (messages.length === 0) {
    return true;
  }

  for (const message of messages) {
    if (!isApiMessage(message)) {
      return false;
    }
  }

  return true;
}

/**
 * isGuest() checks data against guest data type.
 * @param data unknown
 * @returns boolean
 */
export function isGuest(data: unknown): data is Guest {
  const guest = data as Guest;

  if (typeof guest !== 'object') {
    return false;
  }

  if ('email' in guest && !isString(guest.email)) {
    return false;
  }

  if ('name' in guest && !isString(guest.name)) {
    return false;
  }

  if ('role' in guest && !isString(guest.role)) {
    return false;
  }

  if ('accepted' in guest && !isBoolean(guest.accepted)) {
    return false;
  }

  if ('internal' in guest && !isBoolean(guest.internal)) {
    return false;
  }

  return true;
}

/**
 * isSchedulingRequest guards data for scheduling request type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingRequest(data: unknown): data is SchedulingRequest {
  const request = data as SchedulingRequest;

  if (typeof request !== 'object') {
    return false;
  }

  if ('content' in request && !isString(request.content)) {
    return false;
  }

  if ('guests' in request && !Array.isArray(request.guests)) {
    return false;
  }

  for (const guest of request.guests) {
    if (!isGuest(guest)) {
      return false;
    }
  }

  if ('topic' in request && !isString(request.topic)) {
    return false;
  }

  if ('proposed_date' in request && !isString(request.proposed_date)) {
    return false;
  }

  if ('proposed_time' in request && !isString(request.proposed_time)) {
    return false;
  }

  if ('proposed_duration' in request && !isString(request.proposed_duration)) {
    return false;
  }

  if ('request_timestamp' in request && !isString(request.request_timestamp)) {
    return false;
  }

  return false;
}

/**
 * isMeeting() tests data against Meeting data type.
 * @param data unknown
 * @returns boolean
 */
export function isMeeting(data: unknown): data is Meeting {
  const meeting = data as Meeting;

  if (typeof meeting !== 'object') {
    return false;
  }

  if (!('organizer' in meeting && isGuest(meeting.organizer))) {
    return false;
  }

  if (!('guests' in meeting && Array.isArray(meeting.guests))) {
    return false;
  }

  for (const guest of meeting.guests) {
    if (!isGuest(guest)) {
      return false;
    }
  }

  if (!('start_datetime' in meeting && isString(meeting.start_datetime))) {
    return false;
  }

  if (!('end_datetime' in meeting && isString(meeting.end_datetime))) {
    return false;
  }

  if (!('subject' in meeting && isString(meeting.subject))) {
    return false;
  }

  if ('location' in meeting && !isString(meeting.location)) {
    return false;
  }

  if ('description' in meeting && !isString(meeting.description)) {
    return false;
  }

  if (!('duration' in meeting && isNumber(meeting.duration))) {
    return false;
  }

  return true;
}

/**
 * isNegotiation() tests data against Negotiation data type.
 * @param data unknown
 * @returns boolean
 */
export function isNegotiation(data: unknown): data is Negotiation {
  const negotiation = data as Negotiation;

  if (typeof negotiation !== 'object') {
    return false;
  }

  if (!('proposed_start_datetime' in negotiation && isString(negotiation.proposed_start_datetime))) {
    return false;
  }

  if (!('proposed_end_datetime' in negotiation && isString(negotiation.proposed_end_datetime))) {
    return false;
  }

  if (!('proposed_duration' in negotiation && isNumber(negotiation.proposed_duration))) {
    return false;
  }

  if (!('guests' in negotiation && Array.isArray(negotiation.guests))) {
    return false;
  }

  for (const guest in negotiation.guests) {
    if (!isGuest(guest)) {
      return false;
    }
  }

  if ('subject' in negotiation && !isString(negotiation.subject)) {
    return false;
  }

  return true;
}

/**
 * isSchedulingPayload() guards data to be of scheduling payload.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulingPayload(data: unknown): data is SchedulingPayload {
  const payload = data as SchedulingPayload;

  if (typeof payload !== 'object') {
    return false;
  }

  if ('payload_type' in payload && !isString(payload.payload_type)) {
    return false;
  }

  if ('request' in payload && isSchedulingRequest(payload.request)) {
    return false;
  }

  // Attn: Present but not required to test against
  // if ('meeting' in payload && !isMeeting(payload.meeting)) {
  //  return false;
  // }

  // Attn: Present but not required to test against
  // if ('negotiation' in payload && !isNegotiation(payload.negotiation)) {
  //  return false;
  // }

  return true;
}

/**
 * isReservationDetails() tests data against resevration details.
 * @param data unknown
 * @returns boolean
 */
export function isReservationDetails(data: unknown): data is ReservationDetails {
  const reservation = data as ReservationDetails;

  if (typeof reservation !== 'object') {
    return false;
  }

  if (!('payload_type' in reservation && isString(reservation.payload_type))) {
    return false;
  }

  if (!('time_range_minutes' in reservation && isNumber(reservation.time_range_minutes))) {
    return false;
  }

  if (!('phone_number' in reservation && isString(reservation.phone_number))) {
    return false;
  }

  if (!('number_of_people' in reservation && isNumber(reservation.number_of_people))) {
    return false;
  }

  if ('start_time' in reservation && !isString(reservation.start_time)) {
    return false;
  }

  if ('extra_requirements' in reservation && !isString(reservation.extra_requirements)) {
    return false;
  }

  return true;
}

/**
 * isTestPayload() tests data against TestPayload data type.
 * @param data unknown
 * @returns boolean
 */
export function isTestPayload(data: unknown): data is TestPayload {
  const test = data as TestPayload;

  if (typeof test !== 'object') {
    return false;
  }

  if (!('payload_type' in test && isString(test.payload_type))) {
    return false;
  }

  if ('content' in test && !isString(test.content)) {
    return false;
  }

  return true;
}

/**
 * isApiTask() guards data to be of chat task type.
 * @param data unknown
 * @returns boolean
 */
export function isApiTask(data: unknown): data is ApiTask {
  const task = data as ApiTask;

  if (typeof task !== 'object') {
    return false;
  }

  if ('task_id' in task && !isString(task.task_id)) {
    return false;
  }

  if ('parent_task_id' in task && !isString(task.parent_task_id)) {
    return false;
  }

  if ('user_id' in task && !isString(task.user_id)) {
    return false;
  }

  if ('agent_id' in task && !isString(task.agent_id)) {
    return false;
  }

  if ('task_subject' && !isString(task.task_subject)) {
    return false;
  }

  if (!('channel' in task && Object.values(TaskChannel).includes(task.channel))) {
    return false;
  }

  if (!('skill' in task && Object.values(TaskSkill).includes(task.skill))) {
    return false;
  }

  if ('state' in task && !(isString(task.state) && Object.values(TaskState).includes(task.state))) {
    return false;
  }

  if ('requires_attention' in task && !isBoolean(task.requires_attention)) {
    return false;
  }

  if ('messages' in task && !Array.isArray(task.messages)) {
    return false;
  }

  if (task.messages && task.messages.length > 0) {
    for (const message of task.messages) {
      if (!isApiMessage(message)) {
        return false;
      }
    }
  }

  if ('created_at' in task && !isString(task.created_at)) {
    return false;
  }

  if ('updated_at' in task && !isString(task.updated_at)) {
    return false;
  }

  if ('payload' in task && (
    isSchedulingPayload(task.payload) ||
    isReservationDetails(task.payload) ||
    isTestPayload(task.payload))) {
    return false;
  }

  if ('status' in task && !(isString(task.status) && Object.values(TaskStatus).includes(task.status))) {
    return false;
  }

  // Attn: belongs to frontend only
  if ('selected' in task && !isBoolean(task.selected)) {
    return false;
  }

  // Attn: belongs to frontend only
  if ('collaborators' in task && !Array.isArray(task.collaborators)) {
    return false;
  }

  return true;
}
