import { useMemo, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Check, ArrowsClockwise, WarningCircle, FunnelSimple, MagnifyingGlass, ArrowsDownUp, IconWeight } from '@phosphor-icons/react';
import { useTasks } from 'src/hooks';
import { ApiTaskSelectable, TaskStatus } from 'src/types';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import { useSession } from 'src/hooks';
import { Tasks } from 'src/pages/ManageTasksChatPage/components/Tasks';
import { Button } from 'src/components/Button';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import { Spinner } from 'src/components/Loading';

const SVG_SIZE = 16;

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
  weight: 'regular' as IconWeight,
};

enum TasksListSortByTime {
  DEFAULT = 'created_at',
  UPDATED_AT = 'updated_at',
}

enum TasksListSortByStatus {
  ALL = 'All',
  BLOCKED = 'Blocked',
  IN_PROGRESS = 'In Progress',
  DONE = 'Completed',
}

enum TaskListEmptyStates {
  DEFAULT = `No Action Items available.`,
  IN_PROGRESS = `No Action Items in progress.`,
  BLOCKED = `No Action Items that are blocked.`,
  DONE = `No Action Items that have been completed.`,
}

interface TaskListProps {
  timeFilter?: TasksListSortByTime;
  statusFilter?: TasksListSortByStatus;
  selectedTaskId?: string;
  onSelectTask: (id: string) => void;
}

export const TaskList = ({
  selectedTaskId,
  onSelectTask,
  timeFilter = TasksListSortByTime.DEFAULT,
  statusFilter = TasksListSortByStatus.ALL,
}: TaskListProps) => {
  const { appUser } = useSession();
  const { tasks, isLoadingTasks } = useTasks(appUser.user_id);

  const [sortedByTime, setSortedByTime] = useState<TasksListSortByTime>(timeFilter);
  const [sortedByStatus, setSortedByStatus] = useState<TasksListSortByStatus>(statusFilter);

  const groupedTasks: Record<string, ApiTaskSelectable[]> = useMemo(() => {
    if (!tasks) {
      return {};
    }

    const filterTasks = (tasks: ApiTaskSelectable[]) => {
      switch(sortedByStatus) {
        case TasksListSortByStatus.ALL:
          return tasks;
        case TasksListSortByStatus.DONE:
          return tasks.filter((task: ApiTaskSelectable) =>
            task.status === TaskStatus.COMPLETED);
        case TasksListSortByStatus.BLOCKED:
          return tasks.filter((task: ApiTaskSelectable) =>
            task.status === TaskStatus.BLOCKED);
        default:
          return tasks.filter((task: ApiTaskSelectable) =>
            task.status === TaskStatus.BLOCKED ||
            task.status === TaskStatus.NEW);
      }
    };

    const filteredTasks = filterTasks(tasks);

    const acc: Record<string, ApiTaskSelectable[]> = {};

    filteredTasks.forEach((task) => {
      if (!task[sortedByTime]) {
        return;
      }

      const groupedDate = dayjs(task[sortedByTime]).format(DEFAULT_DATE_FORMAT);

      if (!acc[groupedDate]) {
        acc[groupedDate] = [task];
      } else {
        acc[groupedDate] = [...acc[groupedDate], task];
      }
    });

    return acc;
  }, [tasks, sortedByTime, sortedByStatus]);

  const emptyListWarning = useMemo(() => {
    switch(sortedByStatus) {
      case TasksListSortByStatus.BLOCKED:
        return <>{TaskListEmptyStates.BLOCKED}</>;
      case TasksListSortByStatus.IN_PROGRESS:
        return <>{TaskListEmptyStates.IN_PROGRESS}</>;
      case TasksListSortByStatus.DONE:
        return <>{TaskListEmptyStates.DONE}</>;
      default:
        return <>{TaskListEmptyStates.DEFAULT}</>;
    }
  }, [sortedByStatus]);

  return (
    <div className="nj-task-list">
      <div className="nj-task-list--header" data-testid="task-header">
        <form className="nj-task-list--header-search">
          <input placeholder="Search" />
          <Button aria-label="Click to search the task">
            <MagnifyingGlass {...ICON_PROPS} />
          </Button>
        </form>

        <div className="nj-task-list--header-filters">
          <Menu
            menuClassName="nj-multi-menu"
            menuButton={
              <MenuButton className="nj-task-list--header-button" aria-label="Click to filter tasks" >
                <FunnelSimple size={SVG_SIZE} />
                {sortedByStatus}
              </MenuButton>}
            onItemClick={(e) => setSortedByStatus(e.value)}
            transition>
            <MenuItem
              className={classNames({ selected: sortedByStatus === TasksListSortByStatus.ALL })}
              value={TasksListSortByStatus.ALL}>All</MenuItem>
            <MenuDivider />
            <MenuItem
              className={classNames({ selected: sortedByStatus === TasksListSortByStatus.BLOCKED })}
              value={TasksListSortByStatus.BLOCKED}><WarningCircle size={SVG_SIZE} />Blocked</MenuItem>
            <MenuItem
              className={classNames({ selected: sortedByStatus === TasksListSortByStatus.IN_PROGRESS })}
              value={TasksListSortByStatus.IN_PROGRESS}><ArrowsClockwise size={SVG_SIZE} />In progress</MenuItem>
            <MenuItem
              className={classNames({ selected: sortedByStatus === TasksListSortByStatus.DONE })}
              value={TasksListSortByStatus.DONE}><Check size={SVG_SIZE} />Done</MenuItem>
          </Menu>

          <Menu
            menuClassName="nj-multi-menu"
            onItemClick={(e) => setSortedByTime(e.value)}
            menuButton={<MenuButton className="nj-task-list--header-button" aria-label="Click to sort tasks">
            <ArrowsDownUp size={SVG_SIZE} />
            {sortedByTime === TasksListSortByTime.DEFAULT ? `By created on` : `By last modified`}
          </MenuButton>} transition>
            <MenuItem
              className={classNames({
                selected: sortedByTime === TasksListSortByTime.DEFAULT,
              })}
              value={TasksListSortByTime.DEFAULT}>By created on</MenuItem>
            <MenuItem
              className={classNames({
                selected: sortedByTime === TasksListSortByTime.UPDATED_AT,
              })}
              value={TasksListSortByTime.UPDATED_AT}>By last modified</MenuItem>
          </Menu>
        </div>
      </div>

      <div className="nj-task-list--list">
        {isLoadingTasks ? <Spinner /> :
          Object.entries(groupedTasks).map(([date, tasks]) => (
            <Tasks
              key={date}
              date={date}
              tasks={tasks}
              selectedTaskId={selectedTaskId}
              onSelectTask={onSelectTask}
            />
        ))}
      </div>
      {!isLoadingTasks && Object.keys(groupedTasks).length === 0 &&
      <div className="nj-task-list--empty-list-warning">{emptyListWarning}</div>}
    </div>
  );
};
