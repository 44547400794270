import { createApi } from '@reduxjs/toolkit/query/react';
import { CurrentUser } from 'src/types';
import { baseV2Query } from './config';

export interface UpdateUserRequest {
  id: string;
  body: Partial<CurrentUser>;
}

enum UsersTags {
  Users = 'Users',
}

// TODO(ella): how is it different from appUserSlice? Remove unnecessary APIs.
export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: [UsersTags.Users],
  baseQuery: baseV2Query,
  endpoints: (builder) => ({
    getUserById: builder.query<CurrentUser, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      // transformResponse: (response: { data: CurrentUser }) => response.data,
      providesTags: (result, error, id) => {
        return [{ type: UsersTags.Users, id }];
      },
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserById', id, (draft) => ({
            ...draft,
            ...patch.body,
          })),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetUserByIdQuery, useUpdateUserMutation } = usersApi;
