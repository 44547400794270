import { Link } from 'react-router-dom';
import LogoVertical from 'src/images/logos/LogoVertical.svg';

const mailto = 'ninja-help@ninjatech.ai';

/**
 * HelpCard displays an invitation block with "Contact us" link on the Help page.
 */
export const HelpCard = () => {
  return (
    <div className="nj-help--card">
      <div className="logo">
        <img src={LogoVertical} alt="Ninja Tech AI Logo" />
      </div>
      <p>Need help or support?</p>
      <Link
        className="link"
        to="#"
        onClick={(e) => {
          window.location.href = `mailto:${mailto}`;
          e.preventDefault();
        }}
      >
        Contact us
      </Link>
    </div>
  );
};
