/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReservationDetails = {
    payload_type: ReservationDetails.payload_type;
    time_range_minutes: number;
    phone_number: string;
    number_of_people: number;
    start_time?: string;
    extra_requirements?: string;
};

export namespace ReservationDetails {

    export enum payload_type {
        RESERVATION = 'reservation',
    }


}
