import { Check, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useContext } from 'react';
import { Button } from 'src/components/Button';
import DebugContext from 'src/contexts/DebugContext';
import { useAvatarPreferences } from 'src/hooks';
import { changeMetahumanAvatar } from 'src/utils';
import OutsideClickHandler from 'react-outside-click-handler';

const X_SVG_SIZE = 20;
const CHECK_SVG_SIZE = 40;

interface AvatarSelectorProps {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AvatarSelector = ({ userId, isOpen, onClose }: AvatarSelectorProps) => {
  const { debugMode } = useContext(DebugContext);

  const { avatarOptions, selectedAvatarName, updateUser } = useAvatarPreferences(userId);
  const handleAvatarChange = (avatarName: string) => {
    changeMetahumanAvatar(userId, avatarName, debugMode);

    updateUser({
      id: userId,
      body: {
        metadata: {
          avatarName,
        },
      },
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
    <div
      data-testid="avatar-selector"
      className={classNames('nj-avatar-selector', { open: isOpen })}
    >
      <Button aria-label="Close Avatar Selector" onClick={onClose} className="nj-avatar-selector-close-button">
        <X size={X_SVG_SIZE} color="currentColor" />
      </Button>
      <div className="nj-avatar-selector--list">
        {avatarOptions.map(({ id, url }) => (
          <div
            data-testid={id}
            className="nj-avatar-selector--item"
            key={id}
            onClick={() => handleAvatarChange(id)}
          >
            <img
              className={classNames('nj-avatar-selector--image', {
                active: selectedAvatarName === id,
              })}
              src={url}
              alt="Ninja Tech AI Avatar"
            />
            {selectedAvatarName === id && (
              <div className="nj-avatar-selector--mask">
                <Check data-testid="check-icon" size={CHECK_SVG_SIZE} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </OutsideClickHandler>
  );
};
