/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { animateMetahuman } from 'src/utils';
import { AvatarAnimations } from 'src/types';

interface IFrameProps {
  userId?: string;
  url: string;
  allowFullScreen?: boolean;
  debug?: boolean;
}

/**
 * IFrame component shows iframe based on URL.
 */
export const IFrame = ({ userId, url, allowFullScreen = true, debug = false, ...rest }: IFrameProps) => {
  useEffect(() => {
    if (userId) {
      animateMetahuman(userId, AvatarAnimations.IDLE, debug);
    }
  }, []);

  return (
    <iframe
      src={url}
      width="100%"
      height="100%"
      title="Streaming Avatar"
      allow="autoplay *; fullscreen *"
      allowFullScreen={allowFullScreen}
      style={{ minHeight: '100vh', borderStyle: 'none' }}
      {...rest}
    ></iframe>
  );
};
