import { contactsApi } from 'src/store';
import { CurrentUser } from 'src/types';

/**
 * useContacts() loads and filters contacts of the user account.
 * TODO(ella): Swap CurrentUser with ApiUser & add v2 base query.
 */
export const useContacts = () => {
  const {
    data = [],
    isFetching: isFeatchingContacts,
    isLoading: isLoadingContacts,
    error: contactsError,
    refetch: getContacts,
  } = contactsApi.useGetContactsQuery();

  const getMatchingContacts = (search = '') => {
    const contacts: string[] = data.map((contact: CurrentUser) => contact.name).sort();

    if (search === '') return contacts.slice(0, 5);

    const contactsList = contacts.filter((name: string) => {
      const regExp = new RegExp(`^${search}`, 'gi');
      return name.match(regExp);
    }).slice(0, 5);

    return contactsList.length > 0
      ? contactsList : contacts.slice(0, 5);
  };

  const getMatchingEmails = (search = '') => {
    const emails: string[] = data.map((user: CurrentUser) => user.email).sort();

    if (search === '') return emails.slice(0, 5);

    const emailsList = emails.filter((email: string) => {
      const regExp = new RegExp(`^${search}`, 'gi');
      return email.match(regExp);
    }).slice(0, 5);

    return emailsList.length > 0
      ? emailsList : emails.slice(0, 5);
  };

  return {
    contacts: data,
    isFeatchingContacts,
    isLoadingContacts,
    contactsError,
    getContacts,
    getMatchingContacts,
    getMatchingEmails,
  };
};
