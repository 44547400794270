import { displayGreeting } from 'src/utils';

type PillarsTitleProps = {
  name?: string;
  shouldShowToday?: boolean;
}

export const PillarsTitle = ({ name = '', shouldShowToday = false }: PillarsTitleProps) => {
  return (
    <div className="nj-pillars--title">
      <div className="nj-date" data-testid="show-today">{shouldShowToday && `Today`}</div>
      <h1>
        Hi{name && ` ${name}`},<br />
        <span data-testid="user-greeting">{displayGreeting()}!</span>
      </h1>
    </div>
  );
}
