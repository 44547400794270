import { StylesConfig, MultiValue, GroupBase } from 'react-select';

export const getCustomStyles = <T>(): StylesConfig<T | MultiValue<T>, boolean, GroupBase<T>> => {
  return {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#343e42',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderRadius: '20px',
      ':hover': {
        cursor: 'pointer',
        borderColor: '#343e42',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '10px 16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#e8eaea',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
      color: '#8E9496',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
      paddingRight: '8px',
    }),
    input: (provided) => ({
      ...provided,
      color: '#e8eaea',
      visibility: 'visible',
    }),
    menu: (provided) => ({
      ...provided,
      border: '1px solid #343e42',
      backgroundColor: '#1d282d;',
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? '#61696c' : 'transparent',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#61696c',
      },
    }),
  };
};
