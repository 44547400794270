/* eslint-disable @typescript-eslint/ban-ts-comment */
import Select from 'react-select';
import { getCustomStyles } from './selectHelper';

export interface SelectOption {
  value: string;
  label: string;
}

interface SettingsSelectProps {
  options: SelectOption[];
  value?: SelectOption;
  onChange?: (value: SelectOption) => void;
  defaultValue?: SelectOption;
  name?: string;
}

export const SettingsSelect = ({
  options,
  value,
  defaultValue,
  onChange,
  name = 'select',
}: SettingsSelectProps) => {
  const handleChange = ({ value, label }: SelectOption) => {
    if (onChange) {
      onChange({
        value,
        label,
      });
    }
  };

  return (
    <div>
      <Select
        aria-label={name}
        defaultValue={defaultValue || options[0]}
        options={options}
        styles={getCustomStyles<SelectOption>()}
        isSearchable={false}
        value={value}
        // @ts-ignore
        onChange={handleChange}
      />
    </div>
  );
};
