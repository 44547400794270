import { isString, isObject } from './general.guard';
import { AWSCognitoUserAttributes, AWSCognitoUser } from './aws';

/**
 * isCognitoUserAttributes checks aws user attributes to contain email.
 * @param data unknown
 * @returns boolean
 */
export function isCognitoUserAttributes(data: unknown): data is AWSCognitoUserAttributes {
  const attributes = data as AWSCognitoUserAttributes;

  if (!('email' in attributes && isString(attributes.email))) {
    return false;
  }

  return true;
}

/**
 * isCognitoUser checks data for minimal cognito data
 * that we require from AWS.
 * @param data
 * @returns boolean
 */
export function isCognitoUser(data: unknown): data is AWSCognitoUser {
  const user = data as AWSCognitoUser;

  if (!('username' in user && isString(user.username))) {
    return false;
  }

  if (!('attributes' in user && isObject(user.attributes))) {
    return false;
  }

  if (!isCognitoUserAttributes(user.attributes)) {
    return false;
  }

  return true;
}
