import { useMemo } from 'react';
import { usersApi } from 'src/store';
import akira from 'src/images/assistants/akira.jpg';
import ash from 'src/images/assistants/ash.jpg';
import atlas from 'src/images/assistants/atlas.jpg';
import billie from 'src/images/assistants/billie.jpg';
import jackie from 'src/images/assistants/jackie.jpg';
import jesse from 'src/images/assistants/jesse.jpg';
import jules from 'src/images/assistants/jules.jpg';
import kai from 'src/images/assistants/kai.jpg';
import morgan from 'src/images/assistants/morgan.jpg';
import nico from 'src/images/assistants/nico.jpg';
import noah from 'src/images/assistants/noah.jpg';
import rumi from 'src/images/assistants/rumi.jpg';
import chris from 'src/images/assistants/chris.png';
import jojo from 'src/images/assistants/jojo.png';

const defaultAvatarName = 'BP_Jesse_00';
const defaultCameraIndex = 0;
const maxCameraIndex = 4;

export const useAvatarPreferences = (userId: string) => {
  const { data } = usersApi.useGetUserByIdQuery(userId);

  const [updateUser] = usersApi.useUpdateUserMutation();

  const avatarOptions = useMemo(
    () => [
      {
        id: 'BP_Jesse_00',
        url: jesse,
      },
      {
        id: 'BP_Jules_00',
        url: jules,
      },
      {
        id: 'BP_Jackie_00',
        url: jackie,
      },
      {
        id: 'BP_Noah_00',
        url: noah,
      },
      {
        id: 'BP_Atlas_00',
        url: atlas,
      },
      {
        id: 'BP_Ash_00',
        url: ash,
      },
      {
        id: 'BP_Akira_00',
        url: akira,
      },
      {
        id: 'BP_Kai_00',
        url: kai,
      },
      {
        id: 'BP_Morgan_00',
        url: morgan,
      },
      {
        id: 'BP_Nico_00',
        url: nico,
      },
      {
        id: 'BP_Billie_00',
        url: billie,
      },
      {
        id: 'BP_Rumi_00',
        url: rumi,
      },
      {
        id: 'BP_Chris_00',
        url: chris,
      },
      {
        id: 'BP_Jojo_00',
        url: jojo,
      }
    ],
    [],
  );

  const selectedAvatarName = useMemo(
    () => (data?.metadata && data.metadata?.avatarName) || defaultAvatarName,
    [data],
  );

  const avatarCameraIndex = useMemo(
    () => (data?.metadata && data.metadata?.cameraIndex) || defaultCameraIndex,
    [data],
  );

  const incrementedAvatarCameraIndex = useMemo(
    () => (avatarCameraIndex === maxCameraIndex ? defaultCameraIndex : avatarCameraIndex + 1),
    [avatarCameraIndex],
  );

  const avatarImageURL = useMemo(
    () => (avatarOptions.find(avatar => avatar.id === selectedAvatarName)?.url),
    [selectedAvatarName, avatarOptions],
  );

  return {
    avatarOptions,
    selectedAvatarName,
    avatarImageURL,
    avatarCameraIndex,
    incrementedAvatarCameraIndex,
    updateUser,
  };
};
