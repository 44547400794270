import { ArrowBendUpLeft } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';

const SVG_SIZE = 20;

export const ReplyMessageButton = () => {
  return (
    <Button className="nj-reply-button">
      <ArrowBendUpLeft size={SVG_SIZE} color="currentColor" weight="light" />
      Reply
    </Button>
  );
};
