/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * External status shown by the FE.
 */
export enum TaskStatus {
    NEW = 'new',
    WORKING = 'working',
    COMPLETED = 'completed',
    BLOCKED = 'blocked',
}
