import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser, ConversationRole } from 'src/types';
import { DEFAULT_AGENT } from 'src/constants';
import { fetchDefaultAgent } from 'src/store/thunks';
import { getCircleColor } from 'src/utils';
import { RootState } from '../index';

export enum SettingsTabs {
  GENERAL = 'General',
  PROFILE = 'Profile',
  EXECUTIVE_ASSISTANT = 'Executive Assistant',
  PREFERENCES = 'Preferences',
  ACCESS_AND_DATA = 'Access & Data',
}

interface SettingsState {
  isOpenSettingsSection: boolean;
  activeSettingsTab: SettingsTabs;
  agent: AppUser;
}

// To keep all settings data here.
const initialState: SettingsState = {
  isOpenSettingsSection: false,
  activeSettingsTab: SettingsTabs.GENERAL,
  agent: DEFAULT_AGENT,
};

/**
 * Keeps on a settings data.
 */
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettings: (state) => {
      return initialState;
    },
    setIsOpenSettingsSection: (state, action: PayloadAction<{isOpen: boolean, activeTab?: SettingsTabs}>) => {
      return { ...state, isOpenSettingsSection: action.payload.isOpen, activeSettingsTab: action.payload.activeTab || state.activeSettingsTab };
    },
    setActiveSettingsTab: (state, action: PayloadAction<SettingsTabs>) => {
      return { ...state, activeSettingsTab: action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefaultAgent.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appAgent = {
          ...action.payload,
          color: getCircleColor(),
          avatar: (avatar !== '' ? `avatar${avatar}` : ''),
          role: ConversationRole.AGENT,
        };
        return { ...state, agent: appAgent };
      })
      .addCase(fetchDefaultAgent.rejected, (state, action) => {
        return { ...state, agent: DEFAULT_AGENT };
      });
  },
});

export const settingsState = (state: RootState) => state.settings as SettingsState;

export const {
  resetSettings,
  setIsOpenSettingsSection,
  setActiveSettingsTab,
} = settingsSlice.actions;

export default settingsSlice.reducer;
