import { useMemo, useContext } from 'react';
import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { setFeedbackData, useAppDispatch } from 'src/store';
import DebugContext from 'src/contexts/DebugContext';
import { useSendFeedbackMutation } from 'src/store/services/feedbackApi';
import { useMessages, useSession } from 'src/hooks';
import { ModelFeedback } from 'src/types';

const SVG_SIZE = 20;

interface ConversationThumbButtonsProps {
  messageId: string;
  feedback: ModelFeedback;
}

export const ConversationThumbButtons = ({
  messageId,
  feedback,
}: ConversationThumbButtonsProps) => {
  const { debugMode } = useContext(DebugContext);

  const { appUser, agent } = useSession();

  const dispatch = useAppDispatch();

  const [sendFeedback] = useSendFeedbackMutation();
  const { patchMessage } = useMessages(appUser, agent);

  const handleThumbsDownClick = () => {
    dispatch(setFeedbackData({ ...feedback, messageId }));
  };

  const handleThumbsUpClick = async () => {
    const newFeedback = {
      ...feedback,
      thumbs: true,
    };

    try {
      await sendFeedback(newFeedback).unwrap();
      await patchMessage({ feedback: newFeedback, message_id: messageId });
      dispatch(setFeedbackData(null));
    } catch (error) {
      console.error(error);
    }
  };

  const isThumbDownActive = useMemo(() => feedback.thumbs === false, [feedback]);

  const isThumbUpActive = useMemo(() => !!feedback.thumbs, [feedback]);

  const thumbDownClasses = classNames({
    active: isThumbDownActive,
  });

  const thumbUpClasses = classNames({
    active: isThumbUpActive,
  });

  return (
    <div data-testid="thumb-buttons" className="nj-message-thumb-buttons">
      <button
        className={thumbUpClasses}
        onClick={handleThumbsUpClick}
        // TODO(olha): !debugMode - temporary
        disabled={isThumbDownActive || isThumbUpActive || !debugMode}
        aria-label="Click on the thumb up"
      >
        <ThumbsUp size={SVG_SIZE} color="currentColor" />
      </button>
      <button
        className={thumbDownClasses}
        onClick={handleThumbsDownClick}
        // TODO(olha): !debugMode - temporary
        disabled={isThumbDownActive || isThumbUpActive || !debugMode}
        aria-label="Click on the thumb down"
      >
        <ThumbsDown size={SVG_SIZE} color="currentColor" />
      </button>
    </div>
  );
};
