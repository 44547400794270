import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser, ConversationRole, AvatarBackgroundColor, ChatMode } from 'src/types';
import { avatarBackgroundColorOptions, DEFAULT_AGENT } from 'src/constants';
import { fetchDefaultAgent, fetchUserByEmail } from 'src/store/thunks';
import { getCircleColor } from 'src/utils';
import { RootState } from '../index';

interface SessionState {
  accessToken?: string;
  selectedTaskId?: string;
  agent: AppUser;
  avatarCCLocale: string;
  avatarAudioLocale: string;
  avatarBackgroundColor: AvatarBackgroundColor;
  shouldOpenAvatarByDefault: boolean;
  chatAudioLocale: string;
  chatMode: ChatMode;
  appUser: AppUser;
}

// To keep all session data here.
const initialState: SessionState = {
  accessToken: undefined,
  selectedTaskId: 'default',
  avatarCCLocale: navigator?.language || 'en-US',
  avatarAudioLocale: navigator?.language || 'en-US',
  avatarBackgroundColor: avatarBackgroundColorOptions[0],
  chatAudioLocale: navigator?.language || 'en-US',
  agent: DEFAULT_AGENT,
  shouldOpenAvatarByDefault: false,
  chatMode: ChatMode.CHAT,
  appUser: {} as AppUser,
};

/**
 * Keeps on a session data.
 */
export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetSession: (state) => {
      return initialState;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      return { ...state, accessToken: action.payload };
    },
    setSelectedTaskId: (state, action: PayloadAction<string>) => {
      return { ...state, selectedTaskId: action.payload };
    },
    setAvatarCCLocale: (state, action: PayloadAction<string>) => {
      return { ...state, avatarCCLocale: action.payload };
    },
    setAvatarAudioLocale: (state, action: PayloadAction<string>) => {
      return { ...state, avatarAudioLocale: action.payload };
    },
    setChatAudioLocale: (state, action: PayloadAction<string>) => {
      return { ...state, chatAudioLocale: action.payload };
    },
    setAvatarBackgroundColor: (state, action: PayloadAction<AvatarBackgroundColor>) => {
      return { ...state, avatarBackgroundColor: action.payload };
    },
    setOpenAvatarByDefault: (state, action: PayloadAction<boolean>) => {
      return { ...state, shouldOpenAvatarByDefault: action.payload };
    },
    setChatMode: (state, action: PayloadAction<ChatMode>) => {
      return { ...state, chatMode: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefaultAgent.fulfilled, (state, action) => {
        if (!action.payload) return state;
        const avatar = action.payload.first_name || '';
        const appAgent = {
            ...action.payload,
            color: getCircleColor(),
            avatar: (avatar !== '' ? `avatar${avatar}` : ''),
            role: ConversationRole.AGENT,
        };
        return { ...state, agent: appAgent };
      })
      .addCase(fetchDefaultAgent.rejected, (state, action) => {
        return { ...state, agent: DEFAULT_AGENT };
      })
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        if (!action.payload) return state;
        // TODO(ella): Add S3 AWS bucket to upload user avatars.
        const avatar = action.payload.first_name || '';
        const appUser = {
          ...action.payload,
          color: getCircleColor(),
          avatar: avatar !== '' ? `avatar${avatar}` : '',
          role: ConversationRole.USER,
        };
        return { ...state, appUser };
      });
  },
});

export const sessionState = (state: RootState) => state.session as SessionState;

export const {
  resetSession,
  setSelectedTaskId,
  setAvatarCCLocale,
  setAvatarAudioLocale,
  setAvatarBackgroundColor,
  setOpenAvatarByDefault,
  setChatAudioLocale,
  setChatMode,
} = sessionSlice.actions;

export default sessionSlice.reducer;
