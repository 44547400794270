import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { HelperItem } from './HelperItem';

type HelperProps = {
  prefix?: string;
  selection?: string;
  className?: string;
  items: string[];
  style: { [key: string]: string; };
  onItemClick?: (value: string) => void;
}

export const Helper = ({ prefix = '', items, className = '', selection = '', onItemClick, style }: HelperProps) => {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setSelectedItem((prevSelectedItem) =>
          prevSelectedItem > 0 ? prevSelectedItem - 1 : prevSelectedItem
        );
      } else if (e.key === 'ArrowDown') {
        setSelectedItem((prevSelectedItem) =>
          prevSelectedItem < items.length - 1 ? prevSelectedItem + 1 : prevSelectedItem
        );
      } else if (e.key === 'Enter') {
        e.preventDefault();

        const selection = items.at(selectedItem) || '';
        if (onItemClick) {
          onItemClick(selection);
        }
     }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [selectedItem, items, onItemClick]);

  return items.length > 0 ? (
    <div className={classNames("nj-helper", className)} style={style}>
      {items.map((item: string, index: number) =>
        <HelperItem
          prefix={prefix}
          key={index}
          selection={selection}
          text={item}
          selected={index === selectedItem}
          onItemClick={onItemClick}
        />
      )}
    </div>
  ) : null;
};
