import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Check, X } from '@phosphor-icons/react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';

const SVG_SIZE = 20;
const ICON_PROPS = {
  size: SVG_SIZE,
  color: 'currentColor',
};

interface TaskTitleInputProps {
  defaultValue: string;
  onFormSubmit: (value: string) => Promise<void>;
  onToggleEditableStatus: () => void;
}

interface FormData {
  title: string;
}

export const TaskEditForm = ({
  defaultValue,
  onFormSubmit,
  onToggleEditableStatus,
}: TaskTitleInputProps) => {
  const [isFocusInput, setIsFocusInput] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues: { title: defaultValue },
  });

  const handleCancelEdit = () => {
    resetForm();
    onToggleEditableStatus();
  };

  const handleFormSubmit = async ({ title }: FormData) => {
    if (!title) {
      handleCancelEdit();
      return;
    }
    await onFormSubmit(title);
    handleCancelEdit();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;

    const cleanedValue = value.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '');
    e.target.value = cleanedValue;
  };

  return (
    <form className="nj-task-card--form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="nj-task-card--form-input-wrapper">
        <input
          className="nj-task-card--form-input"
          {...register('title')}
          onInput={handleInputChange}
          onFocus={() => setIsFocusInput(true)}
          onBlur={() => setIsFocusInput(false)}
        />

        {isFocusInput && <Tooltip title="Names can’t contain special characters" />}
      </div>

      <div className="nj-task-card--action-wrapper">
        <Button aria-label="Click to save task" className="nj-task-card--button" type="submit">
          <Check {...ICON_PROPS} />
        </Button>

        <Button
          onClick={handleCancelEdit}
          aria-label="Click to cancel the edit"
          className="nj-task-card--button"
          type="reset"
        >
          <X {...ICON_PROPS} />
        </Button>
      </div>
    </form>
  );
};
