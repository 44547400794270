import classNames from 'classnames';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { ChatMode } from 'src/types';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/MainNavToggleButton';
import { useSession } from 'src/hooks';
import { ModeControl } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/ModeControl';
import { HeaderMenu } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/HeaderMenu';
import { HeaderTitle } from 'src/pages/ManageTasksChatPage/components/ConversationHeader/components/HeaderTitle';

const SVG_SIZE = 24;

export const ConversationHeader = () => {
  const { chatMode } = useSession();
  const classes = classNames('nj-thread-header', {
    'avatar-mode': chatMode === ChatMode.AVATAR,
  });

  return (
    <header className={classes}>
      <div className="nj-thread-header--left">
        <MainNavToggleButton />
        <HeaderTitle />
      </div>

      <div className="nj-thread-header--menu">
        <MagnifyingGlass size={SVG_SIZE} />
        <HeaderMenu />
        <ModeControl />
      </div>
    </header>
  );
};
