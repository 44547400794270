import { AppUser } from 'src/types';
import { Nav } from './Nav';
import { TimeCard } from './TimeCard';

type AppNavProps = {
  user: AppUser;
}

export const AppNav = ({ user }: AppNavProps) => {
  return (
    <div className="nj-app-nav" role="navigation">
      <Nav user={user} />
      <TimeCard hours={12.25} />
    </div>
  );
};
