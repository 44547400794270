import { UserCircle, Question, SignOut } from '@phosphor-icons/react';
import { AuthEventData } from '@aws-amplify/ui';
import { NavLink } from 'react-router-dom';
import { AppUser } from 'src/types';
import LogoHorizontal from 'src/images/logos/LogoTiny.png';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import { resetStore, useAppDispatch } from 'src/store';
import { Profile } from './Profile';
import { Button } from '../Button';

type AccountBarProps = {
  user: AppUser;
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
}

export const Account = ({ user, signOut }: AccountBarProps) => {
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    dispatch(resetStore());
    localStorage.clear();
    if (signOut) {
      signOut();
    }
    window.location.assign(`/`);
  };

  return (
    <div className="nj-account-bar">
      <Button className="upgrade">
        Upgrade to PRO
      </Button>
      <div className="nj-account-bar--nav" role="navigation">
        <NavLink to="/" className="nj-account-bar--logo">
          <img src={LogoHorizontal} alt="Ninja Tech AI Logo" />
        </NavLink>
        <Menu menuClassName="nj-multi-menu" menuButton={<MenuButton><Profile user={user} /></MenuButton>} transition>
          <MenuItem>
            <NavLink to="/profile" title='Profile'>
              <UserCircle size="20" />Profile
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/help" title='Support'>
              <Question size="20" />Support
            </NavLink>
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={handleLogout}><SignOut size="20" />Sign out</MenuItem>
        </Menu>
      </div>
    </div>
  );
};
