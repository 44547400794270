import { rest } from 'msw';
import { CurrentUser } from 'src/types';
import { UpdateUserRequest } from '../usersApi';
import { baseUrl } from '../config';
import { DEFAULT_MOCKED_USER_ID } from './mockedConstants';

export const MOCKED_USER_ID_WITH_METADATA = 'MOCKED_USER_ID_WITH_METADATA';
export const MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX = 'MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX';
export const NOT_FOUND_USER_ID = 'NOT_FOUND_USER_ID';

export const DEFAULT_MOCKED_USER: CurrentUser = {
  _id: DEFAULT_MOCKED_USER_ID,
  name: 'John Smith',
  email: 'js@ninjatech.ai',
};

export const MOCKED_USER_WITH_METADATA: CurrentUser = {
  _id: MOCKED_USER_ID_WITH_METADATA,
  name: 'John Smith',
  email: 'js@ninjatech.ai',
  metadata: {
    avatarName: 'BP_Billie_00',
    cameraIndex: 1,
  },
};

export const MOCKED_USER_WITH_MAX_CAMERA_INDEX: CurrentUser = {
  _id: MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX,
  name: 'John Smith',
  email: 'js@ninjatech.ai',
  metadata: {
    avatarName: 'BP_Billie_00',
    cameraIndex: 4,
  },
};

export const usersApiMock = [
  rest.get(`${baseUrl}/users/${DEFAULT_MOCKED_USER_ID}`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: DEFAULT_MOCKED_USER }));
  }),

  rest.get(`${baseUrl}/users/${MOCKED_USER_ID_WITH_METADATA}`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: MOCKED_USER_WITH_METADATA }));
  }),

  rest.get(`${baseUrl}/users/${MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX}`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ data: MOCKED_USER_WITH_MAX_CAMERA_INDEX }));
  }),

  rest.get(`${baseUrl}/users/${NOT_FOUND_USER_ID}`, (req, res, ctx) => {
    return res(ctx.status(500), ctx.json({ message: 'Value is not a valid uuid' }));
  }),

  rest.patch<UpdateUserRequest>(
    `${baseUrl}/users/${DEFAULT_MOCKED_USER_ID}`,
    async (req, res, ctx) => {
      const { body } = await req.json<UpdateUserRequest>();
      const { metadata } = body;
      if (metadata?.avatarName) {
        return res(
          ctx.status(200),
          ctx.json({
            ...DEFAULT_MOCKED_USER,
            metadata: {
              avatarName: metadata.avatarName,
            },
          }),
        );
      }
      if (metadata?.cameraIndex) {
        return res(
          ctx.status(200),
          ctx.json({
            ...DEFAULT_MOCKED_USER,
            metadata: {
              cameraIndex: metadata.cameraIndex,
            },
          }),
        );
      }
      return res(ctx.status(200), ctx.json(DEFAULT_MOCKED_USER));
    },
  ),
];
